import React from 'react';
import ExchangeTVChart from '../../../options/components/Exchange/ExchangeTVChartOptions';
import { ARBITRUM } from '../../../gmx-test/lib/legacy';
import './BuilderChart.scss';

const BuilderChart = (props) => {
	const {
		infoTokens,
		tokenSelection,
		setTokenSelection,
		positionChartData,
		positionChartCollaterals
	} = props;

	return (
		<div className="BuilderChart">
			<ExchangeTVChart
				infoTokens={infoTokens}
				chainId={ARBITRUM}
				tokenSelection={tokenSelection}
				setTokenSelection={setTokenSelection}
				positionChartData={positionChartData}
				positionChartCollaterals={positionChartCollaterals}
			/>
		</div>
	);
};

export default BuilderChart;