import React, { useState, useRef, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import cx from "classnames";
import { USD_DECIMALS, formatAmount, formatAmountFree, numberWithCommas } from '../../../gmx-test/lib/legacy';
import InputSimple from './../../../../components/UI/input/InputSimple';
import { GREEN, GREY, LGREY, OPTIONS_DECIMALS, RED } from '../../../../components/utils/constants';
import './BuilderPositionChart.scss';
import Currency from './../../../../components/Currency';
import { fromBigNumber } from '../../../../components/utils/utils';

const BuilderPositionChart = (props) => {
	const {
		className,
		style,
		chartToken,
		chartData,
		collaterals,
	} = props;

	const chartRef = useRef(null);

	// const chartData = {
	// 	amount: 20,
	// 	strikes: [1800, 1800],
	// 	premiums: [250, 300],
	// 	strategies: [1, 2],
	// 	isBaseArr: [0, 0],
	// }

	const isETH = chartToken.symbol === "ETH";
	const minMaxDelta = isETH ? 0.3 : 0.2;
	const priceStep = isETH ? 5 : 10;
	const curPrice = chartToken.maxPrice && formatAmountFree(chartToken.maxPrice, USD_DECIMALS, 2);
	const curPriceRounded = chartToken.maxPrice && Math.round(curPrice / priceStep) * priceStep;
	const pxPerPoint = 100;

	const isChartReady = chartData && chartData.strikes.every(strike => strike);

	const calcPnl = (i, closePrice) => {
		const strategy = chartData.strategies[i];
		const premium = chartData.premiums[i];
		const strikePrice = chartData.strikes[i];
		const amount = chartData.amount;
		const collateralObj = collaterals && collaterals[i];
		const liqPrice = collateralObj && fromBigNumber(collateralObj.liquidationPrice, OPTIONS_DECIMALS);
		const collateral = collateralObj && fromBigNumber(collateralObj.amount, OPTIONS_DECIMALS);
		const isBase = collateralObj ? collateralObj.isBase : chartData.isBaseArr[i];
		
		let result;
		switch(strategy) {
			case 1: // Long Call
				if (closePrice < strikePrice) {
					result = -premium;
				} else {
					result = ((closePrice - strikePrice) * amount) - premium;
				}
				break;
			case 2: // Long Put
				if (closePrice > strikePrice) {
					result = -premium;
				} else {
					result = ((strikePrice - closePrice) * amount) - premium;
				}
				break;
			case -1: // Short Call
				if (liqPrice && closePrice > liqPrice) {
					if (isBase) {
						result = -(collateral * liqPrice) + premium;
					} else {
						result = -collateral + premium;
					}
				} else {
					if (isBase) {
						const maxProfit = ((strikePrice - curPrice) * amount) + premium;
						if (closePrice > strikePrice) {
							result = maxProfit;
						} else {
							result = ((closePrice - curPrice) * amount) + premium;
						}
					} else {
						if (closePrice < strikePrice) {
							result = premium;
						} else {
							result = ((strikePrice - closePrice) * amount) + premium;
						}
					}
				}
				break;
			case -2: // Short Put
				if (liqPrice && closePrice < liqPrice) {
					if (isBase) {
						result = -(collateral * liqPrice) + premium;
					} else {
						result = -collateral + premium;
					}
				} else {
					if (closePrice > strikePrice) {
						result = premium;
					} else {
						result = ((closePrice - strikePrice) * amount) + premium;
					}
				}
				break;
		}

		return result && Number(result.toFixed(2));
	}
	const createDataset = () => {
		if (!chartData) {
			return;
		}
		const avgStrike = chartData.strikes.reduce((avg, cur) => {
			return (avg + cur) / 2;
		}, chartData.strikes[0]);
		const delta = window.innerWidth > 480 ? minMaxDelta : minMaxDelta - 0.05;
		const min = Math.round(avgStrike * (1-delta) / 100) * 100;
		const max = Math.round(avgStrike * (1+delta) / 100) * 100;
		const points = [];
		
		for (let i = 0; i < (max - min) / priceStep; i++) {
			const point = min + i * priceStep;
			points.push(point);
		}
		
		const dataset = [];
		chartData.strategies.forEach((strat, i) => {
			points.forEach((point, j) => {
				if (i === 0) {
					dataset.push({
						closePrice: point,
						pnl: calcPnl(i, point),
					})
				} else {
					dataset[j].pnl = dataset[j].pnl + calcPnl(i, point);
				}
			})
		})

		return dataset;
	}
	const data = createDataset();

	// Area Split color
	const gradientOffset = () => {
		if (!data) {
			return;
		}
		const dataMax = Math.max(...data.map((i) => i.pnl));
		const dataMin = Math.min(...data.map((i) => i.pnl));
	
		if (dataMax <= 0) {
			return 0;
		}
		if (dataMin >= 0) {
			return 1;
		}
	
		return dataMax / (dataMax - dataMin);
	};
	const off = gradientOffset();

	// Custom Elements
	const CustomDot = ({ value, cx, cy }) => {
		const xValue = value[1];
		let color;
		if (xValue < 0) {
			color = RED;
		} else if (xValue > 0) {
			color = GREEN;
		} else {
			color = LGREY;
		}

		return (
			<circle
				cx={cx}
				cy={cy}
				r={5}
				style={{opacity: "0.9"}}
				fill={color} />
		)
	}
	const CustomTooltip = (props) => {
		const { active, payload, label } = props;
		const value = active && payload && payload.length && Number(payload[0].value.toFixed(2));
		
		if (active && payload && payload.length) {
			return (
				<div className='Tooltip'>
					<div className="Tooltip-popup">
						<Currency currencyName={chartToken.symbol} isHlight={true} isSmall={true}>
							{numberWithCommas(label)}
						</Currency>
						<p>
							PnL <span className={cx({ positive: value > 0, negative: value < 0 })}>
								{`${value !== 0 ? (value > 0 ? '+' : '-') : ""}$${numberWithCommas(Math.abs(value))}`}
							</span>
						</p>
					</div>
				</div>
			)
		}
		return null;
	}


	// Axis settings
	const tickFormatter = (val => {
		return numberWithCommas(val);
	})
	const tick = {
		fill: LGREY,
		fontSize: 13,
	}
	const axisStroke = GREY;

	// Interval calc
	const [interval, setInterval] = useState(null);
	useEffect(() => {
		if (chartRef.current && data) {
			let interval;
			const temp = Math.round(pxPerPoint / (chartRef.current.clientWidth / data.length));
			if (temp < 20) {
				interval = 19;
			} else {
				interval = (Math.floor(temp / 20) * 20) - 1;
			}
			setInterval(interval);
		}
	}, [chartRef.current, data, window.innerWidth]);

	return (
		<div className={cx(className, 'BuilderPositionChart')} style={style} ref={chartRef}>
			{isChartReady ? (
				<ResponsiveContainer>
					<AreaChart
						data={data}
						margin={{}}
					>
						<XAxis
							dataKey="closePrice"
							interval={interval}
							tickFormatter={tickFormatter}
							tick={tick}
							stroke={axisStroke}
						/>
						<YAxis 
							dataKey="pnl"
							tickFormatter={tickFormatter} 
							tick={tick}
							stroke={axisStroke}
							padding={{
								bottom: 15,
							}}
						/>
						<Tooltip
							cursor={{ stroke: LGREY }}
							content={<CustomTooltip />}
						/>
						<Area 
							type="monotone"
							dot={false}
							dataKey="pnl"
							stroke="url(#splitColor)"
							activeDot={<CustomDot />}
							fill="url(#splitColorTransparent)"
						/>
						<defs>
							<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
								<stop offset={off} stopColor={GREEN} stopOpacity={1} />
								<stop offset={off} stopColor={RED} stopOpacity={1} />
							</linearGradient>
							<linearGradient id="splitColorTransparent" x1="0" y1="0" x2="0" y2="1">
								<stop offset={off} stopColor={GREEN} stopOpacity={0.3} />
								<stop offset={off} stopColor={RED} stopOpacity={0.3} />
							</linearGradient>
						</defs>
						<ReferenceLine 
							x={curPriceRounded}
							stroke={GREY}
							strokeDasharray="3 3"
							label={{
								value: `Current Price`,
								fill: GREY,
								fontSize: window.innerWidth > 768 ? 15 : 13,
								position: "insideTopLeft",
								writingMode: "tb-rl",
								offset: 9,
							}}
						/>
						{collaterals && collaterals.length && collaterals.map(collateralObj => {
							if (!collateralObj) {
								return;
							}
							const liqPrice = fromBigNumber(collateralObj.liquidationPrice, OPTIONS_DECIMALS);
							const liqPriceRounded = Math.round(liqPrice / priceStep) * priceStep;
							return (
								<ReferenceLine
									x={liqPriceRounded}
									stroke={RED}
									strokeDasharray="3 3"
									label={{
										value: `Liq. Price`,
										fill: RED,
										fontSize: window.innerWidth > 768 ? 15 : 13,
										position: "insideTopLeft",
										writingMode: "tb-rl",
										offset: 9,
									}}
								/>
							)
						})}
						<CartesianGrid stroke={"rgba(58, 68, 102, 0.15)"} />
					</AreaChart>
				</ResponsiveContainer>
			) : (
				<div className='BuilderPositionChart__tip'>
					Fill out the Strike fields to view position chart
				</div>
			)}
		</div>
	);
};

export default BuilderPositionChart;