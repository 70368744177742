import React, { useEffect, useRef } from 'react';
import cx from "classnames";
import "./TradingView.scss";

let tvScriptLoadingPromise;

export default function TradingViewWidget({ className, style, chartToken }) {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_15468') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: `BINANCE:${chartToken.symbol}USDT`,
            interval: "240",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            hide_side_toolbar: false,
            container_id: "tradingview_15468",
            allow_symbol_change: true,
          });
        }
      }
    },
    [chartToken?.symbol]
  );

  return (
    <div className={cx('tradingview-widget-container', className)} style={style}>
      <div id='tradingview_15468' />
      <div className='TradingView__copyright text-inline'>
        <a href="https://www.tradingview.com/symbols/ETHUSDT/?exchange=BINANCE" rel="noopener" target="_blank"><span>ETHUSDT chart</span></a> by TradingView
      </div>
    </div>
  );
}

