import { useState, useEffect } from 'react';
import './OptionsList.scss';
import Loader from './../../../../components/UI/loader/Loader';
import OptionsItem from './OptionsItem';
import Tooltip from './../Tooltip/Tooltip';
import Chai_ABI from '../../../../deployments/options/Chai_ABI.json';
import { CHAI_CONTRACT_ETH, CHAI_CONTRACT_BTC } from '../../../../components/utils/contracts';
import useSWR from 'swr';
import { ethers } from 'ethers';
import icon_btnUp from '../../../../img/icon-btn-up.svg';
import Filter from './../../../../components/UI/Filter/Filter';
import { marketsOptions, OPTION_LIST_FRONTIER } from './../../../../components/utils/constants';
import { useLocalStorageSerializeKey, ARBITRUM } from './../../lib/legacyOptions';
import BuyPositionModal from './BuyPositionModal';
import useSort from '../../../../hooks/useSort';

const OptionsList = (props) => {
	const {
		active,
		walletAddress,
		library,
		options,
		optionPrices,
		isLoading,
		scrollRef,
		swapOption,
		infoTokens,
		pendingPositions,
		setPendingPositions,
	} = props;

	const chaiAddress = options[0]?.token === 'ETH'
    ? CHAI_CONTRACT_ETH : CHAI_CONTRACT_BTC;

	const { data: markPrice } = useSWR(
		active && [active, options[0]?.token, "getMarkPrice"],
		{
			fetcher: async () => {
				const contract = new ethers.Contract(chaiAddress, Chai_ABI, library.getSigner());
				return await contract.latestAnswer();
			},
			refreshInterval: 5000
		}
	)

	const isCall = swapOption === 'Call';

	const [markets, setMarkets] = useLocalStorageSerializeKey([ARBITRUM, "Options-Markets"], marketsOptions.slice());
	const [sortOptions, sortedOptions, setSortedOptions, sortType] = useSort(options, markets, "market");

	useEffect(() => {
		const [param, dir] = sortType.split('__');
		sortOptions(param, dir, true);
	}, [markets]);
	
	useEffect(() => {
		if (options.length) {
			sortOptions('strike', isCall ? -1 : 1, true);
		} else {
			setSortedOptions([]);
		}
	}, [options.length]);

	const [position, setPosition] = useState(null);
	const [buyModalVisible, setBuyModalVisible] = useState(false);
	const buyPosition = (position) => {
    setPosition(position);
    setBuyModalVisible(true);
  }

	const renderHead = () => {
		return (
			<tr className="Exchange-list-header">
				<th>
					Market
				</th>
				<th>
					<Tooltip
						className="has-hint-tooltip nowrap"
						handle="Type"
						position="left-bottom"
						enabled={true}
						renderContent={() => {
							return (
								<div className="Tooltip__text">
									American-style options may <br />exercise at any time before <br />the option expires.<br />European-style options may <br />exercise only at expiration.
								</div>
							);
						}}
					/>
				</th>
				<th>
					<button
						className='btn_table-sort'
						onClick={() => {
							sortOptions('strike');
						}}
					>
						Strike
					</button>
				</th>
				<th>
					Expires in
				</th>
				<th>
					Date
				</th>
				<th>
					<button
						className='btn_table-sort'
						onClick={() => {
							sortOptions('cost');
						}}
					>
						Total Cost
					</button>
				</th>
				<th>
					<button
						className='btn_table-sort'
						onClick={() => {
							sortOptions('price');
						}}
					>
						Price per Option
					</button>
				</th>
				<th></th>
			</tr>
		)
	}

	if (!options) {
		return null;
	}
	return (
		<div className="OptionsList block" ref={scrollRef}>
			<div className="OptionsList__header">
				<h1 className='OptionsList__title'>Available Options</h1>
				<Filter
					items={marketsOptions}
					selectedItems={markets}
					setSelectedItems={setMarkets}
				/>
			</div>
			<div className="table-wrapper table-wrapper_scrollable">
				<table className="Exchange-list large App-box">
					{renderHead()}
					<tbody>
						{(!sortedOptions.length || !markPrice) ?
							<tr>
								<td colSpan={8}>
									{
										isLoading ?
										<Loader /> :
											(options.length 
												? "Please select markets from the list above"
												: "Please choose the desired amount and period to view the available options")
									}
								</td>
							</tr>
							:
							(isLoading
								? <tr>
										<td colSpan={8}>
											<Loader />
										</td>
									</tr>
								: sortedOptions.map(opt => {
										const optPriceObj = optionPrices[opt.strategy];
										opt.price = optPriceObj?.price;
										opt.cost = optPriceObj?.cost;
										
										return(
											<OptionsItem
												key={opt.strategy}
												option={opt}
												markPrice={markPrice}
												buyPosition={buyPosition}
											/>
										)
									}))
						}
					</tbody>
				</table>
			</div>
			<button className="btn_up"
				onClick={() => {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					});
				}}
			>
				<img src={icon_btnUp} alt="scroll up button" />
			</button>

			<BuyPositionModal
				visible={buyModalVisible}
        setVisible={setBuyModalVisible}
        position={position}
        setPosition={setPosition}
				walletAddress={walletAddress}
				library={library}
				infoTokens={infoTokens}
				pendingPositions={pendingPositions}
				setPendingPositions={setPendingPositions}
			/>
		</div>
	);
};

export default OptionsList;