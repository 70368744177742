import React, { useEffect, useState } from 'react';
import BuilderForm from './BuilderForm/BuilderForm';
import { ARBITRUM, useLocalStorageByChainId } from '../../gmx-test/lib/legacy';
import useSWR from 'swr';
import { CHAI_CONTRACT_BTC, CHAI_CONTRACT_ETH, Chai_ABI } from '../../../components/utils/contracts';
import { ethers } from 'ethers';
import { signer } from '../../../components/utils/providers';
import { getToken, getTokenBySymbol, getTokens } from '../../gmx-test/config/Tokens';
import BuilderChart from './BuilderChart/BuilderChart';
import BuilderPositions from './BuilderPositions/BuilderPositions';
import { useWeb3React } from '@web3-react/core';

const Builder = (props) => {
	const {
		connectWallet,
		infoTokens,
	} = props;

	const { active, account } = useWeb3React();
	
	useEffect(() => {
		document.title = "Position Builder | DeDeLend";
	}, []);

	const [fetchPositionsTrigger, setFetchPositionsTrigger] = useState(false);
	const triggerPositionsFetch = () => {
		setFetchPositionsTrigger(!fetchPositionsTrigger);
	}
	const [asset, setAsset] = useLocalStorageByChainId(ARBITRUM, "Asset", "ETH");
	const [tokenSelection, setTokenSelection] = useState(getTokenBySymbol(ARBITRUM, asset).address);
	useEffect(() => {
		setTokenSelection(getTokenBySymbol(ARBITRUM, asset).address);
	}, [asset]);
	useEffect(() => {
		setAsset(getToken(ARBITRUM, tokenSelection).symbol)
	}, [tokenSelection]);

	const chaiAddress = asset === 'ETH'
    ? CHAI_CONTRACT_ETH : CHAI_CONTRACT_BTC;
  const { data: chaiMarkPrice } = useSWR(
    active && [active, asset, "getMarkPrice_Options", account],
    {
      fetcher: async () => {
        const contract = new ethers.Contract(chaiAddress, Chai_ABI, signer);
        return await contract.latestAnswer();
      },
      refreshInterval: 5000,
    }
  )

	const [positionChartData, setPositionChartData] = useState({
		amount: null,
		strikes: [],
		strategies: [],
		premiums: [],
		isBaseArr: [],
	});
	const [positionChartCollaterals, setPositionChartCollaterals] = useState([]);
	
	return (
		<div className="Builder">
			<BuilderForm
				asset={asset}
				setAsset={setAsset}
				dates={[]}
				chaiMarkPrice={chaiMarkPrice}
				infoTokens={infoTokens}
				connectWallet={connectWallet}
				triggerPositionsFetch={triggerPositionsFetch}
				positionChartData={positionChartData}
				setPositionChartData={setPositionChartData}
				positionChartCollaterals={positionChartCollaterals}
				setPositionChartCollaterals={setPositionChartCollaterals}
			/>
			<BuilderChart
				infoTokens={infoTokens}
				tokenSelection={tokenSelection}
				setTokenSelection={setTokenSelection}
				positionChartData={positionChartData}
				positionChartCollaterals={positionChartCollaterals}
			/>
			<BuilderPositions
				infoTokens={infoTokens}
				fetchPositionsTrigger={fetchPositionsTrigger}
				triggerPositionsFetch={triggerPositionsFetch}
			/>
		</div>
	);
};

export default Builder;