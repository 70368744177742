import React, { useState, useEffect } from 'react';
import { formatForContract } from '../../utils/math';
import Modal from './Modal';
import { formatAmount, formatAmountFree, USD_DECIMALS } from './../../../views/gmx-test/lib/legacy';
import NumberInput_v3 from './../input/NumberInput_v3';
import { CALL, PUT, SL, TP } from './../../../views/options/lib/legacyOptions';
import { OPTIONS_DECIMALS } from '../../utils/constants';
import cx from "classnames";
import { executePromise, fromBigNumber } from '../../utils/utils';
import Currency from './../../Currency';
import {
	getTPMethod,
	getTPContract,
} from '../../utils/contracts';
import Tooltip from '../../../views/gmx-test/components/Tooltip/Tooltip';
import icon_bin from '../../../img/icon-bin.svg';
import "./SLTP.scss";
import Spinner from '../loader/Spinner';

const SLTPModalOptions = (props) => {
	const {
		visible,
		setVisible,
		isTP,
		position,
		setPosition,
		SLTPCommission,
		markPrices,
		library,
		triggerPositionsFetch,
	} = props;

	const [priceValue, setPriceValue] = useState('');
	const [amountValue, setAmountValue] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isRemoving, setIsRemoving] = useState();
	const [isApproved, setIsApproved] = useState(null);
	const isActionSet = Boolean(isTP ? (!position?.tp || position.tp.eq(0)) : (!position?.sl || position.sl.eq(0)));
	const actionStr = isActionSet ? "Set" : "Update";
	const titleStr = isTP ? TP : SL;
	const btnText = `${actionStr} ${titleStr}`;
	const title = `${isActionSet ? "Set" : "Edit"} ${titleStr}`;
	const isHegic = position?.market === "Hegic";

	const markPrice = markPrices[position?.token];

	useEffect(() => {
		if (position && visible) {
			setAmountValue(fromBigNumber(position.size, OPTIONS_DECIMALS));
			position.checkIsTpApproved()
				.then(setIsApproved);
		}
	}, [position]);

	const executeCb = () => {
		position.setIsSLTPSpinning(true);
		if (triggerPositionsFetch) {
			triggerPositionsFetch();
		}
		setVisible(false);
	}
	const onApply = async () => {
		if (!isApproved) {
			executePromise(position.approveTp, [], "ERC721 approved!", setIsSubmitting, () => {
				setIsApproved(true);
			});
			return;
		}
		const method = getTPMethod(isActionSet ? "set" : "update", position.market);
		const contract = getTPContract(position.market, position.token, library.getSigner());
		const id = position.id;
		const price = formatForContract(priceValue, 8);
		const isCall = position.isCall;
		const takeType = isTP
			? isCall ? 0 : 1
			: isCall ? 1 : 0;
		const args = [id, price, takeType];
		const successMsg = `${titleStr} ${isActionSet ? "set" : "updated"}!`;
		if (isActionSet) {
			args.push({ value: await contract.commissionSize() });
		}
		executePromise(contract[method], args, successMsg, setIsSubmitting, executeCb);
	}
	const onRemove = () => {
		// Lyra SL/TP bug fix
		const contract = getTPContract(position.hasOldTP ? `Lyra_old` : position.market, position.token, library.getSigner());
		const method = getTPMethod("delete", position.market);
		executePromise(contract[method], [position.id], `${titleStr} removed!`, setIsRemoving, executeCb);
	}

	const getError = () => {
		if (!position) {
			return btnText;
		}

		if (isApproved === null) {
			return `Please, wait`;
		}

		if (isSubmitting) {
			if (isApproved) {
				return `Submitting...`;
			}
			return `Approving...`;
		}
		if (isRemoving) {
			return `Removing...`;
		}

		if (!amountValue && !isHegic) {
			return `Enter amount`;
		}
		const userAmount = formatForContract(amountValue, 18);
		const curAmount = fromBigNumber(position.size, 0);
		if (userAmount > curAmount) {
			return `Amount exceeds option's size`
		}
		if (!priceValue || priceValue == 0) {
			return `Enter price`;
		}
		
		const isCall = position.isCall;
		const curPrice = Number(formatAmount(markPrice, 20, 0, 0));
		const userPrice = formatForContract(priceValue, 10);
		
		if (userPrice === 0) {
			return;
		}

		if (!isTP) {
      if ((isCall && userPrice > curPrice) || (!isCall && userPrice < curPrice)) {
        return `${isCall ? CALL : PUT} SL can't be ${isCall ? 'greater' : 'less'} than mark price`;
      }
    }
    if (isTP) {
      if ((isCall && userPrice < curPrice) || (!isCall && userPrice > curPrice)) {
        return `${isCall ? CALL : PUT} TP can't be ${isCall ? 'less' : 'greater'} than mark price`;
      }
    }
	}
	const getBtnText = () => {
		const error = getError();
		if (error) {
			return error;
		}

		if (!isApproved) {
			return `Approve`;
		}

		return btnText;
	}
	const isBtnEnabled = () => {
		const hasError = Boolean(getError());
		
		if (hasError) {
			return false;
		}

		return true;
	}

	const resetModal = () => {
		setPriceValue('');
		setAmountValue('');
		setPosition(null);
		setIsApproved(null);
	};

	const marketImg = position
		? require(`../../../img/icon-${position.market}.svg`).default
		: "";

	const emptyBodyNode = (
		<div className="modal__body">
			<div className="Exchange-info-row">
				<div className="Exchange-info-label">
					Market
				</div>
			</div>
			<div className="Exchange-info-row">
				<div className="Exchange-info-label">
					Strategy
				</div>
			</div>
			<div className="Exchange-info-row">
				<div className="Exchange-info-label">
					Strike
				</div>
			</div>
			<Tooltip
				className="Tooltip_input"
				handle={
					<NumberInput_v3
						title='Amount'
						disabled={true}
						value={amountValue}
						setValue={setAmountValue}
						displayValueTitle={'Amount'}
					/>
				}
				position="left-bottom"
				enabled={true}
				renderContent={() => {
					return (
						<div className="Tooltip__text">
							Partial closing is not supported
						</div>
					);
				}}
			/>
			<NumberInput_v3
				title='Price'
				value={priceValue}
				setValue={setPriceValue}
				displayValueTitle={'Current Price'}
				additionalSymbol={"$"}
			/>
			<div className="Exchange-info-row">
				<div className="Exchange-info-label">
					Fee
				</div>
			</div>
			<div className="text-inline Tip Tip_bordered">
				The option will automatically be exercised if the take-profit price is reached. If the price doesn’t cross the take-profit threshold within 30 minutes before the expiration date, the option will be exercised automatically
			</div>
			<button 
				className="SLTP__btn modal__btn btn btn_hlight"
				disabled
			>
				...
			</button>
		</div>
	)

	return (
		<Modal
			className="PositionModal modal_SLTP modal_SLTP-Options"
			visible={visible}
			setVisible={setVisible}
			resetModal={resetModal}
		>
			<h1 className="modal__title">{title}</h1>
			{position ? (
				<div className="modal__body">
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Market
						</div>
						<div className="align-right PositionModal__market-logo">
							<img src={marketImg} alt={`${position.market} logo`} />
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Strategy
						</div>
						<div className="align-right">
							<span
								className={cx({ positive: position.isCall, negative: !position.isCall })}
							>
								{position.isCall ? CALL : PUT}
							</span>
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Strike
						</div>
						<div className="align-right">
							${formatAmount(position.strikePrice, OPTIONS_DECIMALS, 2, true)}
						</div>
					</div>
					<Tooltip
						className="Tooltip_input"
						handle={
							<NumberInput_v3
								title='Amount'
								disabled={true}
								value={amountValue}
								setValue={setAmountValue}
								displayValue={formatAmountFree(position.size, OPTIONS_DECIMALS, 3, true)}
								displayValueTitle={'Amount'}
								maxValue={fromBigNumber(position.size, OPTIONS_DECIMALS)}
								additionalSymbol={position.token}
							/>
						}
						position="left-bottom"
						enabled={true}
						renderContent={() => {
							return (
								<div className="Tooltip__text">
									Partial closing is not supported
								</div>
							);
						}}
					/>
					<NumberInput_v3
						title='Price'
						value={priceValue}
						setValue={setPriceValue}
						displayValue={formatAmount(markPrice, USD_DECIMALS, 2, true)}
						displayValueTitle={'Current Price'}
						additionalSymbol={"$"}
					/>
					{isActionSet && (
						<>
							<div className="Exchange-info-row">
								<div className="Exchange-info-label">
									{titleStr} Fee
								</div>
								<div className="align-right">
									{SLTPCommission && (
										<Currency currencyName='ETH' isSmall={true}>
											{formatAmount(SLTPCommission, OPTIONS_DECIMALS, 3, true) + " ($" + formatAmount(markPrices.ETH.div(SLTPCommission), OPTIONS_DECIMALS, 2, true) + ")"}
										</Currency>
									)}
									
								</div>
							</div>
							<div className="text-inline Tip Tip_bordered">
								{
									isHegic
									? "The option will automatically be exercised if the take-profit price is reached. If the price doesn’t cross the take-profit threshold within 30 minutes before the expiration date, the option will be exercised automatically"
									: "Your option will be sold automatically to the Lyra AMM when the price reaches your take-profit/stop-loss price. Please be aware that when we sell your option to the Lyra AMM, we can’t control the slippage"
								}
							</div>
						</>
					)}
					<button 
						className="SLTP__btn modal__btn btn btn_hlight"
						style={!isActionSet ? { marginTop: 0 } : {}}
						onClick={() => {
							setIsSubmitting(true);
							onApply();
						}}
						disabled={!isBtnEnabled()}
					>
						{getBtnText()}
					</button>
					{!isActionSet && (
						<div className='SLTP__remove-btn align-right'>
							<button
								className='btn_inline'
								onClick={onRemove}
								disabled={isRemoving}
							>
								<img className='btn__icon' src={icon_bin} alt="Bin icon" />
								{
									isRemoving
									? <Spinner />
									: `Remove ${isTP ? "TP" : "SL"}`
								}
							</button>
						</div>
					)}
				</div>
			) : emptyBodyNode}

			
		</Modal>
	);
};

export default SLTPModalOptions;