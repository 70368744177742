import React, { useState, useEffect } from 'react';
import { DDL_WALLET, OPTIONS_DECIMALS, assetsIcons, assetsOptions, tokenDecimals } from './../../../../components/utils/constants';
import Tab from '../../../gmx-test/components/Tab/Tab';
import InputSimple from './../../../../components/UI/input/InputSimple';
import { convertInputNum, sepToNumber, separateThousands } from '../../../../components/utils/sepThousands';
import './BuilderForm.scss';
import './CustomDate.scss';
import { ARBITRUM, expandDecimals, formatAmount, formatAmountFree, useLocalStorageByChainId } from '../../../gmx-test/lib/legacy';
import { getOptionComponentLabel, builderStrategies, strategyStatsLabels, getDefaultStrikes } from './../builderConstants';
import cx from "classnames";
import Selector from './../../../../components/UI/Selector/Selector';
import { HEGIC_STRATEGIES, HegicStrategy_ABI, PRICE_CALCULATORS, PriceCalculator_ABI, builderOpen, builderOpenContract, getIsUsdcApproved } from '../../../../components/utils/contracts';
import { fromBigNumber, getLastDateOfMonth, getMonthFromString, getSoftMaxCollateral, numToBn } from '../../../../components/utils/utils';
import { alchemyProvider, getLyra } from '../../../../components/utils/providers';
import { BigNumber, ethers } from 'ethers';
import { eachMonthOfInterval, format, lastDayOfMonth } from 'date-fns';
import Spinner from '../../../../components/UI/loader/Spinner';
import Currency from './../../../../components/Currency';
import Tooltip from '../../../gmx-test/components/Tooltip/Tooltip';
import { useBuilderFields } from '../../../../hooks/useBuilderFields';
import useDebounce from '../../../../hooks/useDebounce';
import BuyBuildModal from './BuyBuildModal';
import { useWeb3React } from '@web3-react/core';
import InputWithSelector from './../../../../components/UI/input/InputWithSelector';
import icon_edit from '../../../../img/icon-edit.svg';

const BuilderForm = (props) => {
	const {
		asset,
		setAsset,
		connectWallet,
		chaiMarkPrice,
		infoTokens,
		triggerPositionsFetch,
		setPositionChartData,
		positionChartCollaterals,
		setPositionChartCollaterals,
	} = props;

	const { active, account } = useWeb3React();
	const baseToken = `W${asset}`;
	
	const [modalVisible, setModalVisible] = useState(false);
	const [amount, setAmount] = useState('1');
	const [strategy, setStrategy] = useLocalStorageByChainId(ARBITRUM, "Builder-strategy-option-v2", "Long Call");
	const strategyType = strategy.split(' ')[0];
	const [lyraMarket, setLyraMarket] = useState(null);
	const [isCostLoading, setIsCostLoading] = useState(false);
	const [cost, setCost] = useState(null);
	const [received, setReceived] = useState(null);
	const [datesMs, setDatesMs] = useState([]);

	const fetchStrikes = (field, isCall) => {
		setPositionChartData(null);
		setCost(null);
		setReceived(null);
		const strikesPromises = [];
		const { defaultStrikes: strikes, defaultStrikesData: strikesData } = getDefaultStrikes();
		const data = {
			strikes,
			strikesData,
		}
		fetchHegicStrikes(field, isCall, strikesPromises, data);
		fetchLyraStrikes(field, isCall, strikesPromises, data);
		return { strikesPromises, data };
	}
	const builderFields = useBuilderFields(strategy, fetchStrikes, baseToken);
	const hasSell = !!builderFields.find(field => !field.isBuy);
	const hasBuy = !!builderFields.find(field => field.isBuy);
	const clearOptions = () => {
		setPositionChartData(null);
		setPositionChartCollaterals(null);
		builderFields.forEach(field => {
			field.dateMs[1](null);
			field.strike[1](null);
			field.clearData();
		})
	}
	
	const isBtnEnabled = () => {
		if (!active) {
			return true;
		}
		
		if (!amount) {
			return;
		}
		
		for (let i = 0; i < builderFields.length; i++) {
			const field = builderFields[i];
			if (!field.strike[0]) {
				return;
			}
		}

		return !getError();
	}
	const btnDefaultText = "Buy Strategy";
	const getError = () => {
		if (!amount) {
			return `Enter Amount`;
		}
		if (!builderFields.every(field => field.strike[0])) {
			return `Fill out the Strike fields`;
		}

		if (cost && cost.eq(-1)) {
			return `No Liquidity`;
		}
		if (!builderFields.every(field => {
			if (field.isBuy) {
				return true;
			}
			return field.collateral.value[0];
		})) {
			return `Fill out the Collateral fields`;
		}
		
		if (!cost || (hasSell && !received) || !builderFields.every(field => {
			if (field.isBuy) {
				return true;
			}
			return Boolean(field.collateral.info[0]);
		})) {
			return `Please, wait`;
		}
	}
	const getBtnText = () => {
		if (!active) {
			return `Connect wallet`;
		}
		return getError() ?? btnDefaultText;
	}

	// Set Lyra Market
	useEffect(() => {
		if (asset) {
			setLyraMarket(null);
			const LYRA = getLyra();
			LYRA.markets().then(markets => {
				const market = markets.find(mkt => {
					return mkt.baseToken.symbol === baseToken;
				});
				setLyraMarket(market);
			})
		}
	}, [asset]);
	// Fetch dates
	const fetchDates = () => {
		const dates = lyraMarket.liveBoards().map(bd => bd.expiryTimestamp * 10**3);
		return dates;
	}
	useEffect(() => {
		if (lyraMarket) {
			setDatesMs(fetchDates());
		}
	}, [lyraMarket]);
	// Clear fields data
	useEffect(() => {
		clearOptions();
	}, [asset, strategy]);

	// Fetch Strikes Fns
	const defaultAmount = expandDecimals(1, tokenDecimals[asset]);
	const fetchHegicStrikes = async (field, isCall, strikesPromises, data) => {
		const isBuy = builderStrategies[strategy].structure[field.id] > 0;
		if (!isBuy) {
			return;
		}
		const { strikes, strikesData } = data;
		const dateMs = field.dateMs[0];

    const periodDays = Math.floor((dateMs - Date.now()) / 1000 / 60 / 60 / 24);
    if (periodDays < 7 || periodDays > 90) {
      return;
    }
    let strategies;
    if (periodDays < 14) {
      strategies = HEGIC_STRATEGIES.short;
    } else if (periodDays < 30) {
      strategies = HEGIC_STRATEGIES.medShort;
    } else if (periodDays < 60) {
      strategies = HEGIC_STRATEGIES.medLong;
    } else if (periodDays <= 90) {
      strategies = HEGIC_STRATEGIES.long;
    }
    
    for (let i = 0; i < Object.keys(strategies).length; i++) {
      const strategy = Object.keys(strategies)[i];
      const [, strategySentiment, strikeScale, strategyTokenSymbol] = strategy.split('_');
      const isSpread = strategySentiment.split('-').length > 1;
      if (isSpread) {
        continue;
      }

      const strategyIsCall = strategySentiment === 'CALL';
      if ((isCall && !strategyIsCall) || (!isCall && strategyIsCall)) {
        continue;
      }
      if (strategyTokenSymbol !== asset) {
        continue;
      }
      
      const strike = (formatAmountFree(chaiMarkPrice, 8, 2) / 100 * strikeScale).toFixed(0);
			const priceName = `PriceCalculator_${strategySentiment}_${strikeScale}_${strategyTokenSymbol}`;
      const priceAddress = PRICE_CALCULATORS[priceName];
      const priceContract = new ethers.Contract(priceAddress, PriceCalculator_ABI, alchemyProvider);
			const periodS = Math.floor((dateMs - Date.now()) / 1000);

			const strategyAddress = strategies[strategy];
			const strategyContract = new ethers.Contract(strategyAddress, HegicStrategy_ABI, alchemyProvider);
			let hasLiq;
			try {
				hasLiq = !(await strategyContract.getAvailableContracts(periodS, [])).eq(0)
			} catch(e) {
				console.log(e);
			}
			if (!hasLiq) {
				continue;
			}
			
      strikesPromises.push(
				priceContract.calculatePremium(periodS, defaultAmount, 0)
					.then(res => {
						const price = fromBigNumber(res, 6).toFixed(2);
						strikes.Hegic.push([strike, price]);
					}, console.log)
			)

			strikesData.Hegic[strike] = {
				getParams: (amount) => {
					const amountBn = numToBn(amount, tokenDecimals[asset]);
					return new Promise((resolve, reject) => {
						resolve(builderOpenContract.encodeFromHegic(
							strategyAddress, // strategy
							builderOpen, // holder
							amountBn, // amount
							periodS, // period
							[] // additional
						));
					}) 
				},
				calcPremium: (amount) => {
					const amountBn = numToBn(amount, tokenDecimals[asset]);
					return priceContract.calculatePremium(periodS, amountBn, 0)
						.then(res => {
							const premium = expandDecimals(res, OPTIONS_DECIMALS - 6);
							return premium;
						}, console.log)
				},
			};
    }
  }
  const fetchLyraStrikes = (field, isCall, strikesPromises, data) => {
		const LYRA = getLyra();
		const { strikes, strikesData } = data;
		if (!lyraMarket) {
			return;
		}
		const dateMs = field.dateMs[0];
		const isBuy = builderStrategies[strategy].structure[field.id] > 0;
		
    const boardId = lyraMarket.liveBoards().find((bd) => {
			return bd.expiryTimestamp * 10**3 === dateMs;
    })?.id;
		if (!boardId) {
			return;
		}
    const board = lyraMarket.liveBoardsMap[boardId];
    
    board.strikes.forEach(strike => {
			const { strikeId, strikePrice } = strike;
			const strikeRounded = fromBigNumber(strikePrice, OPTIONS_DECIMALS);
      strikesPromises.push(
				LYRA.quoteOption(lyraMarket.address, strikeId, isCall, defaultAmount)
					.then(async result => {
						const res = result[isBuy ? "ask" : "bid"];
						const price = fromBigNumber(res.premium, tokenDecimals[asset]).toFixed(2);
						if (res.premium.eq(0) || /* res.isDisabled */ (res.disabledReason && res.disabledReason !== "UnableToHedgeDelta")) {
							return;
						}

						const getTrade = (amount, options) => {
							const SLIPPAGE = 0.0015;
							const size = numToBn(amount, OPTIONS_DECIMALS);
							
							return lyraMarket.trade(
								account,
								strikeId.toNumber(),
								isCall,
								isBuy,
								size,
								SLIPPAGE,
								options
							)
						}

						strikes.Lyra.push([strikeRounded, price]);
						strikesData.Lyra[strikeRounded] = {
							strikeId: strikeId.toNumber(),
							getParams: (amount, options) => {
								return getTrade(amount, options).then(trade => {
									const params = trade.params[0];
									return builderOpenContract.encodeFromLyra([
											params.strikeId,
											params.positionId,
											params.iterations,
											params.optionType,
											params.amount,
											params.setCollateralTo,
											params.minTotalCost,
											params.maxTotalCost,
											DDL_WALLET, // referrer
										]
									);
								})
							},
							calcPremium: (amount) => {
								return getTrade(amount).then(trade => {
									const totalCostParam = isBuy ? "maxTotalCost" : "minTotalCost";
									const premium = trade.params[0][totalCostParam];
									if ((premium && premium.eq(0)) || trade.disabledReason === "DeltaOutOfRange") {
										return null;
									}
									return trade.params[0][totalCostParam];
								})
							},
							getCollateral: (amount, options) => {
								return getTrade(amount, options)
									.then(trade => {
										if (trade.collateral && !trade.collateral.max) {
											trade.collateral.max = getSoftMaxCollateral(trade, trade.collateral);
										}
										return trade.collateral;
									})
							}
						}
					})
			)
    })

		return { strikes, strikesData };
  }

	// Calculate Max.Cost/Received and Collateral
	const calcMaxCost = () => {
		if (!amount || !builderFields.find(field => field.strike[0])) {
			return;
		}
		setIsCostLoading(true);
		setCost(null);
		let cost = BigNumber.from(0);
		let received = BigNumber.from(0);
		const premiumPromises = [];
		const amountNum = Number(amount);
		const premiums = [];
		const isBaseArr = builderFields.map(field => {
			if (!field.isBuy) {
				return field.collateral.token[0] !== "USDC";
			}
			return false;
		});
		builderFields.forEach(field => {
			const [strike, ] = field.strike;
			const [strikesData, ] = field.strikesData;
			
			Object.keys(strikesData).forEach(market => {
				const data = strikesData[market];
				if (!data[strike]) {
					return;
				}
				premiumPromises.push(
					data[strike].calcPremium(amountNum)
						.then(premium => {
							if (!premium) {
								throw new Error("No Liquidity");
							} else {
								if (field.isBuy) {
									cost = cost.add(premium);
								} else {
									received = received.add(premium);
								}
								premiums[field.id] = (Number(formatAmountFree(premium, OPTIONS_DECIMALS, 2)));
							}
						})
				)
			})
		})
		Promise.all(premiumPromises)
			.then(() => {
				setCost(cost);
				setReceived(received);
				setPositionChartData({
					amount: Number(amount),
					strikes: builderFields.map(field => Number(field.strike[0])),
					strategies: builderStrategies[strategy].structure,
					premiums,
					isBaseArr,
				});
			}, e => {
				if (e.message === "No Liquidity") {
					setCost(BigNumber.from(-1));
				}
			}).finally(() => {
				setIsCostLoading(false);
			});
	}
	const calcCollateral = (field, token) => {
		if (!amount || !builderFields.find(field => field.strike[0])) {
			return;
		}
		const amountNum = Number(amount);
		const positionChartCollaterals = [];
		const promises = [];
		const calcFn = (field) => {
			const [, setCollateralInfo] = field.collateral.info;
			setCollateralInfo(null);
			const [strike, ] = field.strike;
			const [strikesData, ] = field.strikesData;
			const isBuy = field.isBuy;
			const collateralToken = token ?? field.collateral.token[0];
			const [collateralValue, ] = field.collateral.value;
			const collateral = sepToNumber(collateralValue);
			
			Object.keys(strikesData).forEach(market => {
				const data = strikesData[market];
				if (!data[strike] || !data[strike].getCollateral) {
					return;
				}
				if (!isBuy) {
					promises.push(data[strike].getCollateral(amountNum, {
						isBaseCollateral: collateralToken !== "USDC",
						setToCollateral: numToBn(collateral, OPTIONS_DECIMALS),
					}).then(res => {
						setCollateralInfo(res);
						if (collateral) {
							positionChartCollaterals[field.id] = res;
						}
					}));
				}
			})
		}
		if (!field) {
			builderFields.forEach(calcFn);
		} else {
			calcFn(field);
		}
		Promise.all(promises).then(() => {
			setPositionChartCollaterals([...positionChartCollaterals]);
		});
	}

	useEffect(() => {
		calcMaxCost();
		calcCollateral();
		builderFields.forEach(field => {
			field.collateral.value[1]("");
		});
		setPositionChartCollaterals(null);
	}, [...builderFields.map(field => field.strike[0])]);
	const calcMaxCostDelayed = useDebounce(calcMaxCost, 800);
	useEffect(() => {
		calcMaxCostDelayed();
	}, [amount]);
	
	const calcCollateralDelayed = useDebounce(calcCollateral, 800);
	useEffect(() => {
		calcCollateralDelayed();
		// setPositionChartCollaterals(null);
	}, [
			/* Collateral value fields */
			...builderFields.map(field => {
				if (!field.isBuy) {
					return field.collateral.value[0];
				}
			}),
			amount,
	]);

	let collateralSums = [BigNumber.from(0), BigNumber.from(0)];
	if (hasBuy) {
		collateralSums = builderFields.reduce((sum, field) => {
			if (!field.isBuy) {
				const collateralInfo = field.collateral.info[0];
				if (collateralInfo) {
					const index = collateralInfo.isBase ? 0 : 1;
					sum[index] = sum[index].add(collateralInfo.amount);
				}
			}
			return sum;
		}, [BigNumber.from(0), BigNumber.from(0)])
	}
	

	// Render Chart Preview block
	const renderChartPreview = (strategy) => {
		if (!strategy) {
			return null;
		}
		return (
			<div className="BuilderForm__preview">
				<div className="BuilderForm__preview-img">
					<img src={require(`../../../../img/BuilderCharts/${strategy}.svg`).default} alt={`${strategy} chart`} />
				</div>
				<div className="BuilderForm__preview-stats">
					{Object.keys(builderStrategies[strategy].stats).map(stat => {
						const statValue = builderStrategies[strategy].stats[stat];
						return (
							<div className="BuilderForm__preview-stat" key={stat}>
								<div className="BuilderForm__preview-stat-name">
									{stat}
								</div>
								<div className={
									cx("BuilderForm__preview-stat-val", {
										positive: statValue === 1,
										negative: statValue === -1,
										neutral: statValue === 0,
									})
								} style={{whiteSpace: "nowrap"}}>
									{Array.isArray(statValue)
										? statValue.map((val, i) => {
												return (
													<span className={cx({
														positive: val === 1,
														negative: val === -1,
														neutral: val === 0,
													})}>
														{strategyStatsLabels[stat][val.toString()]}{(i !== statValue.length-1) ? ", " : ""}
													</span>
												)
											})
										: strategyStatsLabels[stat][statValue.toString()]}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
	
	return (
		<div className="BuilderForm App-box">
			<div className="BuilderForm__inputs">
				<div className="BuilderForm__field">
					<div className="BuilderForm__field-title">
						Asset
					</div>
					<div className="BuilderForm__field-content">
						<Tab
							icons={assetsIcons}
							options={assetsOptions}
							option={asset}
							setOption={setAsset}
							iconsFirst={true}
						/>
					</div>
				</div>
				<div className="BuilderForm__field">
					<div className="BuilderForm__field-title">
						Enter Amount
					</div>
					<div className="BuilderForm__field-content">
						<InputSimple 
							value={amount}
							onChange={(e) => {
								setAmount(convertInputNum(e.target.value, ''));
							}}
							unit={builderStrategies[strategy].inputLabel ?? `${strategy}s`}
						/>
					</div>
				</div>
				<div className="BuilderForm__field">
					<div className="BuilderForm__field-title">
						Strategy
					</div>
					<div className="BuilderForm__field-content">
						<Selector
							className="Selector_wide"
							items={Object.keys(builderStrategies)}
							chosenItem={strategy}
							titleIcon={
								<img
									src={require('../../../../img/icon-aggregator.svg').default}
									alt={"Selector icon"}
								/>
							}
							onSelect={(item) => {
								setStrategy(item);
							}}
							renderOnHover={(item) => {
								return renderChartPreview(item);
							}}
						/>
					</div>
				</div>
				<div className="BuilderForm__field BuilderForm__field_multiple">
					<div className="BuilderForm__field-title">
						Expiration Date
					</div>
					<div className="BuilderForm__field-content">
						{datesMs && (
							builderStrategies[strategy].structure.map((struct, i) => {
								const [dateMs, setDateMs] = builderFields[i].dateMs;
								const [dateObj, setDateObj] = builderFields[i].dateObj;
								const clearData = builderFields[i].clearData;

								const monthIntervalDates = eachMonthOfInterval({start: Date.now(), end: (new Date()).setFullYear((new Date()).getFullYear() + 1)});
								monthIntervalDates.splice(monthIntervalDates.length - 1, 1)
								const monthInterval = monthIntervalDates.map(date => {
									return format(date, "MMMM");
								});

								const maxDate = getLastDateOfMonth(dateObj.month, dateObj.year);

								const customDateStr = `${dateObj.year}-${(getMonthFromString(dateObj.month)+1) < 10 ? "0" : ""}${getMonthFromString(dateObj.month) + 1}-${dateObj.date < 10 ? "0" : ""}${dateObj.date}T08:00:00.000`;
								const customDate = new Date(customDateStr);
								customDate.setUTCHours(8);

								return (
									<Selector
										key={i}
										className="Selector_wide"
										items={datesMs}
										chosenItem={dateMs}
										defaultText="Choose Expiration Date"
										formatFn={(date, isChosen) => {
											return format(date, isChosen ? 'd MMM yyyy hh:mm a' : 'd MMM');
										}}
										titleIcon={
											<img
												src={require('../../../../img/icon-date.svg').default}
												alt={"Selector icon"}
											/>
										}
										onSelect={(item) => {
											setDateMs(item);
											clearData();
										}}
										footerNode={
											<div>
												<div className="divider" style={{margin: "10px 15px", width: "calc(100% - 30px)"}} />
												<div className='Custom-date'>
													<h3 className="Custom-date__title" onClick={(e) => e.stopPropagation()}>Custom Date</h3>
													<div className="Custom-date__inputs">
														<div onClick={(e) => e.stopPropagation()}>
															<InputSimple
																setValue={(val) => {
																	dateObj.setDate(val, maxDate);
																	setDateObj({...dateObj});
																}}
																value={dateObj.date}
																type="number"
																unit={<img src={icon_edit} alt="edit date" />}
															/>
														</div>
														<div onClick={(e) => e.stopPropagation()}>
															<Selector
																items={monthInterval}
																chosenItem={dateObj.month}
																onSelect={(item) => {
																	const maxDate = getLastDateOfMonth(item, dateObj.year)
																	dateObj.setMonth(item, maxDate);
																	setDateObj({...dateObj});
																}}
															/>
														</div>
														<div onClick={(e) => e.stopPropagation()}>
															<Selector
																items={
																	[(new Date()).getFullYear(), (new Date()).getFullYear() + 1]
																}
																chosenItem={dateObj.year}
																onSelect={(item) => {
																	dateObj.setFullYear(item);
																	setDateObj({...dateObj});
																}}
															/>
														</div>
														<div>
															<button
																className='btn btn_small'
																onClick={(e) => {
																	setDateMs(customDate.getTime());
																}}
																disabled={!dateObj.date || customDate.getTime() < Date.now()}
															>
																Confirm
															</button>
														</div>
													</div>
												</div>
											</div>
										}
									/>
								)
							})
						)}
					</div>
				</div>
				<div className="BuilderForm__field BuilderForm__field_multiple">
					<div className="BuilderForm__field-title">
						<Tooltip
							className="has-hint-tooltip nowrap"
							handle="Strike"
							position="left-bottom"
							enabled={true}
							renderContent={() => {
								return (
									<div className="Tooltip__text">
										<h6>Hegic Options (American options)</h6>
										<div style={{margin: "3px 0"}}>They require manual exercise before <br/>the expiration date. <br/>There are no secondary markets; out-<br/>of-the-money options can't be sold.</div>
										<h6>Lyra (European options)</h6>
										<div style={{margin: "3px 0"}}>They are only exercised automatically <br/>at the expiration date. <br/>Secondary markets are available; out-<br/>of-the-money options can be sold.</div>
									</div>
								);
							}}
						/>
					</div>
					<div className="BuilderForm__field-content">
						{builderStrategies[strategy].structure.map((struct, i) => {
							const strategyObj = builderStrategies[strategy];
							const field = builderFields[i];
							const [dateMs,] = field.dateMs;
							const [strike, setStrike] = field.strike;
							const [market, setMarket] = field.market;
							const [strikes,] = field.strikes;
							const [isLoading, ] = field.loader;

							return (
								isLoading
									? <div className="Spinner" key={i}><Spinner /></div>
									: <Selector
											key={i}
											className="Selector_wide"
											items={strikes}
											chosenItem={strike}
											defaultText="Choose Strike"
											disabled={!dateMs}
											onSelect={(item, market) => {
												setMarket(market);
												setStrike(item);

												if (strategyObj.isStrikeUnified) {
													let unifiedFields = builderFields;
													if (strategy === "Iron Butterfly") {
														unifiedFields = [builderFields[1], builderFields[2]];
													}
													unifiedFields.forEach(depField => {
														if (field === depField) {
															return;
														}
														if (strategy === "Iron Butterfly") {
															if (i !== 1 && i !== 2) {
																return;
															}
														}
														const [, setDepStrike] = depField.strike;
														const [, setDepMarket] = depField.market;
														const [depStrikes, ] = depField.strikes;
														const chosenStrike = depStrikes[market]?.find(strike => strike[0] === item)?.[0];

														if (chosenStrike) {
															setDepMarket(market);
															setDepStrike(chosenStrike);
														}	else {
															setDepStrike(null);
														}
													})
												}
											}}
											titleIcon={
												<span className={cx({
													positive: struct > 0,
													negative: struct < 0,
												})}>
													{getOptionComponentLabel(struct)}
												</span>
											}
											formatFn={(item) => {
												return `$${separateThousands(item)}`;
											}}
											sortFn={(items) => {
												return items.sort((prev, cur) => {
													if (prev[0] > cur[0]) {
														return 1;
													} else if (prev[0] < cur[0]) {
														return -1;
													}
													return 0;
												})
											}}
											isComplex={true}
											complexCols={["Price, USDC"]}
											renderChosenItem={() => {
												const price = strikes[market]?.find(strikeArr => {
													return strikeArr[0] === strike
												})?.[1];
												let marketImg;
												try {
													marketImg = require(`../../../../img/icon-${market}.svg`).default;
												} catch (e) {
													console.log(e);
												}
												
												return (
													<div className="Selector__chosen-item-complex-content">
														<div className="market">
															{marketImg && <img src={marketImg} alt={`${market} icon`}
																style={{transform: market === "Hegic" ? "translate(-2px, -1px)" : ""}}
															/>}
														</div>
														<div className="strike">
															${separateThousands(strike)};
														</div>
														<div className="price">
															<Currency isHlight={true}>
																{separateThousands(price)}
															</Currency>
														</div>
													</div>
												)
											}}
										/>
							)
					})}
					</div>
				</div>
				<div className="BuilderForm__field">
					{renderChartPreview(strategy)}
				</div>
				<div className="BuilderForm__field BuilderForm__field_multiple">
					<div className="BuilderForm__field-title">
						<Tooltip
							className="has-hint-tooltip nowrap"
							handle="Collateral"
							position="left-bottom"
							enabled={true}
							renderContent={() => {
								return (
									<div className="Tooltip__text">
										This refers to the amount <br/>you’re setting aside <br/>as collateral for selling options
									</div>
								);
							}}
						/>
					</div>
					<div className="BuilderForm__field-content">
						{builderStrategies[strategy].structure.map((struct, i) => {
							if (struct < 0 /* which means selling option */) {
								const builderField = builderFields[i];
								const isCall = Math.abs(struct) === builderStrategies["Long Call"].key;
								const [collateralValue, setCollateralValue] = builderField.collateral.value;
								const [collateralToken, setCollateralToken] = builderField.collateral.token;
								const isBase = collateralToken !== "USDC";
								const [collateral, ] = builderField.collateral.info;
								const strike = builderField.strike[0];

								const collateralTokens = !isCall ? ["USDC"] :
									(strategyType === "Covered" || strategyType === "Short"
									? builderStrategies[strategy].collateralTokens.map(token => {
											return token === "base" ? baseToken : token;
										})
									: ["USDC", baseToken])
								
								return (
									<div className='BuilderForm__field-content-line' key={i}>
										<InputWithSelector
											input={
												<InputSimple
													value={collateralValue}
													onChange={(e) => {
														setCollateralValue(convertInputNum(e.target.value, ','));
													}}
													disabled={!strike || isCostLoading}
													placeholder={
														collateral ?
															`${isBase ? "" : "$"}${formatAmountFree(collateral.min, OPTIONS_DECIMALS, 2)} — ${formatAmountFree(collateral.max, OPTIONS_DECIMALS, 2)}`
															: `Enter Amount`
													}
													hasError={collateralValue && collateral instanceof Error}
													unit={
														collateral && collateralValue &&
															<span style={{fontSize: 13, lineHeight: "130%"}}>
																Liq. Price:
																<span style={{color: "#fff", marginLeft: 4}}>
																	{collateral.liquidationPrice ?
																	`$${formatAmount(collateral.liquidationPrice, OPTIONS_DECIMALS, 0, true)}` : "—"}
																</span>
															</span>
													}
												/>
											}
											selector={
												<Selector
													disabled={!isCall || strategyType !== "Iron"}
													items={collateralTokens}
													formatFn={(item) => {
														return item === "USDC" ? "USDC.e" : item;
													}}
													chosenItem={collateralToken}
													onSelect={(token) => {
														setCollateralToken(token);
														setCollateralValue("");
														calcCollateral(builderField, token);
													}}
													className="Selector_wide Selector_token"
												/>
											}
										/>
									</div>
								)
							} else {
								return (
									<div className='BuilderForm__field-content-line' key={i}>
										<span className={cx("muted")}>
											—
										</span>
									</div>
								)
							}
						})}
					</div>
				</div>
			</div>
			<div className="BuilderForm__buyer">
				<div className="BuilderForm__buyer-fields">
					{hasSell && (
						<div className="BuilderForm__field BuilderForm__buyer-field">
							<div className="BuilderForm__field-title">
								<Tooltip
									className="has-hint-tooltip nowrap"
									handle="Min. Received"
									position="left-bottom"
									enabled={true}
									renderContent={() => {
										return (
											<div className="Tooltip__text">
												This is the least amount you’re <br/>assured to receive when <br/>selling options. If the option <br/>price drops beyond this point, <br/>your transaction will <br/>be reverted.
											</div>
										);
									}}
								/>
							</div>
							<div className="BuilderForm__field-content">
								{isCostLoading
									? <Spinner />
									:	<Currency isSmall={true} isHlight={!!received}>
											{received ? formatAmount(received, OPTIONS_DECIMALS, 2, true) : "—"}
										</Currency>}
							</div>
						</div>
					)}
					{hasBuy && (
						<div className="BuilderForm__field BuilderForm__buyer-field">
							<div className="BuilderForm__field-title">
								<Tooltip
									className="has-hint-tooltip nowrap"
									handle="Max. Cost"
									position="left-bottom"
									enabled={true}
									renderContent={() => {
										return (
											<div className="Tooltip__text">
												This is the highest amount <br/>you’ll pay for buying options. <br/>If the option price rises <br/>beyond this, your transaction <br/>will be reverted.
											</div>
										);
									}}
								/>
							</div>
							<div className="BuilderForm__field-content">
								{isCostLoading
									? <Spinner />
									:	<Currency isSmall={true} isHlight={!!cost}>
											{cost ? formatAmount(cost, OPTIONS_DECIMALS, 2, true) : "—"}
										</Currency>}
							</div>
						</div>
					)}
					{hasSell && (
						<div className="BuilderForm__field BuilderForm__buyer-field">
							<div className="BuilderForm__field-title">
								Collateral
							</div>
							<div className="BuilderForm__field-content">
								{collateralSums.map((sum, i) => {
									const isBase = i === 0;
									if (sum.eq(0)) {
										if (isBase) {
											return;
										}
										if (!collateralSums[0].eq(0)) {
											return;
										}
										return (
											<Currency isSmall={true} isHlight={!!received} currencyName={
												strategyType === "Covered" ? baseToken : "USDC"
											}>
												—
											</Currency>
										)
									}
									return (
										<Currency isSmall={true} isHlight={!!received} currencyName={isBase ? baseToken : "USDC"}>
											{isBase
												? formatAmountFree(sum, OPTIONS_DECIMALS, 3)
												: formatAmount(sum, OPTIONS_DECIMALS, 0, true)}
										</Currency>
									)
								})}
								
							</div>
						</div>
					)}
				</div>
				<button
					className="BuilderForm__button btn btn_hlight"
					disabled={!isBtnEnabled()}
					onClick={() => {
						if (!active) {
							connectWallet();
						} else {
							setModalVisible(true)
						}
					}}
				>
					{getBtnText()}
				</button>
			</div>
			<BuyBuildModal
				visible={modalVisible}
				setVisible={setModalVisible}
				fields={builderFields}
				strategy={strategy}
				amount={amount}
				asset={asset}
				maxCost={cost}
				infoTokens={infoTokens}
				triggerPositionsFetch={triggerPositionsFetch}
				received={received}
				hasSell={hasSell}
				hasBuy={hasBuy}
				collateralSums={collateralSums}
			/>
		</div>
	);
};

export default BuilderForm;