import React from 'react';
import Tab from '../../../gmx-test/components/Tab/Tab';
import BuilderPositionsList from './BuilderPositionsList';
import { ARBITRUM, useLocalStorageByChainId } from '../../../gmx-test/lib/legacy';
import { useWeb3React } from '@web3-react/core';
import './BuilderListSection.scss';

const BuilderListSection = (props) => {
	const {
		positions,
		infoTokens,
    positionsLoading,
    positionsError,
    pendingPositions,
    setPendingPositions,
    library,
    SLTPCommission,
		triggerPositionsFetch,
	} = props;

	const { active, account } = useWeb3React();
	
	// Sections Labels
  const LIST_SECTIONS = ["Positions"];
  let [listSection, setListSection] = useLocalStorageByChainId(ARBITRUM, "List-section-v2", LIST_SECTIONS[0]);
  const LIST_SECTIONS_LABELS = {
    Positions: "Active",
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  // End Sections Labels

	return (
		<div className="BuilderListSection">
			<div className="Exchange-list-tab-container">
				<Tab
					options={LIST_SECTIONS}
					optionLabels={LIST_SECTIONS_LABELS}
					option={listSection}
					onChange={(section) => setListSection(section)}
					type="inline"
					className="Exchange-list-tabs"
				/>
				<div className="Exchange-list-tip">
					*The table displays estimated PnL values. To view the actual PnL, click on the «Close» button
				</div>
			</div>
			{listSection === "Positions" && (
				<BuilderPositionsList
					infoTokens={infoTokens}
					active={active}
					account={account}
					library={library}
					positions={positions}
					positionsLoading={positionsLoading}
					positionsError={positionsError}
					pendingPositions={pendingPositions}
					setPendingPositions={setPendingPositions}
					SLTPCommission={SLTPCommission}
					triggerPositionsFetch={triggerPositionsFetch}
				/>
			)}
		</div>
	);
};

export default BuilderListSection;