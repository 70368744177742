import { formatUnits, parseUnits } from "ethers/lib/utils";
import { ARBITRUM, expandDecimals, formatAmount } from "../../views/gmx-test/lib/legacy";
import { errAlert, notifySuccess } from "./notifications";
import { MAX_FRACTION_LENGTH, OPTIONS_DECIMALS } from "./constants";
import { getTokenInfo } from './../../views/gmx-test/lib/legacy';
import { getTokenBySymbol, getTokens } from "../../views/gmx-test/config/Tokens";
import { BigNumber } from "ethers";
import Spinner from "../UI/loader/Spinner";
import { lastDayOfMonth } from "date-fns";


export const getSlTpFromPercentage = (isLong, isTP, percentage, openPriceNum, leverage, fees) => {
	if (!percentage) {
		return 0;
	}
	
	if ((isTP && isLong) || (!isTP && !isLong)) {
		return (
			(
				openPriceNum + (0.01 * openPriceNum * (percentage / leverage))
			) * 10**10
		).toFixed(0);
	} else {
		return (
			(
				openPriceNum - (0.01 * openPriceNum * (percentage / leverage))
			) * 10**10
		).toFixed(0);
	}
}

export const daysToMs = (days) => {
	return days * 24 * 60**2 * 1000;
}

export const msToTimeLeft = (ms) => {
	const s = ms / 1000;
	const m = s / 60;
	const h = m / 60;
	const hours = Math.floor(h % 24);
	const days = Math.floor(h / 24);
	
	return `${days}d ${hours}h`;
}

export function fromBigNumber(number, decimals = OPTIONS_DECIMALS) {
	if (!number) {
		return;
	}
  return parseFloat(formatUnits(number.toString(), decimals));
}

export const numToBn = (num, decimals = OPTIONS_DECIMALS, maxFraction = MAX_FRACTION_LENGTH) => {
	const n = typeof num !== "number" ? Number(num) : num;
	return BigNumber.from(n * 10**maxFraction).mul(10**(decimals - maxFraction));
}

export const executePromise = (fn, args = [], successMsg, setIsLoading, cb) => {
	if (setIsLoading) {
		setIsLoading(true);
	}
	
	fn(...args)
		.then(tx => {
			console.log("Submitting:", tx);
			tx.wait().then((res) => {
				notifySuccess(successMsg, tx.hash);
				if (setIsLoading) {
					setIsLoading(false);
				}
				if (cb) {
					cb(res);
				}
			}, (e) => {
				errAlert(e, setIsLoading);
			})
		}, (e) => {
			errAlert(e, setIsLoading);
		});
}

export const getOptionProfitZone = (isCall, strikePrice, premium, size) => {
	const profitZoneCalcMethod = isCall ? "add" : "sub";
	const profitZone = strikePrice[profitZoneCalcMethod](expandDecimals(premium.div(size), OPTIONS_DECIMALS));
	return {
		profitZone,
		profitZoneStr: `${isCall ? ">" : "<"}$${formatAmount(profitZone, OPTIONS_DECIMALS, 2, true)}`
	};
}

export const getMarkPrice = (infoTokens, token) => {
	return getTokenInfo(infoTokens, getTokenBySymbol(ARBITRUM, token).address).maxPrice;
}

export const findLatestOrEarliest = (arr, datePropName, isLatest = true) => {
	const res = arr.reduce((prev, cur) => {
		const prevDateMs = (new Date(prev[datePropName])).getTime();
		const curDateMs = (new Date(cur[datePropName])).getTime();
		if (isLatest) {
			if (curDateMs > prevDateMs) {
				return cur;
			}
			return prev;
		} else {
			if (curDateMs < prevDateMs) {
				return cur;
			}
			return prev;
		}
	}, arr[0]);

	return res;
}

export const awaitLoading = (node, isLoading, loader = <Spinner />) => {
	return isLoading ? loader : node;
}

export const getPnlStats = (pnlObj) => {
	let delta = 0, deltaStr = '...', hasPositionProfit = false;
	const defaultPnl = {
		delta,
		deltaStr,
		hasPositionProfit,
	}
	if (Object.keys(pnlObj).length < 2) {
		return defaultPnl;
	}
	const { unrealizedPnl, unrealizedPnlPercentage } = pnlObj;
	delta = unrealizedPnl;
	
	deltaStr = <>
		<span style={{color: "#747FA6", marginRight: 5}}>
			{unrealizedPnl.lt(0) ? '–' : ''}${Math.abs(fromBigNumber(unrealizedPnl, OPTIONS_DECIMALS)).toFixed(2)}
		</span>
		{`(${unrealizedPnl.lt(0) ? '-' : ''}${formatAmount(unrealizedPnlPercentage.abs(), OPTIONS_DECIMALS - 2, 0, true)}%)`}
	</>
		
	hasPositionProfit = unrealizedPnl.gt(0);

	return {
		delta,
		deltaStr,
		hasPositionProfit,
	}
}

const toBigNumber = (number, decimals = 18) => {
  return parseUnits(number.toFixed(decimals).toString(), decimals)
}
export function getSoftMaxCollateral(trade, collateral, isPosition) {
	const UNIT = BigNumber.from(10).pow(18);
	const CASH_SECURED_CALL_MAX_COLLATERAL_BUFFER = 2.5;
	const strikePrice = isPosition ? trade.strikePrice : trade.strike().strikePrice;
  const cashSecuredMax = (trade.newSize ?? trade.size)
    .mul(strikePrice)
    .div(UNIT)
    .mul(toBigNumber(CASH_SECURED_CALL_MAX_COLLATERAL_BUFFER))
    .div(UNIT)

  const max = collateral.max
    ? collateral.max
    : // For cash-secured calls, set max to 1.25 * size * strike
    cashSecuredMax.gt(collateral.min)
    ? cashSecuredMax
    : collateral.min

  return max
}

export function getMonthFromString(mon){
	var d = Date.parse(mon + "1, 2012");
	if(!isNaN(d)){
		 return new Date(d).getMonth();
	}
	return -1;
}

export function getLastDateOfMonth(month, year) {
	if (typeof month === "string") {
		month = getMonthFromString(month);
	}
	const tempDate = new Date();
	tempDate.setFullYear(year);
	tempDate.setMonth(month);
	return lastDayOfMonth(tempDate).getDate();
}