import React, { useState, useEffect } from 'react';
import { formatForContract } from '../../utils/math';
import Modal from './Modal';
import { formatAmount, formatAmountFree, USD_DECIMALS } from './../../../views/gmx-test/lib/legacy';
import NumberInput_v3 from './../input/NumberInput_v3';
import { CALL, PUT, SL, TP } from './../../../views/options/lib/legacyOptions';
import { OPTIONS_DECIMALS } from '../../utils/constants';
import cx from "classnames";
import { executePromise, fromBigNumber, getSoftMaxCollateral, numToBn } from '../../utils/utils';
import Currency from './../../Currency';
import {
	getTPMethod,
	getTPContract,
	getIsUsdcApproved,
	approveUSDC,
} from '../../utils/contracts';
import Tooltip from '../../../views/gmx-test/components/Tooltip/Tooltip';
import icon_bin from '../../../img/icon-bin.svg';
import "./SLTP.scss";
import Spinner from '../loader/Spinner';
import useGodEye from '../../../hooks/useGodEye';
import { sepToNumber, separateThousands } from '../../utils/sepThousands';
import useDebounce from '../../../hooks/useDebounce';

const CollateralModal = (props) => {
	const {
		visible,
		setVisible,
		position,
		setPosition,
	} = props;

	const { account } = useGodEye();
	const [collateralValue, setCollateralValue] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isUsdcApproved, setIsUsdcApproved] = useState(null);
	const btnText = "Update Collateral";
	const isActive = position && visible;
	
	const spender = isActive && position.lyraPosition.marketAddress;
	const checkUsdcApproved = () => {
		setIsUsdcApproved(null);
		getIsUsdcApproved(account, spender, numToBn(sepToNumber(collateralValue), 6))
			.then(res => {
				setIsUsdcApproved(res);
			});
	};
	const checkUsdcApprovedDelayed = useDebounce(checkUsdcApproved, 800);
	useEffect(() => {
		if (isActive) {
			setIsUsdcApproved(null);
			checkUsdcApprovedDelayed();
		}
	}, [collateralValue]);

	const onApply = () => {
		setIsSubmitting(true);
		if (!isUsdcApproved) {
			approveUSDC(spender, setIsSubmitting, () => {
				checkUsdcApproved();
			});
		} else {
			executePromise(position.updateCollateral, [sepToNumber(collateralValue)], `Collateral updated!`, setIsSubmitting, () => {
				setVisible(false);
				position.setIsCollateralSubmitting(true);
			})
		}
	}

	const getError = () => {
		if (!position) {
			return btnText;
		}
		if (!collateralValue) {
			return `Enter the Amount`;
		}
		const collateral = numToBn(sepToNumber(collateralValue));
		if (collateral.lte(0)
			|| collateral.gt(position.collateral.max.sub(position.collateral.amount))) {
			return `Amount is out of bounds`;
		}
		if (isSubmitting) {
			if (!isUsdcApproved) {
				return `Approving...`;
			}
			return `Submitting...`;
		}
		if (isUsdcApproved === null) {
			return `Please, wait`
		}
	}
	const getBtnText = () => {
		const error = getError();
		if (error) {
			return error;
		}

		if (!isUsdcApproved) {
			return `Approve`;
		}

		return btnText;
	}
	const isBtnEnabled = () => {
		const hasError = Boolean(getError());
		if (hasError) {
			return false;
		}
		return true;
	}

	const resetModal = () => {
		setCollateralValue("")
		setPosition(null);
	};

	if (position && position.isBuy) {
		return;
	}

	return (
		<Modal
			className="CollateralModal"
			visible={visible}
			setVisible={setVisible}
			resetModal={resetModal}
		>
			<h1 className="modal__title">Add Collateral</h1>
				<div className="modal__body">
					<NumberInput_v3
						title='Amount'
						value={collateralValue}
						setValue={setCollateralValue}
						icon={
							position &&
							require(`../../../img/icon-${position.collateral.isBase ? `W${position.token}` : "USDC"}.svg`).default
						}
						placeholder={
							position &&
							`0 — ${separateThousands(formatAmountFree(position.collateral.max.sub(position.collateral.amount), OPTIONS_DECIMALS, 2))}`
						}
					/>
					<button 
						className="modal__btn btn btn_hlight"
						onClick={() => {
							setIsSubmitting(true);
							onApply();
						}}
						disabled={!isBtnEnabled()}
					>
						{getBtnText()}
					</button>
				</div>
		</Modal>
	);
};

export default CollateralModal;