import React, { useContext } from 'react';
import { GodEyeContext } from '../context/context';
import { useWeb3React } from '@web3-react/core';


export default function useGodEye() {
	const godEyeWallet = useContext(GodEyeContext);
	const { account: nativeAccount, active, library } = useWeb3React();
	const account = godEyeWallet ?? nativeAccount;

	return { account, active, library };
}