import React from 'react';
import './InputWithSelector.scss';

const InputWithSelector = (props) => {
	const {
		input,
		selector,
	} = props;

	return (
		<div className="InputWithSelector">
			{input}
			{selector}
		</div>
	);
};

export default InputWithSelector;