import { useEffect, useRef } from "react";
import { Exchange } from "../pages/Exchange/ExchangeOptions";
import "react-toastify/dist/ReactToastify.css";

import "../styles/Font.css";
import "./App.scss";
import "../styles/Input.scss";
import _AccountManager from "../../../deployments/arbitrum_ddl/accountManager.json";

function FullApp() {
  const exchangeRef = useRef();

  return (
    <>
      <div className="App GMX-Interface Options-Interface">
        <div className="App-content">
          <Exchange
            ref={exchangeRef}
          />
        </div>
      </div>
    </>
  );
}

function Options(props) {
  return (
    <FullApp {...props} />
  );
}

export default Options;
