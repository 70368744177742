import { useState } from "react";
import { BigNumber } from "ethers";

export default function useSort(items, filterArr, filterPropertyName) {
	const [sortType, setSortType] = useState('');
	const [sortedItems, setSortedItems] = useState([]);
	const filterFn = (itemsArr) => {
		if (!filterArr) {
			return itemsArr;
		}
		return itemsArr.filter(item => {
			for (let i = 0; i < filterArr.length; i++) {
				const filterProperty = filterArr[i];
				if (filterProperty === item[filterPropertyName]) {
					return true;
				}
			}
		})
	}

	const sortItems = (param, dir = 1, isInit = false) => {
		const res = filterFn(items);
		let isBigNumbers;
		if (items.length) {
			isBigNumbers = BigNumber.isBigNumber(items[0][param]);
		}
		
		const sortFn = (arr) => {
			arr.sort((a, b) => {
				const prevEl = a[param];
				const el = b[param];
				
				if (prevEl === null) {
					return dir;
				} else if (el === null) {
					return -dir;
				} else if (prevEl && el) {
					return 0;
				}
			})
			arr.sort((a, b) => {
				const prevEl = a[param];
				const el = b[param];
	
				if (!prevEl || !el) {
					return 0;
				}
				
				if (isBigNumbers ? prevEl.lt(el) : prevEl < el) {
					return dir;
				}	else if (isBigNumbers ? prevEl.eq(el) : prevEl === el) {
					return 0;
				} else {
					return -dir;
				}
			})
		}
		
		const [prevParam, prevDir] = sortType.split('__');
		if (!isInit) {
			if (param === prevParam) {
				dir = -prevDir;
			}
		}
		sortFn(res);
		setSortedItems(res.slice());
		setSortType(`${param}__${dir}`);
	}

	return [sortItems, sortedItems, setSortedItems, sortType];
}