import React, { useEffect } from 'react';
import Logo from './components/UI/logo/Logo'
import Button from './components/UI/button/Button';
import Wallet from './components/Wallet';
import { useLocation } from 'react-router-dom';
import Tabs from './components/Tabs';
import './Header.scss';
import useGodEye from './hooks/useGodEye';



const Header = (props) => {
	const {
		connectWallet,
	} = props;

	const { active, account } = useGodEye();

	const loc = useLocation();
	const headerLinks = [
		{
			name: "One-Click Strategies",
			to: '/terminal'
		},
	]
	const activeLink = headerLinks.find(link => {
		return loc.pathname.startsWith(link.to);
	})
	if (activeLink) {
		activeLink.isActive = true;
	}

	return (
		<header className='header'>
			<div className="header__content _container">
				<div className="header__nav">
					<Logo />
					<Tabs 
						className='header__links' 
						links={headerLinks} 
					/>
				</div>
				{active ?
					<Wallet
						address={account}
					/>
					:
					<Button isMain={true} onClick={(e) => {
						connectWallet()
					}}>Connect wallet</Button>}
			</div>
		</header>
	);
};

export default Header;