// Common
export const HOMEPAGE_HREF = "/terminal/builder";
export const DEFAULT_PAGE_TITLE = "DeDeLend: Leading DeFi Platform for Streamlined Options & Perpetuals Trading and Effortless Asset Management";
export const DDL_WALLET = "0x683Ad8b899Cd14d8e077c9A623E8B3fEd65a8c09";
export const DDL_WALLET_ENCODED = '0'.repeat(64 - DDL_WALLET.length + 2) + DDL_WALLET.split('0x')[1];

export const MAX_FRACTION_LENGTH = 6;
export const DATE_FORMAT_TEMPLATE = "dd/MM/yy, hh:mm a";

export const BORDER_COEF = 0.01;
export const APY = '10.00%';
export const ALL_MARKETS = [
  "GMX",
  "GNS",
  "Hegic",
  "Lyra",
]

// Perpetuals constants
export const marketsList = [
  {
    name: 'GMX',
  },
  {
    name: 'GNS',
  },
];

export const GNS_FEES_MULTIPLIER = 0.0008;


// Options constants
export const OPTIONS_DECIMALS = 18;
export const OPTION_LIST_FRONTIER = 10;
export const marketsOptions = ['Hegic', 'Lyra', 'Premia'];
export const assetsOptions = ['ETH', 'BTC'];
const assetsIconsDraft = {};
assetsOptions.forEach(asset => {
  try {
    const icon = require(`../../img/icon-${asset}.svg`).default;
    assetsIconsDraft[asset] = icon;
  } catch (e) {
    console.log(e);
  }
});
export const assetsIcons = {...assetsIconsDraft};

export const PremiaStrikeRangeMultiplier = {
  Call: [0.8, 2],
  Put: [0.5, 1.2],
}

export const tokenDecimals = {
  ETH: 18,
  BTC: 8
}

export const OPTION_TYPES = {
  Hegic: "American",
  Lyra: "European",
  Premia: "American",
}

export const GOD_EYE_CHEATCODE = "AEZAKMI=";


// Style constants
export const GREEN = "#46956A";
export const RED = "#C75E4B";
export const CORNFLOWER = "#6988FF";
export const LGREY = "#747FA6";
export const GREY = "#3A4466";