import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/UI/modal/Modal';
import Currency from '../../../../components/Currency';
import Tooltip from '../../../gmx-test/components/Tooltip/Tooltip';
import './BuyBuildModal.scss';
import { builderProtocols, builderStrategies } from '../builderConstants';
import { getOptionComponentLabel } from '../builderConstants';
import { DATE_FORMAT_TEMPLATE, OPTIONS_DECIMALS, tokenDecimals } from '../../../../components/utils/constants';
import { awaitLoading, executePromise, fromBigNumber } from '../../../../components/utils/utils';
import icon_calendar from '../../../../img/icon-calendar.svg';
import { format } from 'date-fns';
import cx from "classnames";
import { USDC, approveUSDC, builderOpen, builderOpenContract, getIsTokenApproved, getIsUsdcApproved, WETH, WBTC, tokenContracts, approveToken } from './../../../../components/utils/contracts';
import { expandDecimals, formatAmount, formatAmountFree } from '../../../gmx-test/lib/legacy';
import { BigNumber } from 'ethers';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { useWeb3React } from '@web3-react/core';

const BuyBuildModal = (props) => {
	const {
		visible,
		setVisible,
		fields,
		strategy,
		infoTokens,
		amount,
		asset,
		maxCost,
		triggerPositionsFetch,
		received,
		hasSell,
		hasBuy,
		collateralSums,
	} = props;

	const { active, account } = useWeb3React();

	const baseName = `W${asset}`;
	const baseDecimals = tokenDecimals[asset];
	const isCollateralFilled = fields.every(field => {
		if (field.isBuy) {
			return true;
		}
		return field.collateral.info[0] && !field.collateral.info[0].amount.eq(0);
	});
	const hasBaseCollateral = !!fields.find(field => {
		if (!field.isBuy) {
			if (field.collateral.info[0] && field.collateral.info[0].isBase) {
				return true;
			}
		}
	});
	const [isUsdcApproved, setIsUsdcApproved] = useState(null);
	const checkUsdcApproved = () => {
		setIsUsdcApproved(null);
		let usdcAmount = maxCost.div(10**(OPTIONS_DECIMALS-6));
		if (hasSell && isCollateralFilled) {
			fields.forEach(field => {
				if (
					!field.isBuy
					&& field.collateral.info[0]
					&& !field.collateral.info[0].isBase
				) {
					usdcAmount = usdcAmount.add(field.collateral.info[0].amount.div(10**(OPTIONS_DECIMALS-6)))
				}
			})
			getIsUsdcApproved(account, builderOpen, usdcAmount)
				.then(setIsUsdcApproved)
		} else {
			getIsUsdcApproved(account, builderOpen, usdcAmount)
				.then(setIsUsdcApproved)
		}
	}
	const [isBaseApproved, setIsBaseApproved] = useState(null);
	const checkBaseApproved = () => {
		setIsBaseApproved(null);
		if (isCollateralFilled) {
			const baseContract = tokenContracts[baseName];
			const baseAmount = fields.reduce((sum, field) => {
				if (
					!field.isBuy
					&& field.collateral.info[0]
					&& field.collateral.info[0].isBase
				) {
					return sum.add(field.collateral.info[0].amount.div(10**(OPTIONS_DECIMALS - baseDecimals)));
				}
				return sum;
			}, BigNumber.from(0));
			getIsTokenApproved(baseContract, account, builderOpen, baseAmount)
				.then(setIsBaseApproved);
		}
	}
	useEffect(() => {
		if (visible) {
			if (active && maxCost) {
				checkUsdcApproved();
			}
			if (active && hasBaseCollateral) {
				checkBaseApproved();
			}
		}
	}, [visible, isCollateralFilled]);

	const usdcBalance = infoTokens[USDC.address].balance && expandDecimals(infoTokens[USDC.address].balance, OPTIONS_DECIMALS - 6);
	const baseBalance = infoTokens[tokenContracts[baseName].address].balance && expandDecimals(infoTokens[tokenContracts[baseName].address].balance, OPTIONS_DECIMALS - baseDecimals);
	const [premiums, setPremiums] = useState([]);
	const [isMinorLoading, setIsMinorLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (visible) {
			setIsMinorLoading(true);
			fields.forEach(field => {
				const market = field.market[0];
				const strike = field.strike[0];
				premiums.push(field.strikesData[0][market][strike].calcPremium(amount))
			})
			Promise.all(premiums)
				.then(res => {
					setPremiums([...res])
					setIsMinorLoading(false);
				})
		}
	}, [visible]);

	const getError = () => {
		if (usdcBalance && maxCost && usdcBalance.lt(maxCost)) {
			return `Max. Cost exceeds balance`;
		}
		if (isUsdcApproved === null || (hasBaseCollateral && isBaseApproved === null)) {
			return `Please, wait`;
		}
	}
	const getBtnText = () => {
		const err = getError();
		if (err) {
			return err;
		}

		if (isLoading) {
			if (!isUsdcApproved || (hasBaseCollateral && !isBaseApproved)) {
				return `Approving...`;
			} else {
				return `Submitting...`;
			}
		}

		if (!isUsdcApproved) {
			return `Approve USDC`;
		}
		if (hasBaseCollateral && !isBaseApproved) {
			return `Approve W${asset}`;
		}

		return `Buy Strategy`;
	}
	const isBtnEnabled = () => {
		return !getError() && !isLoading;
	}
	const onPrimaryClick = async () => {
		if (!isUsdcApproved) {
			approveUSDC(builderOpen, setIsLoading, checkUsdcApproved);
			return;
		}
		if (hasBaseCollateral && !isBaseApproved) {
			approveToken(tokenContracts[baseName], baseName, builderOpen, setIsLoading, checkBaseApproved);
			return;
		}

		setIsLoading(true);
		const paramsArr = [];
		const protocols = [];
		let promises = [];
		fields.forEach(field => {
			const market = field.market[0];
			const strike = field.strike[0];
			const data = field.strikesData[0][market][strike];
			const options = field.isBuy ? {} : {
				setToCollateral: field.collateral.info[0].amount,
				isBaseCollateral: field.collateral.info[0].isBase,
			}

			promises.push(data.getParams(amount, options).then(params => {
				paramsArr.push(params);
				protocols.push(builderProtocols[`${field.market[0]}_${asset}`]);
			}))
		})
		await Promise.all(promises);
		const productKey = builderStrategies[strategy].key;
		executePromise(builderOpenContract.consolidationOfTransactions, [protocols, paramsArr, productKey], `${amount} ${builderStrategies[strategy].inputLabel ?? `${strategy}${amount > 1 ? "s" : ""}`} opened!`, setIsLoading, () => {
			setVisible(false);
			triggerPositionsFetch();
		});
	}

	const resetModal = () => {
		setPremiums([]);
	}
	return (
		<Modal
			className={"BuyBuildModal"}
			visible={visible}
			setVisible={setVisible}
			resetModal={resetModal}
		>
			<div className="BuyBuildModal__body">
				<div className="modal__steps steps" style={{gridTemplateColumns: hasBaseCollateral ? "1fr 1fr 1fr" : "1fr 1fr"}}>
					<div className={cx("step", !isUsdcApproved && "current")}>Approve USDC</div>
					{hasBaseCollateral && (
						<div className={cx("step", isUsdcApproved && !isBaseApproved && "current")}>Approve {baseName}</div>
					)}
					<div className={cx("step", isUsdcApproved && (isBaseApproved || !hasBaseCollateral) && "current")}>Buy</div>
				</div>
				<div className="BuyBuildModal__header">
					<h1 className="BuyBuildModal__title modal__title">Buy Strategy</h1>
					<div className="BuyBuildModal__strategy" style={{color: builderStrategies[strategy].color}}>
						{strategy}
					</div>
				</div>
				<table className='BuyBuildModal__table' cellPadding={0} border={1}>
					<tbody>
						<tr>
							<th style={{width: hasSell ? "28%" : "35%"}}>
								Strike
							</th>
							<th style={{width: hasSell ? "12%" : "25%"}}>
								Amount
							</th>
							{hasSell && (
								<th style={{width: "15%"}}>
									Liq. Price
								</th>
							)}
							<th style={{width: hasSell ? "20%" : "25%", whiteSpace: "nowrap"}}>
								{`${hasBuy ? `Max.${!hasSell ? " " : ""}Cost` : ""}${hasBuy && hasSell ? " / " : ""}${hasSell ? "Min.Received" : ""}`}
							</th>
							{hasSell && (
								<th style={{width: "15%"}}>
									Collateral
								</th>
							)}
							<th style={{width: hasSell ? "5%" : "10%"}}>
								{/* date */}
							</th>
						</tr>
						{fields.map((field, i) => {
							if (!visible) {
								return (
									<tr key={i} style={{height: 22}}>
										<td>...</td>
									</tr>
								);
							}
							const strike = field.strike[0];
							const struct = builderStrategies[strategy].structure[i];
							const structLabel = getOptionComponentLabel(struct);
							const market = field.market[0];
							let marketIcon;
							try {
								marketIcon = require(`../../../../img/icon-${market}_circled.svg`).default;
							} catch (e) {
								console.log(e);
							}

							return (
								<tr key={i}>
									<td>
										<div className="BuyBuildModal__table-strike">
											<img src={marketIcon} alt={`${market} icon`} />
											<div className={cx("BuyBuildModal__table-strike-label", {
												positive: struct > 0,
												negative: struct < 0,
											})}>
												{structLabel}
											</div>
											<span>
												${formatAmount(strike, 0, 0, true)}
											</span>
										</div>
									</td>
									<td>
										<Currency currencyName={asset}>
											{amount}
										</Currency>
									</td>
									{hasSell && (
										<td>
											{/* Liq.Price */}
											{field.isBuy ? "—"
												: (field.collateral.info[0].liquidationPrice ?
													`$${formatAmount(field.collateral.info[0].liquidationPrice, OPTIONS_DECIMALS, 0, true)}`
													: "—"
												)}
										</td>
									)}
									<td>
										{awaitLoading((
											<Currency>
												{premiums.length && !isMinorLoading && formatAmount(premiums[i], OPTIONS_DECIMALS, 2, true)}	
											</Currency>
										), isMinorLoading)}
									</td>
									{hasSell && (
										<td>
											{/* Collateral */}
											{field.isBuy ? "—"
												: <Currency currencyName={field.collateral.info[0].isBase ? baseName : "USDC"}>
														{field.collateral.info[0].isBase
															? formatAmountFree(field.collateral.info[0].amount, OPTIONS_DECIMALS, 3)
															: formatAmount(field.collateral.info[0].amount, OPTIONS_DECIMALS, 0, true)
														}
													</Currency>
												}
										</td>
									)}
									<td style={{textAlign: "right"}}>
									<Tooltip
										className="Tooltip_date-icon"
										handle={
											<img src={icon_calendar} alt={"Calendar icon"} />
										}
										position="left-bottom"
										enabled={true}
										renderContent={() => {
											return (
												<div className="Tooltip__text">
													Expiration Date
													<div style={{color: "#fff"}}>
														{format(field.dateMs[0], DATE_FORMAT_TEMPLATE)}
													</div>
												</div>
											);
										}}
									/>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className="BuyBuildModal__mobile">
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Strategy
						</div>
						<div className="align-right" style={{color: builderStrategies[strategy].color}}>
							{strategy}
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Amount
						</div>
						<div className="align-right">
							{amount}
						</div>
					</div>
					<div className="divider" />
					<Accordion
						className='Accordion_strategy'
						transition
						transitionTimeout={250}
					>
						<AccordionItem
							header={({state}) => `${state.isEnter ? "Hide" : "Show"} full strategy`}
							initialEntered
						>
							{fields.map((field, i) => {
								if (!visible) {
									return;
								}
								const strike = field.strike[0];
								const struct = builderStrategies[strategy].structure[i];
								const structLabel = getOptionComponentLabel(struct);
								const market = field.market[0];
								let marketIcon;
								try {
									marketIcon = require(`../../../../img/icon-${market}_circled.svg`).default;
								} catch (e) {
									console.log(e);
								}

								return (
									<div className="BuyBuildModal__field-info" key={i}>
										<div className="Exchange-info-row">
											<div className="Exchange-info-label">
												Strike
											</div>
											<div className="align-right BuyBuildModal__table-strike">
												<img src={marketIcon} alt={`${market} icon`} />
												<div className={cx("BuyBuildModal__table-strike-label", {
													positive: struct > 0,
													negative: struct < 0,
												})}>
													{structLabel}
												</div>
												<span>
													${formatAmount(strike, 0, 0, true)}
												</span>
											</div>
										</div>
										<div className="Exchange-info-row">
											<div className="Exchange-info-label">
												Amount
											</div>
											<div className="align-right">
												<Currency currencyName={asset}>
													{amount}
												</Currency>
											</div>
										</div>
										{/* Liq.Price */}
										{!field.isBuy && (
											<div className="Exchange-info-row">
												<div className="Exchange-info-label">
													Liq. Price
												</div>
												<div className="align-right">
													{field.collateral.info[0].liquidationPrice ?
														`$${formatAmount(field.collateral.info[0].liquidationPrice, OPTIONS_DECIMALS, 0, true)}`
														: "—"
													}
												</div>
											</div>
										)}
										<div className="Exchange-info-row">
											<div className="Exchange-info-label">
												{field.isBuy ? "Max. Cost" : "Min. Received"}
											</div>
											<div className="align-right">
												{awaitLoading((
													<Currency>
														{premiums.length && !isMinorLoading && formatAmount(premiums[i], OPTIONS_DECIMALS, 2, true)}	
													</Currency>
												), isMinorLoading)}
											</div>
										</div>
										{!field.isBuy && (
											<div className="Exchange-info-row">
												<div className="Exchange-info-label">
													Collateral
												</div>
												<div className="align-right">
													<Currency currencyName={field.collateral.info[0].isBase ? baseName : "USDC"}>
														{field.collateral.info[0].isBase
															? formatAmountFree(field.collateral.info[0].amount, OPTIONS_DECIMALS, 3)
															: formatAmount(field.collateral.info[0].amount, OPTIONS_DECIMALS, 0, true)
														}
													</Currency>
												</div>
											</div>
										)}
										<div className="Exchange-info-row">
											<div className="Exchange-info-label">
												Expiration Date
											</div>
											<div className="align-right">
												{format(field.dateMs[0], DATE_FORMAT_TEMPLATE)}
											</div>
										</div>
										<div className="divider" />
									</div>
								)
							})}
						</AccordionItem>
					</Accordion>
				</div>
			</div>
			
			<div className="BuyBuildModal__footer">
				<div className="BuyBuildModal__money">
					<div className="BuyBuildModal__money-item">
						<div className="BuyBuildModal__money-item-title" style={{alignSelf: "flex-start"}}>
							Balance
						</div>
						<div className="BuyBuildModal__money-item-val">
							{hasBaseCollateral && (
								<Currency isHlight={true} currencyName={baseName}>
									{formatAmountFree(baseBalance, OPTIONS_DECIMALS, 3)}
								</Currency>
							)}
							<Currency isHlight={true}>
								{formatAmount(usdcBalance, OPTIONS_DECIMALS, 2, true)}
							</Currency>
						</div>
					</div>
					<div className="BuyBuildModal__money-group">
						{hasSell && (
							<div className="BuyBuildModal__money-item">
								<div className="BuyBuildModal__money-item-title">
									<Tooltip
										className="has-hint-tooltip nowrap"
										handle="Min. Received"
										position="left-bottom"
										enabled={true}
										renderContent={() => {
											return (
												<div className="Tooltip__text">
													This is the least amount you’re <br/>assured to receive when <br/>selling options. If the option <br/>price drops beyond this point, <br/>your transaction will <br/>be reverted.
												</div>
											);
										}}
									/>
								</div>
								<div className="BuyBuildModal__money-item-val">
									<Currency isSmall={true} isHlight={!!received}>
										{received ? formatAmount(received, OPTIONS_DECIMALS, 2, true) : "—"}
									</Currency>
								</div>
							</div>
						)}
						{hasBuy && (
							<div className="BuyBuildModal__money-item">
								<div className="BuyBuildModal__money-item-title">
									<Tooltip
										className="has-hint-tooltip nowrap"
										handle="Max. Cost"
										position="left-bottom"
										enabled={true}
										renderContent={() => {
											return (
												<div className="Tooltip__text">
													This is the highest amount <br/>you’ll pay for buying options. <br/>If the option price rises <br/>beyond this, your transaction <br/>will be reverted.
												</div>
											);
										}}
									/>
								</div>
								<div className="BuyBuildModal__money-item-val">
									<Currency isHlight={true}>
										{formatAmount(maxCost, OPTIONS_DECIMALS, 2, true)}
									</Currency>
								</div>
							</div>
						)}
						{hasSell && (
							<div className="BuyBuildModal__money-item">
								<div className="BuyBuildModal__money-item-title">
									Collateral
								</div>
								<div className="BuyBuildModal__money-item-val" style={{alignItems: "flex-end"}}>
									{collateralSums.map((sum, i) => {
										const isBase = i === 0;
										if (sum.eq(0)) {
											if (isBase) {
												return;
											}
											if (!collateralSums[0].eq(0)) {
												return;
											}
										}
										return (
											<Currency isHlight={true} currencyName={isBase ? baseName : "USDC"}>
												{isBase
													? formatAmountFree(sum, OPTIONS_DECIMALS, 3)
													: formatAmount(sum, OPTIONS_DECIMALS, 0, true)
												}
											</Currency>
										)
									})}
								</div>
							</div>
						)}
					</div>
				</div>
				<button 
					className="modal__btn btn btn_hlight"
					onClick={onPrimaryClick}
					disabled={!isBtnEnabled()}
				>
					{getBtnText()}
				</button>
			</div>
		</Modal>
	);
};

export default BuyBuildModal;