import { useState, useEffect } from 'react';
import Modal from './../../../../components/UI/modal/Modal';
import { sepToNumber } from '../../../../components/utils/sepThousands';
import { BigNumber } from 'ethers';
import NumberInput_v3 from './../../../../components/UI/input/NumberInput_v3';
import { expandDecimals, formatAmount } from './../../../gmx-test/lib/legacy';
import { DATE_FORMAT_TEMPLATE, OPTIONS_DECIMALS } from '../../../../components/utils/constants';
import { executePromise, fromBigNumber, msToTimeLeft } from './../../../../components/utils/utils';
import { format } from 'date-fns';
import cx from 'classnames';
import './PositionModal.scss';
import { formatAmountFree } from './../../lib/legacyOptions';
import Tooltip from './../../../gmx-test/components/Tooltip/Tooltip';
import Currency from './../../../../components/Currency';

const ClosePositionModal = (props) => {
	const {
		visible,
		setVisible,
		position,
		setPosition,
		pendingPositions,
		setPendingPositions,
	} = props;

	const isHegic = position?.market === "Hegic";
	const isLyra = position?.market === "Lyra";
	const [sizeValue, setSizeValue] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [, setTrade] = useState(null);

	const size = expandDecimals(BigNumber.from((sepToNumber(sizeValue) * 10**6).toFixed(0)), 12);

	useEffect(() => {
		if (position && isLyra) {
			if (!position.trade) {
				position.getTrade(!position.isBuy)
					.then(trade => {
						// Lyra SDK returns ZERO_BN if trade.isOpen === true
						trade.isOpen = false;
						// Updates interface
						setTrade(trade);

						position.trade = trade;
					})
			}
		}
	}, [visible, position]);
	
	
	let pnl,
	pnlFormatted,
	pnlSign,
	pnlStr,
	pnlPercentage,
	pnlPercentageStr;
	if (position && getError() !== "Amount exceeds option's size") {
		if (isLyra && position.trade) {
			pnl = position.trade.pnl();
			if (sizeValue) {
				pnl = size.div(position.size.div(10**6)).mul(pnl).div(10**6);
			}
			pnlFormatted = formatAmount(pnl.abs(), OPTIONS_DECIMALS, 2, true)
			pnlSign = pnl.gt(0) ? "+" : (pnl.lt(0) ? "–" : "");
			pnlStr = `${pnlSign}$${pnlFormatted}`;
			pnlPercentage = Math.abs(fromBigNumber(pnl) / fromBigNumber(position.premium) * 100);
			pnlPercentageStr = `(${pnlSign}${pnlPercentage.toFixed(2)}%)`;
		}

		if (isHegic) {
			const { unrealizedPnl, unrealizedPnlPercentage } = position.pnl;
			pnl = unrealizedPnl;
			pnlPercentage = unrealizedPnlPercentage;
	
			pnlStr = `
				${pnl.lt(0) ? '–' : ''}$${Math.abs(fromBigNumber(pnl, OPTIONS_DECIMALS)).toFixed(2)}
			`;
			pnlPercentageStr = `(${formatAmount(pnlPercentage, OPTIONS_DECIMALS - 2, 2, true)}%)`;
		}
	}

	const onCloseClick = () => {
		executePromise(position.close, [size], "Position closed!", setIsSubmitting,
		() => {
			setVisible(false);
			setPendingPositions([...pendingPositions, position.strategy]);
		});
	}

	function getError() {
		if (!position) {
			return "ERR! No position";
		}

		if (isLyra) {
			if (!position.trade) {
				return "Please, wait"
			}
		}
		
		if (!sizeValue && !isHegic) {
			return "Enter amount";
		}

		if (sepToNumber(sizeValue) > fromBigNumber(position.size)) {
			return "Amount exceeds option's size";
		}

		if (isSubmitting) {
			return "Closing...";
		}
	}
	const getBtnText = () => {
		const error = getError();
		if (error) {
			return error;
		}
		return "Close";
	}
	const isBtnEnabled = () => {
		const hasError = Boolean(getError());
		if (hasError) {
			return false;
		}
		return true;
	}

	const resetModal = () => {
		// animation delay
		setTimeout(() => {
			setSizeValue('');
			setPosition(null);
		}, 300)
	}
	
	const marketImg = position
		? require(`../../../../img/icon-${position.market}.svg`).default
		: "";

	return (
		<Modal
			className={"CloseOptionModal PositionModal"}
			visible={visible}
			setVisible={setVisible}
			resetModal={resetModal}
		>
			<h1 className="modal__title">Close Option</h1>
			{position &&
				<div className="modal__body">
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Market
						</div>
						<div className="align-right PositionModal__market-logo">
							<img src={marketImg} alt={`${position.market} logo`} />
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Type
						</div>
						<div className="align-right">
							{position.type}
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Strategy
						</div>
						<div className="align-right">
							<span
								className={cx({ positive: position.isCall, negative: !position.isCall })}
							>
								{position.sentiment}
							</span>
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Strike
						</div>
						<div className="align-right">
							${formatAmount(position.strikePrice, OPTIONS_DECIMALS, 2, true)}
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Expires In
						</div>
						<div className="align-right">
							{msToTimeLeft(position.expiry - Date.now())}
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Expiration Date
						</div>
						<div className="align-right">
							{format(position.expiry, DATE_FORMAT_TEMPLATE)}
						</div>
					</div>
					<div className="Exchange-info-row">
						<div className="Exchange-info-label">
							Amount
						</div>
						<div className="align-right">
							<Currency currencyName={position.token}>
								{formatAmountFree(position.size, OPTIONS_DECIMALS, 3)}
							</Currency>
						</div>
					</div>
					<Tooltip
						className="Tooltip_input"
						handle={
							<NumberInput_v3
								title='Amount'
								disabled={isHegic}
								value={isHegic ? fromBigNumber(position.size) : sizeValue}
								setValue={setSizeValue}
								maxValue={fromBigNumber(position.size)}
								icon={require(`../../../../img/icon-${position.token}.svg`).default}
								iconAlt={`${position.token} icon`}
							/>
						}
						position="left-bottom"
						enabled={isHegic}
						renderContent={() => {
							return (
								<div className="Tooltip__text">
									Hegic doesn't support partial closing
								</div>
							);
						}}
					/>
					
					<div className="Exchange-info-row hlight">
						<div className="Exchange-info-label">
							Profit/Loss
						</div>
						<div className="align-right">
							<span>
								{pnlStr ?? "..."}
							</span>
							{
								pnlPercentage &&
								<span
									className={cx(pnl && { positive: pnl.gt(0), negative: pnl.lt(0) })}
									style={{marginLeft: 4}}
								>
									{pnlPercentageStr}
								</span>
							}
							
						</div>
					</div>
					<div className="Exchange-info-row hlight">
						<div className="Exchange-info-label">
							Premium
						</div>
						<div className="align-right">
							{position.isBuy ? "–" : "+"}${formatAmount(position.premium.abs(), OPTIONS_DECIMALS, 2, true)}
						</div>
					</div>
				</div>}
			
			<button 
				className="modal__btn btn btn_hlight"
				onClick={onCloseClick}
				disabled={!isBtnEnabled()}
			>
				{getBtnText()}
			</button>
		</Modal>
	);
};

export default ClosePositionModal;