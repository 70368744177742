import { useState, useEffect } from "react";
import { builderStrategies, getDefaultStrikes } from "../views/Terminal/Builder/builderConstants";
import { format } from "date-fns";

export const useBuilderFields = (strategy, effectFn, baseToken) => {
	const structure = builderStrategies[strategy].structure;
	const { defaultStrikes, defaultStrikesData } = getDefaultStrikes();

	const isDateUnified = builderStrategies[strategy].isDateUnified;
	const unifiedDate = useState(null);
	const unifiedDateObj = useState({
		date: (new Date()).getDate(),
		month: format(new Date(), "MMMM"),
		year: (new Date()).getFullYear(),

		setDate (dateStr, max) {
			const date = dateStr ? Number(dateStr) : "";
			if (date !== "" && (date > max || date < 1)) {
				return;
			}
			this.date = date;
		},
		setMonth (month, maxDate) {
			this.month = month;
			if (this.date > maxDate) {
				this.setDate(maxDate, maxDate);
			}
		},
		setFullYear (year) {
			this.year = year;
		}
	})
	const separateDates = [
		useState(null),
		useState(null),
		useState(null),
		useState(null),
	];

	const fieldsDraft = [
		{
			dateMs: isDateUnified ? unifiedDate : separateDates[0],
			dateObj: unifiedDateObj,
			strike: useState(null),
			collateral: {
				value: useState(""),
				token: useState("USDC"),
				info: useState(null),
			},
			market: useState(null),
			strikes: useState({...defaultStrikes}),
			strikesData: useState({...defaultStrikesData}),
			loader: useState(false),
			id: 0,
		},
		{
			dateMs: isDateUnified ? unifiedDate : separateDates[1],
			dateObj: unifiedDateObj,
			strike: useState(null),
			collateral: {
				value: useState(""),
				token: useState("USDC"),
				info: useState(null),
			},
			market: useState(null),
			strikes: useState({...defaultStrikes}),
			strikesData: useState({...defaultStrikesData}),
			loader: useState(false),
			id: 1,
		},
		{
			dateMs: isDateUnified ? unifiedDate : separateDates[2],
			dateObj: unifiedDateObj,
			strike: useState(null),
			collateral: {
				value: useState(""),
				token: useState("USDC"),
				info: useState(null),
			},
			market: useState(null),
			strikes: useState({...defaultStrikes}),
			strikesData: useState({...defaultStrikesData}),
			loader: useState(false),
			id: 2,
		},
		{
			dateMs: isDateUnified ? unifiedDate : separateDates[3],
			dateObj: unifiedDateObj,
			strike: useState(null),
			collateral: {
				value: useState(""),
				token: useState("USDC"),
				info: useState(null),
			},
			market: useState(null),
			strikes: useState({...defaultStrikes}),
			strikesData: useState({...defaultStrikesData}),
			loader: useState(false),
			id: 3,
		},
	];
	const fields = fieldsDraft.slice(0, structure.length);
	const strategyType = strategy.split(' ')[0];

	fields.forEach((field, i) => {
		field.clearData = () => {
			const [, setStrikes] = field.strikes;
			const [, setStrikesData] = field.strikesData;
			const [, setCollateralValue] = field.collateral.value;
			const [, setCollateralToken] = field.collateral.token;
			const [, setCollateralInfo] = field.collateral.info;
			setStrikes({...defaultStrikes});
			setStrikesData({...defaultStrikesData});
			setCollateralValue("");
			if (strategyType === "Covered" && !field.isBuy && field.isCall) {
				setCollateralToken(baseToken);
			} else {
				setCollateralToken("USDC");
			}
			setCollateralInfo(null);
		}
		field.isCall = Math.abs(structure[i]) === 1;
		field.isBuy = structure[i] > 0;
	});
	
	const effFactory = (field) => {
		return [
			() => {
				if (!field) {
					return;
				}
				field.strike[1](null);
				if (effectFn && field.dateMs[0]) {
					field.loader[1](true);
					field.clearData();
					const { strikesPromises, data } = effectFn(field, field.isCall);
					Promise.all(strikesPromises).then(() => {
						field.strikes[1]({...data.strikes});
						field.strikesData[1]({...data.strikesData});
						field.loader[1](false);
					});
				}
			}, [field?.dateMs[0]]
		]
	}
	useEffect(...effFactory(fields[0]));
	useEffect(...effFactory(fields[1]));
	useEffect(...effFactory(fields[2]));
	useEffect(...effFactory(fields[3]));

	return fields;
}