import React from 'react';
import { Outlet } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import { useLocation } from 'react-router-dom';
import './Terminal.scss';

const Terminal = (props) => {
	const {
		
	} = props;

	const loc = useLocation();
	const links = [
		/* {
			name: "Perpetuals",
			to: '/terminal/perpetuals'
		}, */
		{
			name: "Position Builder",
			to: "/terminal/builder"
		},
		{
			name: "My Positions",
			to: '/terminal/positions',
		},
		/* {
			name: "User Profile",
			to: "/terminal/profile"
		}, */
		/* {
			name: "Market Overview",
			to: "/terminal/market-overview"
		} */
	]
	const activeLink = links.find(link => {
		return loc.pathname.endsWith(link.to);
	})
	if (activeLink) {
		activeLink.isActive = true;
	}

	return (
		<div className="Terminal GMX-Interface">
			<Tabs
				links={links}
				pathname={loc.pathname}
			/>
			<Outlet />
		</div>
	);
};

export default Terminal;