import React from 'react';
import { formatAmount, formatAmountFree } from './utils/legacy';
import cx from 'classnames';
import { OPTIONS_DECIMALS } from './utils/constants';
import './Strike.scss';

const Strike = (props) => {
	const {
		position,
	} = props;

	const marketImg = require(`../img/icon-${position.market}_circled.svg`).default;
	return (
		<div className="Strike">
			<img /* className='market-logo' */ src={marketImg} alt={`${position.market} icon`} />
			<div className={cx("Strike__sentiment", {
				positive: position.isBuy,
				negative: !position.isBuy,
			})}>
				{`${position.isBuy ? "Buy" : "Sell"} ${position.isCall ? "Call" : "Put"}`}
			</div>
			${formatAmount(position.strikePrice, OPTIONS_DECIMALS, 2, true, undefined, true)}
		</div>
	);
};

export default Strike;