export const separateThousands = (num, symb = ",") => {
	if (typeof num === 'undefined' || num === null) {
		return '';
	}
	
	const str = num.toString();
	let [intPart, fractionPart] = str.split('.');
	const hasFraction = fractionPart !== undefined;

	intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, (hasFraction && symb !== '') ? ',' : symb)
	
	if (hasFraction) {
		return intPart + '.' + fractionPart
	} else {
		return intPart;
	}
}


const removeNonNumeric = (num) => {
	let result = num.toString().replace(/^\./g, '').replace(/[^0-9.]/g, '').replace(/^0\d/, '');

	if (result.match(/\./g)?.length > 1) {
		result = result.replace(/\.$/, '')
	}

	let [, fractionPart] = result.split('.')

	if (fractionPart?.length > 6) {
		result = result.substr(0, result.length - 1)
	}
	
	return result;
}

export const convertInputNum = (num, symb) => {
	return separateThousands(removeNonNumeric(num), symb);
}

export const nonFractionConvert = (num) => {
	const val = (num).replace(/[^0-9]/g, '').replace(/^0\d/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return val;
}


export const sepToNumber = (str, symb = ',') => {
	if (typeof str !== 'string') {
		return str;
	}

	if (symb === ' ' && str.split(symb).length === 1) {
		symb = ',';
	}
	
	return Number(str.split(symb).join(''));
}