import { ethers } from 'ethers';
import _DDL_POOL from '../../deployments/arbitrum_ddl/DDL_POOL.json'
import _USDC from "../../deployments/arbitrum_ddl/USDC.json";
import _WETH from "../../deployments/arbitrum_ddl/WETH.json";
import _WBTC from "../../deployments/arbitrum_ddl/WBTC.json";
import { alchemyProvider, signer } from './providers.js';
import { executePromise } from './utils';
import _OperationalTreasury from '../../deployments/options/OperationalTreasury.json';
import _HegicStrategy_ABI from '../../deployments/options/HegicStrategy_ABI.json';
import _PriceCalculator_ABI from '../../deployments/options/PriceCalculator_ABI.json';
import _HEGOPS from '../../deployments/options/HEGOPS.json';
import _DDL_TakeProfit_Hegic from '../../deployments/options/DDL_TakeProfit_Hegic.json';
import _DDL_TakeProfit_Lyra_ETH from '../../deployments/options/DDL_TakeProfit_Lyra_ETH.json';
import _DDL_TakeProfit_Lyra_BTC from '../../deployments/options/DDL_TakeProfit_Lyra_BTC.json';
import _Lyra_OptionToken_ETH from '../../deployments/options/Lyra_OptionToken_ETH.json';
import _Lyra_OptionToken_BTC from '../../deployments/options/Lyra_OptionToken_BTC.json';
import _BuilderOpen from '../../deployments/builder/optionBuilderOpen.json';
import { CALL, PUT } from '../../views/options/lib/legacyOptions';
import _Chai_ABI from '../../deployments/options/Chai_ABI.json';

// Options Contracts
export const DDL_POOL = new ethers.Contract(_DDL_POOL.address, _DDL_POOL.abi, signer);


// Token approvals
export const USDC = new ethers.Contract(_USDC.address, _USDC.abi, signer);
export const approveUSDC = (spender, setIsLoading, cb) => {
  executePromise(USDC.approve, [spender, ethers.constants.MaxUint256], "USDC approved!", setIsLoading, cb);
}
export const getIsUsdcApproved = async (owner, spender, amount = 1e15) => {
  const allowance = await USDC.allowance(owner, spender);
  return allowance.gte(amount);
}

export const WETH = new ethers.Contract(_WETH.address, _WETH.abi, signer);
export const WBTC = new ethers.Contract(_WBTC.address, _WBTC.abi, signer);
export const tokenContracts = {
  WETH,
  WBTC
}
export const approveToken = (tokenContract, tokenName, spender, setIsLoading, cb) => {
  executePromise(tokenContract.approve, [spender, ethers.constants.MaxUint256],  `${tokenName} approved!`, setIsLoading, cb);
}
export const getIsTokenApproved = async (tokenContract, owner, spender, amount = 1e15) => {
  const allowance = await tokenContract.allowance(owner, spender);
  return allowance.gte(amount);
}


// Hegic
export const OperationalTreasury = _OperationalTreasury.address;
export const OperationalTreasury_ABI = _OperationalTreasury.abi;

export const HEGOPS = _HEGOPS.address;
export const HEGOPS_ABI = _HEGOPS.abi;

export const HEGIC_STRATEGIES = {
	short: {
		HegicStrategy_CALL_100_BTC_1: '0x527d1086C3DD22FcdB338F69D47A1e4bFE11E539',
    HegicStrategy_CALL_100_ETH_1: '0x09a4B65b3144733f1bFBa6aEaBEDFb027a38Fb60',
    HegicStrategy_CALL_110_BTC_1: '0x377191F680b52ce3d0060388fEF01be7ef68e3d5',
    HegicStrategy_CALL_110_ETH_1: '0xF48f571DdD77dba9Ae10fefF6df04484685091D9',
    HegicStrategy_CALL_120_BTC_1: '0x8Fb8529695237D4004cffb21499e7E49E982786a',
    HegicStrategy_CALL_120_ETH_1: '0x96416b82C9DFA7649570c784Eb0D0FFcDE361cFE',
    HegicStrategy_CALL_130_BTC_1: '0x32563D6151891c0A8d56eD644AcDEa6FE5f4E4eB',
    HegicStrategy_CALL_130_ETH_1: '0xf8b8b54bE1Eb37E76873670Ac9DE3C28b507142C',
		HegicStrategy_PUT_100_BTC_1: '0xA8DF600289bEc25602741756f55f27ffFDAB69a6',
    HegicStrategy_PUT_100_ETH_1: '0xaA0DfBFb8dA7f45BB41c0fB68B71FAEB959B22aa',
    HegicStrategy_PUT_70_BTC_1: '0x7066286C1AA0FeBE0f63eF8a44F6Fea9F05FFBE0',
    HegicStrategy_PUT_70_ETH_1: '0x35EF16F3c329a54050Ca120145340dfC2b4F9541',
    HegicStrategy_PUT_80_BTC_1: '0x06c3Ba025bEd24Ea38c2BdDA4bcD2A7F9f5C30e1',
    HegicStrategy_PUT_80_ETH_1: '0x77C8d12d9E2D21f6eE02E8ccA332fDe4BE5C2465',
    HegicStrategy_PUT_90_BTC_1: '0xe115beD3b0752BBbbEF5C423021b464bBF93dC2c',
    HegicStrategy_PUT_90_ETH_1: '0x6B7e5906F53d8bB365f4A6fA776Fd0f0caf57881',
    "HegicStrategy_CALL-SPREAD_10_BTC_1": '0x5D0bc0d08e8A7C09d10df9BB2498f1d4aB986e6A',
    "HegicStrategy_CALL-SPREAD_10_ETH_1": '0x5c59f7ec23C0Bace3B1959C99A43FFd30078E5bE',
    "HegicStrategy_CALL-SPREAD_20_BTC_1": '0x597cdD5D4CEC7FA2851Cc0cfd93d6915c926a107',
    "HegicStrategy_CALL-SPREAD_20_ETH_1": '0x4490ad3c0c1A61061E88a3eaD661bFe16225A370',
    "HegicStrategy_CALL-SPREAD_30_BTC_1": '0xc822F483dAE467721123eA60D3D233e7ac68802f',
    "HegicStrategy_CALL-SPREAD_30_ETH_1": '0x610D7feB49DD18BBC20E4dbe600c53E1cDf6d9F1',
    "HegicStrategy_PUT-SPREAD_10_BTC_1": '0x45b9D63F1998ca31151981792849a64342C88631',
    "HegicStrategy_PUT-SPREAD_10_ETH_1": '0xc4C3b5050D574CBF3eE0b613104cF5C4E47625d4',
    "HegicStrategy_PUT-SPREAD_20_BTC_1": '0x606e34C7008f1425C3a69446de4cAd3D7c6fd96D',
    "HegicStrategy_PUT-SPREAD_20_ETH_1": '0xa1c290D16E2290EA536a13Ba7cc3Df0000A0f1A6',
    "HegicStrategy_PUT-SPREAD_30_BTC_1": '0x71D89f44C71252f319Cd9652f7886c3834685A58',
    "HegicStrategy_PUT-SPREAD_30_ETH_1": '0x8F11Ed84e9Dc76db4392b428A0eAF98Ce72c3752',

	},
	medShort: {
		HegicStrategy_CALL_100_BTC_2: '0x20ee3e61e8f1eB8ae959abf5F78bA8731d010Ac3',
    HegicStrategy_CALL_100_ETH_2: '0x6418C3514923a6464A26A2ffA5f17bF1efC96a21',
    HegicStrategy_CALL_110_BTC_2: '0xaFA483B18FE66251C8306C7018028f674F653012',
    HegicStrategy_CALL_110_ETH_2: '0xaF38a4d9153B149F05FA85C2cCFAaA677c99040E',
    HegicStrategy_CALL_120_BTC_2: '0xFe35F1AB7e18930d4316B86fCefBCF053bf44A45',
    HegicStrategy_CALL_120_ETH_2: '0x1060e80c1156060a1E8DdB9C64Afe74564865Ee2',
    HegicStrategy_CALL_130_BTC_2: '0xe4DB400BFC4D627559D592db4ca2839cb87Fbbfc',
    HegicStrategy_CALL_130_ETH_2: '0xc00103524932d86770d4F671107b0E170BD0fe6c',
		HegicStrategy_PUT_100_BTC_2: '0xE0f083fd87ebAEBFCfa8236098048105d32639E4',
    HegicStrategy_PUT_100_ETH_2: '0x2739A4C003080A5B3Ade22b92c3321EDa2Da3A9e',
    HegicStrategy_PUT_70_BTC_2: '0x52442a8C632D4c998A8BDBf44A70481AA5599b0e',
    HegicStrategy_PUT_70_ETH_2: '0x48EcF46Aa51992a064aB172439ABFE182F39b478',
    HegicStrategy_PUT_80_BTC_2: '0xb02343D33d2447870E38b3452Ef59d0De7b3289d',
    HegicStrategy_PUT_80_ETH_2: '0xb58ccd922B57422d0497904B7D9fccB922b8aaDf',
    HegicStrategy_PUT_90_BTC_2: '0x7d0c3FDF0e8A6f475Ed1834ae05fDa1E519fd227',
    HegicStrategy_PUT_90_ETH_2: '0x3e2a0fE32Cc000d87D9e5D6ed8b3D64e9c74C752',
    "HegicStrategy_CALL-SPREAD_10_BTC_2": '0x6FF946F2BB609B63910a774E193F29CdE7E8fD5f',
    "HegicStrategy_CALL-SPREAD_10_ETH_2": '0x0ac1995C43B5566760AD1D88B812f3e51e12eCc6',
    "HegicStrategy_CALL-SPREAD_20_BTC_2": '0x7E8e5eaD9A1b8F0533F614a736214a95835c79d7',
    "HegicStrategy_CALL-SPREAD_20_ETH_2": '0x1068daF4CD8fDe2000B6FbE8Ec94F106dc08a40E',
    "HegicStrategy_CALL-SPREAD_30_BTC_2": '0x3b4900D5a20dbEbCE44eDF8f6B68Dae32E990Ed0',
    "HegicStrategy_CALL-SPREAD_30_ETH_2": '0x24db3145F45aAF2c54f586C66b801c42c8658671',
    "HegicStrategy_PUT-SPREAD_10_BTC_2": '0x88386f02c46d8D7b2C9E4aEf3b4246A438F72693',
    "HegicStrategy_PUT-SPREAD_10_ETH_2": '0x31e6Cd148fe51830E7a97262eE9cf52Fe5256B08',
    "HegicStrategy_PUT-SPREAD_20_BTC_2": '0x72Ac8A96b8E79466AdcE711EcBD1Ec5968b371C8',
    "HegicStrategy_PUT-SPREAD_20_ETH_2": '0x9e1072E5119C9345d9543a5Ba1EFeDBAE9bEF2AD',
    "HegicStrategy_PUT-SPREAD_30_BTC_2": '0x01381e0EDdB57678257179d2cB472C3e8e54202c',
    "HegicStrategy_PUT-SPREAD_30_ETH_2": '0xE8dD9Cc3A4Bd078f3D36043f259381d5B50D4B45',
	},
	medLong: {
		HegicStrategy_CALL_100_BTC_3: '0x21a4b4441AA957735902507A72A58A3a1Caaa0dC',
    HegicStrategy_CALL_100_ETH_3: '0xE377A1a97237b3B89a96d8B731A2ab10d5DaC16C',
    HegicStrategy_CALL_110_BTC_3: '0x6c46e2531a42F618F2ae452971DC9a7c6AD48D15',
    HegicStrategy_CALL_110_ETH_3: '0x05458B7d9531EaD242290De60bEaa3cC10C87560',
    HegicStrategy_CALL_120_BTC_3: '0xe259410Bbfb45CE89FDA81ec0A97d36Da8Db4374',
    HegicStrategy_CALL_120_ETH_3: '0x79F87E88088293FE34dEf4eBe71fBFa5367220B4',
    HegicStrategy_CALL_130_BTC_3: '0x27806482457FC98F9C2e724b5E604C66BB5ba121',
    HegicStrategy_CALL_130_ETH_3: '0xae2F4e15564995af79b7dd2378B2680f7Facc342',
    HegicStrategy_PUT_100_BTC_3: '0x035b965c5B7bC20E00358ACF1794d2248eA1932A',
    HegicStrategy_PUT_100_ETH_3: '0xf711D0BC60F37cA28845BA623ccd9C635E5073A1',
    HegicStrategy_PUT_70_BTC_3: '0x55c4532e1790592120b7b27436842caD203E7CD3',
    HegicStrategy_PUT_70_ETH_3: '0xF55F6d98e2F48F8D46757D5A6e225bf0ADC9651C',
    HegicStrategy_PUT_80_BTC_3: '0x8dD239e0e51BCAAd503b06D818ce811e7D99c1Af',
    HegicStrategy_PUT_80_ETH_3: '0xdF30cdb7abb3Ca707b82359A349D3b0C94d4a421',
    HegicStrategy_PUT_90_BTC_3: '0xE56BEAfAe4731A21eF83fe81F63b95055e81E374',
    HegicStrategy_PUT_90_ETH_3: '0x33a4B4403B8C6349371CbDf539138D78ec0Aab66',
    "HegicStrategy_CALL-SPREAD_10_BTC_3": '0x8e3063D5dCbe66E58053C3Bc8E0BC8dDFe43D585',
    "HegicStrategy_CALL-SPREAD_10_ETH_3": '0xBB318170Ce1c2dc79B564f33F92BacaadaBd22de',
    "HegicStrategy_CALL-SPREAD_20_BTC_3": '0x324be893029D982c187F0333E7aa1d44365d2676',
    "HegicStrategy_CALL-SPREAD_20_ETH_3": '0xb89d7eB2bc675347BEb685ff1bE1557E983beFe1',
    "HegicStrategy_CALL-SPREAD_30_BTC_3": '0x530289340C6d2Ef02140054Ae7F91C293fd586CF',
    "HegicStrategy_CALL-SPREAD_30_ETH_3": '0x929d20D9e1A6A90bf057857C8BA09271f1BaadA2',
    "HegicStrategy_PUT-SPREAD_10_BTC_3": '0x7267091cFc9825a4A1dF36C26d412FeFD788d935',
    "HegicStrategy_PUT-SPREAD_10_ETH_3": '0x2B93645f310E016c1a6d1738DCec0f18621F71d0',
    "HegicStrategy_PUT-SPREAD_20_BTC_3": '0x9ab2f8530150799CDdAC35799d18Ad2fe05Bf8c3',
    "HegicStrategy_PUT-SPREAD_20_ETH_3": '0x4EE21aB8bc165Cd3e6C45676F9f64426Ffc2d67b',
    "HegicStrategy_PUT-SPREAD_30_BTC_3": '0xBa883223A1757eEB781d02C383926c308d4843f4',
    "HegicStrategy_PUT-SPREAD_30_ETH_3": '0xf87C8C7587055FA62082E8ed447fe68D98805fF2',
	},
	long: {
		HegicStrategy_CALL_100_BTC_4: '0xc07fBf677f417bb9FfDD8248A74c7FBcCFaDAe9e',
    HegicStrategy_CALL_100_ETH_4: '0x2727B807D22fCAeB7F900F49894054Ed92b9125B',
    HegicStrategy_CALL_110_BTC_4: '0x14d25367102A931E2E2F587F4ED94087dc585fd0',
    HegicStrategy_CALL_110_ETH_4: '0xE53a20c824cEAB8151AF8Fc92E7Eb689cFbD1231',
    HegicStrategy_CALL_120_BTC_4: '0xBAbccB37338488AFBaBECAE2C3D21b9a71289A73',
    HegicStrategy_CALL_120_ETH_4: '0xB7b36483B256290435a060805bdF15B3f2b122eb',
    HegicStrategy_CALL_130_BTC_4: '0x9fA4DFfBE3B129988e0d09536b0E2D74F6bF7D91',
    HegicStrategy_CALL_130_ETH_4: '0x4dC39763ccC861dB1c8e80c34C0079c9A12f03E4',
    HegicStrategy_PUT_100_BTC_4: '0xEd46f602212d1CbaCd699C876b4A49761421bf97',
    HegicStrategy_PUT_100_ETH_4: '0x015FAA9aF7599e6cea597EBC7e7e04A149a3E992',
    HegicStrategy_PUT_70_BTC_4: '0xa26541ccE18CcC6E21360562d34044ceb3B5A40e',
    HegicStrategy_PUT_70_ETH_4: '0x5BBe6C4A66D4a48442dc0837a0eA859a11D7766C',
    HegicStrategy_PUT_80_BTC_4: '0xFD203c73851986ad7e7B99457AeafF3d98e76228',
    HegicStrategy_PUT_80_ETH_4: '0x7FD5E8B2Ef25A105bf6B1c45f62d8925Eaa068Bc',
    HegicStrategy_PUT_90_BTC_4: '0x02d730e3dEb3B5876fE2Cba59423ef1Db8211DA1',
    HegicStrategy_PUT_90_ETH_4: '0x3031EA515c2274024D93A8D3BfA91ce920E1192E',
    "HegicStrategy_CALL-SPREAD_10_BTC_4": '0x0c1C4D9dFd51093bE1e4Bab1b565113B339835F6',
    "HegicStrategy_CALL-SPREAD_10_ETH_4": '0xCe5e136688A1553C67b3B39Ff5366595dD0F771E',
    "HegicStrategy_CALL-SPREAD_20_BTC_4": '0x7b92a9f268bab14F0FAee4337B01D563AE132Bc3',
    "HegicStrategy_CALL-SPREAD_20_ETH_4": '0xe3F646CB987a9d16eb71B8d0883744e55c95862a',
    "HegicStrategy_CALL-SPREAD_30_BTC_4": '0x223b806Bab0CA844a91b247fE0Ba179E11EAA728',
    "HegicStrategy_CALL-SPREAD_30_ETH_4": '0x199DB958f00F150438E5b9276ff30617D3f83ebF',
    "HegicStrategy_PUT-SPREAD_10_BTC_4": '0x66F54921b32cCA36767D2C272Dda64D5152B92C4',
    "HegicStrategy_PUT-SPREAD_10_ETH_4": '0xfcd40dDe82b699D455B3D073196d580227C2Fba6',
    "HegicStrategy_PUT-SPREAD_20_BTC_4": '0x8c77f5427B40ab2414dC9bca48c74928BFe17Dd1',
    "HegicStrategy_PUT-SPREAD_20_ETH_4": '0xbAe8114E3A2EA5aF5e2b3c8F6FF37714f6D4afFB',
    "HegicStrategy_PUT-SPREAD_30_BTC_4": '0x6D79b82701e4017887d499Ba131FD336ceA6A4CC',
    "HegicStrategy_PUT-SPREAD_30_ETH_4": '0x9945Eb8ac1F4c524fe7Da710ff3068969A329fBA',
	}
}
export const PRICE_CALCULATORS = {
  PriceCalculator_PUT_100_BTC: '0x4567600c2c813322F0CBb358a8d083296A7eEA37',
  PriceCalculator_PUT_100_ETH: '0xB72FC913e45522cD721252476fEfB7F50a65E23A',
  PriceCalculator_PUT_70_BTC: '0x3aFbaf00a6C42e6BE67f8a618b17fB3D441C1898',
  PriceCalculator_PUT_70_ETH: '0xf3625e26cb920f762dEC8DB4fE4845E9384313C0',
  PriceCalculator_PUT_80_BTC: '0x9C3BBe90191F8311939A34737c02A6B49e27caA5',
  PriceCalculator_PUT_80_ETH: '0x58051Cb78094551afC202C50085B13bF00BF624E',
  PriceCalculator_PUT_90_BTC: '0x56A2dC3fAc4bE0A1716c938295e141195e4A1cb3',
  PriceCalculator_PUT_90_ETH: '0x1AF37c8412bCdAa6C6dD4D2E2aDae2B8292603D4',
  PriceCalculator_CALL_100_BTC: '0xAA4483Da5c4D17830dE9a843d07C37b3eC028d9F',
  PriceCalculator_CALL_100_ETH: '0xC62a0b7e480a23BdCfe13EA08BDAceDc278a14C1',
  PriceCalculator_CALL_110_BTC: '0x04BFC83f6277cf50dFB032EFd348316aACbD7863',
  PriceCalculator_CALL_110_ETH: '0x6316BC5f5dF141ae88C436Fe6047D334f1B7e485',
  PriceCalculator_CALL_120_BTC: '0x965Ded687bc0Da3388c6925e0Eb330bD44629B77',
  PriceCalculator_CALL_120_ETH: '0x321F8A71e8268f50eBd2f748D524E7750AD70798',
  PriceCalculator_CALL_130_BTC: '0x4b350b7457b54C5A76828f81472f2719D801D0D5',
  PriceCalculator_CALL_130_ETH: '0xffdc9da896d3301DE596b16a389138B70b1CBAdb',
  "PriceCalculator_CALL-SPREAD_10_BTC": '0xD3872581C3f7DE832Ecc9Aa51eb525AC9Ffc41d2',
  "PriceCalculator_CALL-SPREAD_10_ETH": '0x3BC74C993e1E85ffB5e242402aEc3e90DCfa249f',
  "PriceCalculator_CALL-SPREAD_20_BTC": '0x7f6a7d60c6f7ac50BD0efA677D397d9cd61fC3a9',
  "PriceCalculator_CALL-SPREAD_20_ETH": '0x8C335E897B04B2Ca569Ec733f6242cDe33917094',
  "PriceCalculator_CALL-SPREAD_30_BTC": '0x631c5EFb3e9D2536F9c9c9d7635e2B01363C51C5',
  "PriceCalculator_CALL-SPREAD_30_ETH": '0xAe4897D4d3742F7f9C6A1eA01447EA3061bD6de2',
  "PriceCalculator_PUT-SPREAD_10_BTC": '0xa4A32102a2c1d3Bf01A73B66571B440795835ff5',
  "PriceCalculator_PUT-SPREAD_10_ETH": '0x5a4Dc7011CA0be61c42957cDC36D508d45010D8B',
  "PriceCalculator_PUT-SPREAD_20_BTC": '0x0cC3559beCa7bB605348B1a7B4D90963672C880a',
  "PriceCalculator_PUT-SPREAD_20_ETH": '0xeb38c2Ba3125733e9636eAB7C3c2e7a2C4A11534',
  "PriceCalculator_PUT-SPREAD_30_BTC": '0x4CE72803543da3f563Ef76592945C57FBE7fce38',
  "PriceCalculator_PUT-SPREAD_30_ETH": '0xDB044975100aE87d0eEDd804D9E6083a8406fdAe',
}
export const HegicStrategy_ABI = _HegicStrategy_ABI;
export const PriceCalculator_ABI = _PriceCalculator_ABI;

export const HEGIC_BUY_URLS = {
  PUT_100_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-100&&strategyName=bear-put&asset=BTC&sentiment=bear",
  PUT_100_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-100&&strategyName=bear-put&asset=ETH&sentiment=bear",
  PUT_70_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-70&&strategyName=bear-put&asset=BTC&sentiment=bear",
  PUT_70_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-70&&strategyName=bear-put&asset=ETH&sentiment=bear",
  PUT_80_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-80&&strategyName=bear-put&asset=BTC&sentiment=bear",
  PUT_80_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-80&&strategyName=bear-put&asset=ETH&sentiment=bear",
  PUT_90_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-90&&strategyName=bear-put&asset=BTC&sentiment=bear",
  PUT_90_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=put-90&&strategyName=bear-put&asset=ETH&sentiment=bear",

  CALL_100_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-100&&strategyName=bull-call&asset=BTC&sentiment=bull",
  CALL_100_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-100&&strategyName=bull-call&asset=ETH",
  CALL_110_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-110&&strategyName=bull-call&asset=BTC&sentiment=bull",
  CALL_110_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-110&&strategyName=bull-call&asset=ETH",
  CALL_120_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-120&&strategyName=bull-call&asset=BTC&sentiment=bull",
  CALL_120_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-120&&strategyName=bull-call&asset=ETH",
  CALL_130_BTC: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-130&&strategyName=bull-call&asset=BTC&sentiment=bull",
  CALL_130_ETH: "https://www.hegic.co/app#/arbitrum/trade/new?strikeName=call-130&&strategyName=bull-call&asset=ETH",
}

export const HEGIC_SENTIMENT_LABELS = {
  "CALL": CALL,
  "PUT": PUT,
  "CALL-SPREAD": "Bull Call Spread",
  "PUT-SPREAD": "Bear Put Spread"
}

// Chai
export const CHAI_CONTRACT_ETH = '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612';
export const CHAI_CONTRACT_BTC = '0x6ce185860a4963106506C203335A2910413708e9';
export const Chai_ABI = _Chai_ABI;


// Lyra
export const LyraQuoter = '0x23236b4c7772636b5224df56Be8168A2f42df31C';

// Premia
export const PremiaPools = {
  ETH: "0xE5DbC4EDf467B609A063c7ea7fAb976C6b9BAa1a",
  BTC: "0xb5fE3bc2eF4c34cC233922dfF2Fcb1B1BF89A38E"
}

// DDL Options
// Hegic
export const DDL_TP_Hegic_addr = _DDL_TakeProfit_Hegic.address;
export const DDL_TP_Hegic_ABI = _DDL_TakeProfit_Hegic.abi;
// Lyra
export const DDL_TP_Lyra_ETH_addr = _DDL_TakeProfit_Lyra_ETH.address;
export const DDL_TP_Lyra_BTC_addr = _DDL_TakeProfit_Lyra_BTC.address;
export const DDL_TP_Lyra_ABI = _DDL_TakeProfit_Lyra_ETH.abi;

export const getTPMethod = (name, market) => {
  switch(market) {
    case "Hegic":
      return `${name}TakeProfit`;
    case "Lyra":
      return `${name}StopOrder`;
  }
}
export const getTPContract = (market, token, signer) => {
  switch(market) {
    case "Hegic":
      return new ethers.Contract(DDL_TP_Hegic_addr, DDL_TP_Hegic_ABI, signer);
    case "Lyra":
      if (token === "ETH") {
        return new ethers.Contract(DDL_TP_Lyra_ETH_addr, DDL_TP_Lyra_ABI, signer);
      }
      if (token === "BTC") {
        return new ethers.Contract(DDL_TP_Lyra_BTC_addr, DDL_TP_Lyra_ABI, signer);
      }
      break;
    case "Lyra_old":
      return new ethers.Contract(_DDL_TakeProfit_Lyra_ETH.address_old, DDL_TP_Lyra_ABI, signer);
  }
}
export const getTPApproveContract = (market, token, signer) => {
  switch(market) {
    case "Hegic":
      return new ethers.Contract(HEGOPS, HEGOPS_ABI, signer);
    case "Lyra":
      if (token === "ETH") {
        return new ethers.Contract(_Lyra_OptionToken_ETH.address, _Lyra_OptionToken_ETH.abi, signer);
      }
      if (token === "BTC") {
        return new ethers.Contract(_Lyra_OptionToken_BTC.address, _Lyra_OptionToken_BTC.abi, signer);
      }
  }
}


// Options Builder
export const builderOpen = _BuilderOpen.address;
export const builderOpen_ABI = _BuilderOpen.abi;
export const builderOpenContract = new ethers.Contract(builderOpen, builderOpen_ABI, signer);