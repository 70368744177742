import React from 'react';
import { ImSpinner2 } from 'react-icons/im';

const Spinner = (props) => {
	const {
		style,
		hasMargin,
	} = props;

	return (
		<ImSpinner2 style={{marginLeft: hasMargin ? 7 : 0, ...style}} className="spin position-loading-icon" />
	);
};

export default Spinner;