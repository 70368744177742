import React from 'react';
import { ARBITRUM, USD_DECIMALS, formatAmount } from '../views/gmx-test/lib/legacy';
import Currency from './Currency';
import { getTokenBySymbol } from '../views/gmx-test/config/Tokens';
import icon_market from '../img/icon-market.svg';
import './MarkPricePanel.scss';

const MarkPricePanel = (props) => {
	const {
		infoTokens,
		currencies = ["ETH", "BTC"]
	} = props;
	
	return (
		<div className="MarkPricePanel">
			<div className="MarkPricePanel__icon">
				<img src={icon_market} alt="Market icon" />
			</div>
			<div className="MarkPricePanel__items">
				{currencies.map(curr => {
					const addr = getTokenBySymbol(ARBITRUM, curr).address;
					const markPrice = infoTokens && infoTokens[addr]?.maxPrice;
					return (
						<div className="MarkPricePanel__item" key={curr}>
							<Currency isHlight={true} currencyName={curr}>
								${formatAmount(markPrice, USD_DECIMALS, 2, true)}
							</Currency>
						</div>
					)
				})}
			</div>
		</div>
	);
};

export default MarkPricePanel;