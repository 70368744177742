// Style
import './style/App.scss'

// Main
import React, { useState, useEffect, createContext } from 'react';

import Header from './Header';
import Footer from './Footer';
import AppRouter from './components/AppRouter';
import { HashRouter } from 'react-router-dom';
import { GodEyeContext } from './context/context';
import Warning from './components/UI/warning/Warning';
import { ToastContainer } from 'react-toastify';
import { cssTransition } from 'react-toastify';
import EventToastContainer from './views/gmx-test/components/EventToast/EventToastContainer';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { defaultLocale, dynamicActivate } from './views/gmx-test/lib/i18n';
import { ARBITRUM, CURRENT_PROVIDER_LOCALSTORAGE_KEY, LANGUAGE_LOCALSTORAGE_KEY, SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, activateInjectedProvider, getInjectedHandler, useEagerConnect, useInactiveListener } from './views/gmx-test/lib/legacy';
import SEO from './views/gmx-test/components/Common/SEO';
import { getTokens } from './views/gmx-test/config/Tokens';
import useSWR from 'swr';
import Reader from "./views/gmx-test/abis/ReaderV2.json";
import { useWeb3React } from '@web3-react/core';
import { getContract } from './views/gmx-test/config/Addresses';
import { fetcher } from './views/gmx-test/lib/contracts/fetcher';
import { useInfoTokens } from './views/gmx-test/domain/legacy';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  return library;
}

function FullApp() {
	const [godEyeWallet, setGodEyeWallet] = useState(null);
	
	const Jelly = cssTransition({
		enter: "jellyIn",
		exit: "jellyOut",
	});
	
	// Activating Web3
	const { connector, activate, active, library, account } = useWeb3React();
	const connectInjectedWallet = getInjectedHandler(activate);
  const attemptActivateWallet = (providerName) => {
    localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, true);
    localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
    activateInjectedProvider(providerName);
    connectInjectedWallet();
  };
	const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);
  const triedEager = useEagerConnect(setActivatingConnector);
  useInactiveListener(!triedEager || !!activatingConnector);
	const connectWallet = () => {
		attemptActivateWallet("MetaMask");
	}

	// Getting infoTokens and balances
	const tokens = getTokens(ARBITRUM);
  const tokenAddresses = tokens.map((token) => token.address);
  const readerAddress = getContract(ARBITRUM, "Reader");
  const { data: tokenBalances } = useSWR(active && [active, account, readerAddress, "getTokenBalances"], {
    fetcher: fetcher(library, Reader, [account, tokenAddresses]),
    refreshInterval: 10 * 1000
  });
  const { infoTokens } = useInfoTokens(library, ARBITRUM, active, tokenBalances);
	
	return (
		<HashRouter>
			<I18nProvider i18n={i18n}>
				<GodEyeContext.Provider value={godEyeWallet}>
					<ToastContainer
						limit={2}
						transition={Jelly}
						position="bottom-right"
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick={false}
						draggable={false}
						pauseOnHover={false}
					/>
					<EventToastContainer />
					<div className="App">
						<Header
							connectWallet={connectWallet}
						/>
						<main className='_container'>
							<AppRouter
								connectWallet={connectWallet}
								infoTokens={infoTokens}
								setGodEyeWallet={setGodEyeWallet}
							/>
						</main>
						<Footer />
					</div>
				</GodEyeContext.Provider>
			</I18nProvider>
		</HashRouter>
	)
}

function App() {
	
	useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);
	
	return (
		<>
			<Web3ReactProvider getLibrary={getLibrary}>
				<SEO>
					<FullApp />
					<Warning>
						DeDeLend is in alpha. Use at your own risk
					</Warning>
				</SEO>
			</Web3ReactProvider>
		</>
	);
}

export default App;
