export const builderStrategies = {
	"Long Call": {
		stats: {
			Outlook: 1,
			Profit: true,
			Risk: false,
		},
		structure: [1], // Array of options' keys: positive = buy, negative = sell
		// Example: -1 = Sell Call, 1 = Buy Call.
		key: 1,
		color: "#46956A",
	},
	"Short Call": {
		stats: {
			Outlook: -1,
			Profit: false,
			Risk: true,
		},
		structure: [-1],
		key: 101,
		color: "#C75E4B",
		collateralTokens: ["USDC"]
	},
	"Covered Call": {
		stats: {
			Outlook: 1,
			Profit: false,
			Risk: true,
		},
		structure: [-1],
		key: 201,
		color: "#C75E4B",
		collateralTokens: ["base"]
	},
	"Long Put": {
		stats: {
			Outlook: -1,
			Profit: true,
			Risk: false,
		},
		structure: [2],
		key: 2,
		color: "#46956A"
	},
	"Short Put": {
		stats: {
			Outlook: 1,
			Profit: false,
			Risk: true,
		},
		structure: [-2],
		key: 102,
		color: "#C75E4B",
	},
	"Long Strangle": {
		stats: {
			Outlook: 0,
			Profit: true,
			Risk: false,
		},
		isDateUnified: true,
		structure: [1, 2],
		key: 3,
		color: "#D99445"
	},
	"Short Strangle": {
		stats: {
			Outlook: 0,
			Profit: false,
			Risk: true,
		},
		isDateUnified: true,
		structure: [-1, -2],
		key: 103,
		color: "#C75E4B",
		collateralTokens: ["USDC"]
	},
	"Covered Strangle": {
		stats: {
			Outlook: [0, 1],
			Profit: false,
			Risk: true,
		},
		isDateUnified: true,
		structure: [-1, -2],
		key: 203,
		color: "#C75E4B",
		collateralTokens: ["base"]
	},
	"Long Straddle": {
		stats: {
			Outlook: 0,
			Profit: true,
			Risk: false,
		},
		isDateUnified: true,
		isStrikeUnified: true,
		structure: [1, 2],
		key: 4,
		color: "#D99445"
	},
	"Short Straddle": {
		stats: {
			Outlook: 0,
			Profit: false,
			Risk: true,
		},
		isDateUnified: true,
		isStrikeUnified: true,
		structure: [-1, -2],
		key: 104,
		color: "#C75E4B",
		collateralTokens: ["USDC"]
	},
	"Covered Straddle": {
		stats: {
			Outlook: [0, 1],
			Profit: false,
			Risk: true,
		},
		isDateUnified: true,
		isStrikeUnified: true,
		structure: [-1, -2],
		key: 204,
		color: "#C75E4B",
		collateralTokens: ["base"]
	},
	"Iron Butterfly": {
		stats: {
			Outlook: 0,
			Profit: false,
			Risk: false,
		},
		isStrikeUnified: true,
		isDateUnified: true,
		structure: [2, -2, -1, 1],
		key: 5,
		color: "#6988FF",
		inputLabel: "Iron Butterflies"
	},
	"Iron Condor": {
		stats: {
			Outlook: 0,
			Profit: false,
			Risk: false,
		},
		isDateUnified: true,
		structure: [2, -2, -1, 1],
		key: 6,
		color: "#6988FF",
	},
	/* "Bull Call Spread": {
		stats: {
			Outlook: 0,
			Profit: false,
			Risk: true,
		},
		isDateUnified: true,
		structure: [2, -2, -1, 1],
		key: 7,
		color: "#6988FF",
		inputLabel: "Ironflies"
	},
	"Bear Put Spread": {
		stats: {
			Outlook: 0,
			Profit: false,
			Risk: false,
		},
		isDateUnified: true,
		structure: [2, -2, -1, 1],
		key: 8,
		color: "#6988FF",
		inputLabel: "Condors"
	}, */
}

export const strategyStatsLabels = {
	Outlook: {
		"1": "Bullish",
		"0": "Neutral",
		"-1": "Bearish"
	},
	Profit: {
		"true": "Unlimited",
		"false": "Limited"
	},
	Risk: {
		"true": "Unlimited",
		"false": "Limited"
	}
}

export const builderProtocols = {
	"Lyra_ETH" : 0,
	"Lyra_BTC": 1,
	"Hegic_ETH": 2,
	"Hegic_BTC": 2,
}

export const getOptionComponentLabel = (key) => {
	const strategy = Object.keys(builderStrategies).find(stratName => {
		return builderStrategies[stratName].key === Math.abs(key);
	})
	const isCall = strategy.includes("Call");
	return `${key > 0 ? "Buy" : "Sell"} ${isCall ? "Call" : "Put"}`
}

export const getDefaultStrikes = () => {
	return {
		defaultStrikes: { Hegic: [], Lyra: [] },
		defaultStrikesData: { Hegic: {}, Lyra: {} },
	}
}