import React, { useState, useEffect } from 'react';
import { SL, TP, formatAmount } from '../../lib/legacyOptions';
import { ImSpinner2 } from 'react-icons/im';
import { USD_DECIMALS } from '../../lib/legacyOptions';
import '../../../gmx-test/components/Exchange/PositionsItem.scss';
import cx from "classnames";
import { DATE_FORMAT_TEMPLATE, OPTIONS_DECIMALS } from '../../../../components/utils/constants';
import { formatAmountFree } from '../../lib/legacyOptions';
import { fromBigNumber } from '../../../../components/utils/utils';
import { format } from 'date-fns';
import icon_edit from '../../../../img/icon-edit.svg';
import Tooltip from './../../../gmx-test/components/Tooltip/Tooltip';
import Currency from '../../../../components/Currency';
import Spinner from '../../../../components/UI/loader/Spinner';

const PositionsItem = (props) => {
	const {
		position,
		onPositionClick,
		closePosition,
		markPrices,
		isLarge,
		pendingPositions,
		openSLTP,
	} = props;

	const [isTpSubmitting, setIsTpSubmitting] = useState(false);
	const [isSlSubmitting, setIsSlSubmitting] = useState(false);
	useEffect(() => {
		setIsTpSubmitting(false);
		setIsSlSubmitting(false);
	}, [position]);

	const markPrice = markPrices[position.token];
	const hasTP = (position.tp && !position.tp.eq(0)) || isTpSubmitting;
	const hasSL = (position.sl && !position.sl.eq(0)) || isSlSubmitting;
	const getTpTooltipText = () => {
		if (hasTP || hasSL) {
			return (
				<>
					To set up a {hasTP ? SL : TP}, you <br />need to first remove the <br />active {hasTP ? TP : SL} order
				</>
			)
		}
	}
	const getIsTooltipEnabled = (isTP) => {
		if (isTP ? hasSL : hasTP) {
			return true;
		}
	}
	
	let delta = 0, deltaStr = '...', hasPositionProfit = false;

	if (position.pnl) {
		const { unrealizedPnl, unrealizedPnlPercentage } = position.pnl;
		delta = unrealizedPnl;
		
		deltaStr = `
			${unrealizedPnl.lt(0) ? '-' : ''}$${Math.abs(fromBigNumber(unrealizedPnl, OPTIONS_DECIMALS)).toFixed(2)}
			(${formatAmount(unrealizedPnlPercentage, OPTIONS_DECIMALS - 2, 2, true)}%)
		`;
		hasPositionProfit = unrealizedPnl.gt(0);
	}

	const isPending = position.isPending || pendingPositions.find(strat => position.strategy === strat);
	const closeBtnDisabled = position.size.eq(0) || position.isClosingDisabled || isPending || hasTP || hasSL;
	const marketImg = require(`../../../../img/icon-${position.market}_sm.svg`).default;

	const isSLTPDisabled = isPending;

	const closeButton = (
		<Tooltip
			className="Tooltip_disabled"
			handle={
				<>
					<button
						className="btn_inline"
						style={{marginLeft: "auto"}}
						onClick={() => {
							closePosition(position);
						}}
						disabled={closeBtnDisabled}
					>
						Close
					</button>
				</>
			}
			position="right-bottom"
			enabled={hasSL || hasTP}
			renderContent={() => {
				return (
					<div className="Tooltip__text">
						To close the position <br />you need to remove <br />active Stop Orders
					</div>
				);
			}}
		/>
		
	)
	const TPButton = (
		<>
			{isTpSubmitting
				? <Spinner />
				: <>
						{hasTP && `$${formatAmount(position.tp, OPTIONS_DECIMALS, 2, true)}`}
						<Tooltip
							className="Tooltip_take-profit Tooltip_disabled"
							handle={
								<>
									<button
										className="btn_inline"
										onClick={() => {
											openSLTP(position, true, setIsTpSubmitting);
										}}
										disabled={isSLTPDisabled || hasSL}
									>
										{hasTP
											? <img className='edit-btn' src={icon_edit} alt="Edit Take Profit button" />
											: 'Set TP'}
									</button>
								</>
								
							}
							position="left-bottom"
							enabled={getIsTooltipEnabled(true)}
							renderContent={() => {
								return (
									<div className="Tooltip__text">
										{getTpTooltipText()}
									</div>
								);
							}}
						/>
					</>}
		</>
	)
	const SLButton = (
		<>
			{isSlSubmitting
				? <Spinner />
				: <>
						{hasSL && `$${formatAmount(position.sl, OPTIONS_DECIMALS, 2, true)}`}
						<Tooltip
							className="Tooltip_take-profit Tooltip_disabled"
							handle={
								<button
									className="btn_inline"
									onClick={() => {
										openSLTP(position, false, setIsSlSubmitting);
									}}
									disabled={isSLTPDisabled || hasTP || position.isClosingDisabled}
								>
									{hasSL
										? <img src={icon_edit} className='edit-btn' alt="Edit Stop Loss button" />
										: 'Set SL'}
								</button>
							}
							position="left-bottom"
							enabled={getIsTooltipEnabled(false)}
							renderContent={() => {
								return (
									<div className="Tooltip__text">
										{getTpTooltipText()}
									</div>
								);
							}}
						/>
					</>}
		</>
	)
	return (
		<>
			{isLarge ?
				<tr className={cx(isPending && 'positions-item_pending')}>
					<td className="clickable" onClick={() => onPositionClick(position)}>
						{/* <div className="Exchange-list-title">
							{position.token}
						</div> */}
						<div className="Exchange-list-info-label">
							<img className='market-logo' src={marketImg} alt={`${position.market} icon`} />
							<span className={cx({ positive: position.isCall, negative: !position.isCall })}>
								{position.sentiment}
							</span>
							{isPending && <ImSpinner2 className="spin position-loading-icon" />}
						</div>
					</td>
					<td>
						<Currency currencyName={position.token}>
							{formatAmountFree(position.size, OPTIONS_DECIMALS, 3)}
						</Currency>
					</td>
					<td>
						${formatAmount(position.strikePrice, OPTIONS_DECIMALS, 2, true)}
					</td>
					<td>—</td>
					<td>
						<div>
							<Currency>
								{formatAmount(position.premium, OPTIONS_DECIMALS, 2, true)}
							</Currency>
						</div>
					</td>
					<td>
						<div style={{ width: 150, display: 'block' }}>
							{delta ? (
								<div
									className={cx("Exchange-list-info-label", {
										positive: hasPositionProfit,
										negative: !hasPositionProfit,
										muted: delta.eq(0),
									})}
								>
									{deltaStr}
								</div>
							) : deltaStr}
						</div>
					</td>
					<td>
						{format(position.expiry, DATE_FORMAT_TEMPLATE)}
					</td>
					<td>
						{TPButton}
					</td>
					<td>
						{SLButton}
					</td>
					<td className="td-btn pos-relative" style={{ width: 110, textAlign: 'center' }}>
						{closeButton}
					</td>
				</tr>
			:
			<div className={cx("App-card", isPending && 'positions-item_pending')}>
				<div className="App-card-divider"></div>
				<div className="App-card-content">
					<div className="App-card-row">
						<div className="label">
							Position
						</div>
						<div>
							{/* <div className="Exchange-list-token">
								{position.token}
							</div> */}
							{isPending && <ImSpinner2 className="spin position-loading-icon" style={{marginRight: 10}} />}
							<img className='market-logo' src={marketImg} alt={`${position.market} icon`} />
							<span
								className={cx("Exchange-list-side", {
									positive: position.isCall,
									negative: !position.isCall,
								})}
							>
								{position.sentiment}
							</span>
						</div>
					</div>
				</div>
				<div className="App-card-divider"></div>
				<div className="App-card-content">
					<div className="App-card-row">
						<div className="label">
							Amount
						</div>
						<div>
							<Currency currencyName={position.token}>
								{formatAmountFree(position.size, OPTIONS_DECIMALS, 3)}
							</Currency>
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Strike
						</div>
						<div>
							${formatAmount(position.strikePrice, OPTIONS_DECIMALS, 2, true)}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Liq. Price
						</div>
						<div>
							—
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Premium Paid
						</div>
						<Currency>
							{formatAmount(position.premium, OPTIONS_DECIMALS, 2, true)}
						</Currency>
					</div>
					<div className="App-card-row">
						<div className="label">
							PNL (ROE %)
						</div>
						<div>
							{delta ? (
								<div
									className={cx("Exchange-list-info-label", {
										positive: hasPositionProfit,
										negative: !hasPositionProfit,
										muted: delta.eq(0),
									})}
								>
									{deltaStr}
								</div>
							) : deltaStr}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Expiration Date
						</div>
						<div>
							{format(position.expiry, 'MM/dd/yy, hh:mm a')}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Take Profit
						</div>
						<div>
							{TPButton}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Stop Loss
						</div>
						<div>
							{SLButton}
						</div>
					</div>
				</div>
				<div className="App-card-divider"></div>
				<div className="App-card-options">
					<div className="App-button-option pos-relative">
						{closeButton}
					</div>
				</div>
			</div>}
		</>
	);
};

export default PositionsItem;