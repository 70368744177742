import React, { useState, useRef } from 'react';
import cx from 'classnames';
import './InputSimple.scss';

const InputSimple = (props) => {
	const {
		value,
		setValue,
		onChange,
		hasError,
		disabled,
		unit,
		placeholder,
		type,
	} = props;

	const [hasFocus, setHasFocus] = useState(false);
	const cls = cx(props.className, "InputSimple", hasFocus && 'hlight', hasError && 'error', disabled && 'disabled');
	const inputRef = useRef(null);
	
	return (
		<div className={cls} onClick={() => {
			inputRef.current.focus();
		}}>
			<input
				ref={inputRef}
				disabled={disabled}
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={(e) => {
					if (onChange) {
						onChange(e);
					} else {
						setValue(e.target.value);
					}
				}}
				onFocus={(e) => {
					setHasFocus(true);
				}}
				onBlur={(e) => {
					setHasFocus(false);
				}}
			/>
			<div className="InputSimple__unit">
				{unit}
			</div>
		</div>
	);
};

export default InputSimple;