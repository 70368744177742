import React from 'react';
import './Currency.scss';
import cx from "classnames";

const Currency = ({ className, children, isSmall = true, currencyName = "USDC", isHlight }) => {
	const val = children;
	const icon = require(`../img/icon-${currencyName}.svg`).default
	
	return (
		<div className={cx("Currency", isHlight && 'hlight', className)}>
			<img src={icon} alt={`${currencyName} icon`} width={isSmall ? 15 : "auto"}/>
			<span>{val}</span>
		</div>
	);
};

export default Currency;