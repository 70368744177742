import React, { useState } from 'react';
import './Selector.scss';
import cx from 'classnames';
import { useTogglePopup } from '../../../hooks/useTogglePopup';

const Selector = (props) => {
	const {
		className,
		items,
		chosenItem,
		disabled,
		defaultText,
		onSelect,
		onChange,
		renderOnHover,
		titleIcon,
		iconText,
		hideChosenItem,
		formatFn = (item) => item,
		sortFn = (items) => items,
		isComplex,
		complexCols = [],
		renderChosenItem,
		footerNode,
	} = props;
	
	const {
		isOpen,
		openPopup: openSelector,
		closePopup: closeSelector,
	} = useTogglePopup(disabled);

	const cls = cx("Selector", className, isOpen && 'open', disabled && 'disabled', isComplex && 'complex');

	let chosenItemIcon;
	try {
		chosenItemIcon = require(`../../../img/icon-${chosenItem}.svg`).default;
	} catch(e) {}
	const chosenItemLabel = chosenItem && formatFn(chosenItem, true);

	const emptyNote = (
		<div className="Selector__item Selector__item-empty-note">
			No items available
		</div>
	)
	const renderComplexListFn = () => {
		if (!items) {
			return null;
		}
		const nodes = [];
		Object.keys(items).forEach(group => {
			const headerNode = (
				<div className="Selector__item-header" onClick={(e) => e.stopPropagation()}>
					{[group, ...complexCols].map(col => {
						let icon;
						try {
							icon = require(`../../../img/icon-${col}.svg`).default;
						} catch(e) {}

						if (icon) {
							return (
								<img src={icon} alt={`${group} icon`} key={col} />
							)
						} else {
							return col;
						}
					})}
				</div>
			);
			if (items[group].length) {
				nodes.push(headerNode);
			}
			sortFn(items[group]).forEach(itemArr => {
				let isChosen = false;
				if (itemArr[0] === chosenItem) {
					if (hideChosenItem) {
						return;
					}
					isChosen = true;
				}
				const itemNode = (
					<button
						key={itemArr[0]}
						className={cx("Selector__item", "icon-container", isChosen && "chosen")}
						onClick={() => {
							if (isChosen) {
								return;
							}
							onSelect(itemArr[0], group);
						}}
					>
						{chosenItemIcon && (
							<img
								src={
									itemArr[0] &&
										require(`../../../img/icon-${itemArr[0]}.svg`).default
								}
								alt={`${itemArr[0]} icon`}
							/>
						)}
						{itemArr.map((item, i) => {
							const itemName = i === 0 ? formatFn(item) : item.toString();
							return (
								<span key={item}>{itemName}</span>
							)
						})}
						{renderOnHover && (
							<div className="Selector__hover-tip">
								{renderOnHover(itemArr[0])}
							</div>
						)}
					</button>
				);
				nodes.push(itemNode);
			})
		})
		if (!nodes.length) {
			return emptyNote;
		}
		return nodes.map(node => node);
	}
	return (
		<div className={cls}>
			<button
				className={cx("Selector__chosen-item", !chosenItem && "default-text")}
				onClick={openSelector}
			>
				{titleIcon && (
					<div className="Selector__icon">
						{titleIcon}
					</div>
				)}
				<div className="Selector__chosen-item-info icon-container">
					<div className="icon-container">
						{chosenItemIcon && (
							<img
								src={chosenItemIcon}
								alt={`${chosenItem} icon`}
							/>
						)}
						<span>
							{isComplex && (chosenItem ? renderChosenItem() : defaultText)}
							{!isComplex && (chosenItemLabel ?? defaultText)}
						</span>
					</div>
					<img className="icon-down" src={
						require(`../../../img/icon-down-small-white.svg`).default
					} alt="chevron down" />
				</div>
			</button>
			<div className="Selector__items">
				{(!items || items.length === 0) && (
					emptyNote
				)}
				{isComplex
					? renderComplexListFn()
					: sortFn(items).map(item => {
							let isChosen = false;
							if (item === chosenItem) {
								if (hideChosenItem) {
									return;
								}
								isChosen = true;
							}
							const itemName = formatFn(item);
							return (
								<button
									key={item}
									className={cx("Selector__item", "icon-container", isChosen && "chosen")}
									onClick={() => {
										if (isChosen) {
											return;
										}
										onSelect(item);
										setTimeout(closeSelector, 30);
									}}
								>
									{chosenItemIcon && (
										<img
											src={
												item &&
													require(`../../../img/icon-${item}.svg`).default
											}
											alt={`${item} icon`}
										/>
									)}
									<span>{itemName}</span>
									{renderOnHover && (
										<div className="Selector__hover-tip">
											{renderOnHover(item)}
										</div>
									)}
								</button>
							)
						})}
				{footerNode}
			</div>
		</div>
	);
};

export default Selector;