import { useState } from 'react';

export const useTogglePopup = (disabled) => {
	const [isOpen, setIsOpen] = useState(false);

	function openPopup(e) {
		if (disabled) {
			return;
		}
		if (isOpen) {
			closePopup();
			return;
		}
		
		e.stopPropagation();
		document.addEventListener('click', closePopup);
		setIsOpen(true);
	}
	function closePopup() {
		document.removeEventListener('click', closePopup);
		setIsOpen(false);
	}

	return {
		isOpen,
		openPopup,
		closePopup,
	};
}