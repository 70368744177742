import Lyra from '@lyrafinance/lyra-js';
import { ethers } from 'ethers';

export const alchemyProvider = new ethers.providers.AlchemyProvider("arbitrum", 'tz_aYA5g8dBegbWo1l6TQEY7cKhGZCh1');

export const mmProvider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : undefined;

export const signer = mmProvider?.getSigner();

export const getLyra = () => {
	return new Lyra({provider: alchemyProvider});
}