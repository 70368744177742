import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import BuilderPositionsItem from './BuilderPositionsItem';
import { builderStrategies } from '../builderConstants';
import { OPTIONS_DECIMALS } from '../../../../components/utils/constants';
import { formatAmount, formatAmountFree } from '../../../gmx-test/lib/legacy';
import { getPnlStats } from '../../../../components/utils/utils';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import useSort from '../../../../hooks/useSort';
import Currency from '../../../../components/Currency';
import Tooltip from '../../../gmx-test/components/Tooltip/Tooltip';

const BuilderPositionsComplexItem = (props) => {
	const {
		complexPosition,
		closePosition,
		pendingPositions,
		openSLTP,
		openCollateralModal,
		isLarge,
	} = props;

	const isComplex = complexPosition.elementsDraft?.length > 1;
	const token = complexPosition.elements[0].token;
	const [isFolded, setIsFolded] = useState(true);

	const [sortElements, sortedElements] = useSort(complexPosition?.elements);
	useEffect(() => {
		if (complexPosition.elements) {
			sortElements("premium", 1, true);
		}
	}, [complexPosition]);

	const renderSimplePosition = (pos, isFoldable, cls) => {
		return (
			<BuilderPositionsItem
				key={pos.strategy}
				position={pos}
				closePosition={closePosition}
				isLarge={isLarge}
				pendingPositions={pendingPositions}
				openSLTP={openSLTP}
				openCollateralModal={openCollateralModal}
				isComplex={isComplex}
				isFolded={isFoldable ? isFolded : undefined}
				className={cls}
			/>
		)
	}
	const renderLargeItem = () => {
		if (isComplex) {
			const { amount, premium, pnl, collaterals } = complexPosition;
			if (!amount) {
				return;
			}
			const premiumSign = premium.lt(0) ? "–" : "+";
			const { delta, deltaStr, hasPositionProfit } = getPnlStats(pnl);

			return (
				<>
					<tr className={cx('BuilderPositions__item-header', "clickable", !isFolded && 'active')}
						onClick={() => {
							setIsFolded(!isFolded);
						}}
					>
						<td>
							<span style={{
								color: builderStrategies[complexPosition.complexStrategy]?.color,
							}} className='BuilderPositions__item-header-strategy'>
								{complexPosition.complexStrategy}
								<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
									<path d="M2.60001 4.76667L7.10001 8.76667L11.6 4.76667" stroke={builderStrategies[complexPosition.complexStrategy]?.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</span>
						</td>
						<td>
							{/* Amount */}
							<Currency currencyName={token}>
								{formatAmountFree(amount, OPTIONS_DECIMALS, 3)}
							</Currency>
						</td>
						<td>
							{/* Strike */}
							<span className='muted'>
								Click to see strikes
							</span>
						</td>
						<td>
							{/* Liq price */}
							—
						</td>
						<td>
							{/* Premium */}
							<span className={cx({
								negative: premium.lt(0),
								positive: premium.gt(0),
							})}>
								{premiumSign}${formatAmount(
									premium.abs(),
									OPTIONS_DECIMALS,
									2,
									true
								)}
							</span>
						</td>
						<td style={{display: "flex", flexDirection: "column", rowGap: 5}}>
							{/* Collateral */}
							{collaterals.map((sum, i) => {
								const isBase = i === 0;
								if (sum.eq(0)) {
									if (isBase) {
										return;
									}
									if (!collaterals[0].eq(0)) {
										return;
									}
									return "—";
								}
								return (
									<Currency currencyName={isBase ? `W${token}` : "USDC"}>
										{formatAmountFree(sum, OPTIONS_DECIMALS, 3)}
									</Currency>
								)
							})}
						</td>
						<td>
							{/* PnL */}
							{delta ? (
								<div
									className={cx({
										positive: hasPositionProfit,
										negative: !hasPositionProfit,
										muted: delta.eq(0),
									})}
								>
									{deltaStr}
								</div>
							) : deltaStr}
						</td>
						<td>
							{/* Exp date */}
							—
						</td>
						<td>
							{/* TP */}
							—
						</td>
						<td>
							{/* SL */}
							—
						</td>
						<td>
							{/* Close btn */}
						</td>
					</tr>
					{sortedElements.map((pos, i) => {
						let cls;
						if (i === 0) {
							cls = "first";
						} else if (i === sortedElements.length - 1) {
							cls = "last";
						}
						return renderSimplePosition(pos, true, cls);
					})}
				</>
				
			)
		} else {
			return (
				<>
					{sortedElements.map(pos => {
						return renderSimplePosition(pos);
					})}
				</>
			)
		}
		
	}
	const renderSmallItem = () => {
		if (isComplex) {
			const { amount, premium, pnl, collaterals } = complexPosition;
			if (!amount) {
				return;
			}
			const premiumSign = premium.lt(0) ? "–" : "+";
			const { delta, deltaStr, hasPositionProfit } = getPnlStats(pnl);

			return (
				<div className="BuilderPositions__app-card-container App-card App-card-container">
					<div className="App-card-content">
						<div className="App-card-row">
							<div className="label">
								Strategy
							</div>
							<div style={{
								color: builderStrategies[complexPosition.complexStrategy].color,
							}}>
								{complexPosition.complexStrategy}
							</div>
						</div>
						<div className="App-card-row">
							<div className="label">
								Amount
							</div>
							<div>
								<Currency currencyName={token}>
									{formatAmountFree(amount, OPTIONS_DECIMALS, 3)}
								</Currency>
							</div>
						</div>
						<div className="App-card-row">
							<div className="label">
								<Tooltip
									className="has-hint-tooltip nowrap"
									handle="Premium"
									position="left-bottom"
									enabled={true}
									renderContent={() => {
										return (
											<div className="Tooltip__text">
												<h6>Negative Premium</h6>
												<div style={{margin: "3px 0 5px"}}>Indicates the amount of USDC you <br />have paid as an option buyer.</div>
												<h6>Positive Premium</h6>
												<div style={{margin: "3px 0"}}>Denotes the amount of USDC you <br />have received as an option seller.</div>
											</div>
										);
									}}
								/>
							</div>
							<div>
								<span className={cx({
									negative: premium.lt(0),
									positive: premium.gt(0),
								})}>
									{premiumSign}${formatAmount(
										premium.abs(),
										OPTIONS_DECIMALS,
										2,
										true
									)}
								</span>
							</div>
						</div>
						{!collaterals[0].eq(0) && !collaterals[1].eq(0) && (
							<div className="App-card-row">
								<div className="label">
									<Tooltip
										className="has-hint-tooltip nowrap"
										handle="Collateral"
										position="left-bottom"
										enabled={true}
										renderContent={() => {
											return (
												<div className="Tooltip__text">
													This refers to the amount <br />you’re setting aside <br />as collateral for selling options
												</div>
											);
										}}
									/>
								</div>
								<div style={{display: "flex", flexDirection: "column", rowGap: 5}}>
									{/* Collateral */}
									{collaterals.map((sum, i) => {
										const isBase = i === 0;
										if (sum.eq(0)) {
											if (isBase) {
												return;
											}
											if (!collaterals[0].eq(0)) {
												return;
											}
											return "—";
										}
										return (
											<Currency currencyName={isBase ? `W${token}` : "USDC"}>
												{formatAmountFree(sum, OPTIONS_DECIMALS, 3)}
											</Currency>
										)
									})}
								</div>
							</div>
						)}
						<div className="App-card-row">
							<div className="label">
								PnL
							</div>
							<div>
								{delta ? (
									<div
										className={cx({
											positive: hasPositionProfit,
											negative: !hasPositionProfit,
											muted: delta.eq(0),
										})}
									>
										{deltaStr}
									</div>
								) : deltaStr}
							</div>
						</div>
						<div className="Accordion-container">
							<div className="divider" />
							<Accordion
								className='Accordion_strategy'
								transition
								transitionTimeout={250}
							>
								<AccordionItem
									style={{padding: 0}}
									header={({state}) => `${state.isEnter ? "Hide" : "Show"} full strategy`}
								>
									{complexPosition.elements.map(el => {
										return renderSimplePosition(el, false)
									})}
								</AccordionItem>
							</Accordion>
						</div>
						
					</div>
				</div>
			)
		} else {
			return (
				<>
					{complexPosition.elements.map(pos => {
						return renderSimplePosition(pos);
					})}
				</>
			)
		}
	}

	return (
		<>
			{isLarge
				? renderLargeItem()
				: renderSmallItem()}
		</>
	);
};

export default BuilderPositionsComplexItem;