import { useState, memo, useEffect } from "react";
import BuilderPositionsItem from './BuilderPositionsItem';
import SLTPModalOptions from './../../../../components/UI/modal/SLTPModalOptions';
import { getMarkPrice } from "../../../../components/utils/utils";
import { useWeb3React } from "@web3-react/core";
import BuilderPositionsComplexItem from "./BuilderPositionsComplexItem";
import { Accordion } from '@szhsin/react-accordion';
import ClosePositionModal from "../../../options/components/Exchange/ClosePositionModal";
import Tooltip from "../../../gmx-test/components/Tooltip/Tooltip";
import useSort from './../../../../hooks/useSort';
import CollateralModal from "../../../../components/UI/modal/CollateralModal";

const BuilderPositionsList = memo((props) => {
  const {
    positions,
    infoTokens,
    positionsLoading,
    positionsError,
    pendingPositions,
    setPendingPositions,
    library,
    SLTPCommission,
    triggerPositionsFetch,
  } = props;

  const hasPositions = Boolean(positions && positions.length);

  const [closeModalVisible, setCloseModalVisible] = useState(false);
  const [SLTPVisible, setSLTPVisible] = useState(false);
  const [isTP, setIsTP] = useState(false);
  const [collateralVisible, setCollateralVisible] = useState(false);
  const [position, setPosition] = useState(null);

  const markPrices = {
    ETH: getMarkPrice(infoTokens, "ETH"),
    BTC: getMarkPrice(infoTokens, "BTC"),
  }
  const openSLTP = (position, isTP, setIsSLTPSpinning) => {
    setPosition({
      ...position,
      setIsSLTPSpinning,
    });
    setIsTP(isTP);
    setSLTPVisible(true);
  }
  const closePosition = (position) => {
    setPosition(position);
    setCloseModalVisible(true);
  }
  const openCollateralModal = (position, setIsCollateralSubmitting) => {
    setPosition({
      ...position,
      setIsCollateralSubmitting,
    });
    setCollateralVisible(true);
  }

  const { active } = useWeb3React();
  const getStatusLabel = () => {
    if (!active) {
      return `No wallet connected`
    }
    if (!hasPositions || positionsLoading) {
      if (positionsLoading) {
        return `Loading...`;
      }
      if (positionsError) {
        return `Loading failed, retrying...`;
      }
      return `No open positions`;
    }
  }

  const [sortPositions, sortedPositions] = useSort(positions);
  useEffect(() => {
    if (hasPositions) {
      sortPositions('premium', -1, true);
    }
  }, [positions]);

  
  const renderPositionItem = (position, isLarge) => {
    return (
      <BuilderPositionsComplexItem
        key={`${position.buildID ?? position.elements?.[0]?.strategy}`}
        complexPosition={position}
        markPrices={markPrices}
        closePosition={closePosition}
        isLarge={isLarge}
        pendingPositions={pendingPositions}
        openSLTP={openSLTP}
        openCollateralModal={openCollateralModal}
      />
    )
  }

  return (
    <div className="BuilderPositionsList table-wrapper">
      <div className="Exchange-list small">
        <div>
          {(!hasPositions || positionsLoading) && (
            <div className="App-card Exchange-list-empty-note">
              {getStatusLabel()}
            </div>
          )}
          {hasPositions && sortedPositions.map((position) => {
            return renderPositionItem(position, false);
          })}
        </div>
      </div>
      <table className="Exchange-list large App-box">
        <tbody>
          {hasPositions && (
            <tr className="Exchange-list-header">
              <th>
                Strategy
              </th>
              <th>
                Amount
              </th>
              <th>
                Strike
              </th>
              <th>
                Liq. Price
              </th>
              <th>
              <Tooltip
                className="has-hint-tooltip nowrap"
                handle="Premium"
                position="left-bottom"
                enabled={true}
                renderContent={() => {
                  return (
                    <div className="Tooltip__text">
                      <h6>Negative Premium</h6>
                      <div style={{margin: "3px 0 5px"}}>Indicates the amount of USDC you <br />have paid as an option buyer.</div>
                      <h6>Positive Premium</h6>
                      <div style={{margin: "3px 0"}}>Denotes the amount of USDC you <br />have received as an option seller.</div>
                    </div>
                  );
                }}
              />
              </th>
              <th>
                <Tooltip
                  className="has-hint-tooltip nowrap"
                  handle="Collateral"
                  position="left-bottom"
                  enabled={true}
                  renderContent={() => {
                    return (
                      <div className="Tooltip__text">
                        This refers to the amount <br />you’re setting aside <br />as collateral for selling options
                      </div>
                    );
                  }}
                />
              </th>
              <th>
                PnL
              </th>
              <th>
                Expiration Date
              </th>
              <th>
                Take Profit
              </th>
              <th>
                Stop Loss
              </th>
              <th></th>
            </tr>
          )}
          {(!hasPositions || positionsLoading) && (
            <tr>
              <td colSpan="15" className="Exchange-list-empty-note">
                {getStatusLabel()}
              </td>
            </tr>
          )}
          {hasPositions && sortedPositions.map((position) => {
            return renderPositionItem(position, true);
          })}
        </tbody>
      </table>
      
      <ClosePositionModal
        visible={closeModalVisible}
        setVisible={setCloseModalVisible}
        position={position}
        setPosition={setPosition}
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positions={positions}
      />
      <SLTPModalOptions
        visible={SLTPVisible}
        setVisible={setSLTPVisible}
        position={position}
        setPosition={setPosition}
        isTP={isTP}
        SLTPCommission={SLTPCommission}
        markPrices={markPrices}
        library={library}
        triggerPositionsFetch={triggerPositionsFetch}
      />
      <CollateralModal
        visible={collateralVisible}
        setVisible={setCollateralVisible}
        position={position}
        setPosition={setPosition}
        library={library}
        triggerPositionsFetch={triggerPositionsFetch}
      />
    </div>
  );
})

export default BuilderPositionsList;