import React, { useState, useEffect } from 'react';
import { SL, TP, formatAmount, formatAmountFree } from '../../../../components/utils/legacy';
import { ImSpinner2 } from 'react-icons/im';
import cx from "classnames";
import { DATE_FORMAT_TEMPLATE, OPTIONS_DECIMALS } from '../../../../components/utils/constants';
import { fromBigNumber, getPnlStats } from '../../../../components/utils/utils';
import { format } from 'date-fns';
import icon_edit from '../../../../img/icon-edit.svg';
import Tooltip from './../../../gmx-test/components/Tooltip/Tooltip';
import Currency from '../../../../components/Currency';
import Spinner from '../../../../components/UI/loader/Spinner';
import { builderStrategies } from '../builderConstants';
import Strike from './../../../../components/Strike';

const BuilderPositionsItem = (props) => {
	const {
		className,
		position,
		closePosition,
		isLarge,
		pendingPositions,
		openSLTP,
		isFolded,
		isComplex,
		openCollateralModal,
	} = props;

	const [isTpSubmitting, setIsTpSubmitting] = useState(false);
	const [isSlSubmitting, setIsSlSubmitting] = useState(false);
	const [isCollateralSubmitting, setIsCollateralSubmitting] = useState(false);
	useEffect(() => {
		setIsTpSubmitting(false);
		setIsSlSubmitting(false);
		setIsCollateralSubmitting(false);
	}, [position]);
	const hasTP = (position.tp && !position.tp.eq(0)) || isTpSubmitting;
	const hasSL = (position.sl && !position.sl.eq(0)) || isSlSubmitting;
	const getTpTooltipText = () => {
		if (hasTP || hasSL) {
			return (
				<>
					To set up a {hasTP ? SL : TP}, you <br />need to first remove the <br />active {hasTP ? TP : SL} order
				</>
			)
		}
	}
	const getIsTooltipEnabled = (isTP) => {
		if (isTP ? hasSL : hasTP) {
			return true;
		}
	}

	const { delta, deltaStr, hasPositionProfit } = getPnlStats(position.pnl);

	const isPending = position.isPending || pendingPositions.find(strat => position.strategy === strat);
	const closeBtnDisabled = position.size.eq(0) || position.isClosingDisabled || isPending || hasTP || hasSL;

	const isSLTPDisabled = isPending || !position.isBuy;

	const closeButton = (
		<Tooltip
			className="Tooltip_disabled"
			handle={
				<>
					<button
						className="btn_inline"
						style={{marginLeft: "auto"}}
						onClick={() => {
							closePosition(position);
						}}
						disabled={closeBtnDisabled}
					>
						Close
					</button>
				</>
			}
			position="right-bottom"
			enabled={hasSL || hasTP}
			renderContent={() => {
				return (
					<div className="Tooltip__text">
						To close the position <br />you need to remove <br />active Stop Orders
					</div>
				);
			}}
		/>
		
	)
	const TPButton = (
		<>
			{isTpSubmitting
				? <Spinner />
				: <>
						{hasTP && `$${formatAmount(position.tp, OPTIONS_DECIMALS, 2, true)}`}
						<Tooltip
							className="Tooltip_take-profit Tooltip_disabled"
							handle={
								<>
									<button
										className="btn_inline"
										onClick={() => {
											openSLTP(position, true, setIsTpSubmitting);
										}}
										disabled={isSLTPDisabled || hasSL}
									>
										{hasTP
											? <img className='edit-btn' src={icon_edit} alt="Edit Take Profit button" />
											: 'Set TP'}
									</button>
								</>
								
							}
							position="left-bottom"
							enabled={getIsTooltipEnabled(true)}
							renderContent={() => {
								return (
									<div className="Tooltip__text">
										{getTpTooltipText()}
									</div>
								);
							}}
						/>
					</>}
		</>
	)
	const SLButton = (
		<>
			{isSlSubmitting
				? <Spinner />
				: <>
						{hasSL && `$${formatAmount(position.sl, OPTIONS_DECIMALS, 2, true)}`}
						<Tooltip
							className="Tooltip_take-profit Tooltip_disabled"
							handle={
								<button
									className="btn_inline"
									onClick={() => {
										openSLTP(position, false, setIsSlSubmitting);
									}}
									disabled={isSLTPDisabled || hasTP || position.isClosingDisabled}
								>
									{hasSL
										? <img src={icon_edit} className='edit-btn' alt="Edit Stop Loss button" />
										: 'Set SL'}
								</button>
							}
							position="left-bottom"
							enabled={getIsTooltipEnabled(false)}
							renderContent={() => {
								return (
									<div className="Tooltip__text">
										{getTpTooltipText()}
									</div>
								);
							}}
						/>
					</>}
		</>
	)
	const collateralButton = (
		<button
			className="btn_inline"
			onClick={() => {
				openCollateralModal(position, setIsCollateralSubmitting);
			}}
		>
			<img className='edit-btn' src={icon_edit} alt="Edit Take Profit button" />
		</button>
	)

	let strategy = "";
	if (!isComplex) {
		strategy+= position.isBuy ? "Long" : "Short";
		strategy+= position.isCall ? " Call" : " Put"
	}
	return (
		<>
			{isLarge ?
				<tr className={cx(className, isPending && 'positions-item_pending', isFolded && "folded", (typeof(isFolded) !== "undefined") && "foldable")}>
					<td>
						{!isComplex && (
							<>
								{isPending && <Spinner style={{marginRight: 7}} />}
								<span style={{ color: builderStrategies[strategy].color }}>
									{strategy}
								</span>
							</>
						)}
						{isComplex && isPending && <Spinner style={{marginLeft: "calc(100% - 12px)"}} />}
					</td>
					<td>
						{/* Amount */}
						{isComplex ? (
							<>{formatAmountFree(position.size, OPTIONS_DECIMALS, 3)}</>
						) : <Currency currencyName={position.token}>
									{formatAmountFree(position.size, OPTIONS_DECIMALS, 3)}
								</Currency>}
					</td>
					<td>
						{/* Strike */}
						<Strike position={position} />
					</td>
					<td>
						{/* Liq. Price */}
						{position.isBuy ? "—"
							: (
								position.collateral.liquidationPrice ?
								`$${formatAmount(position.collateral.liquidationPrice, OPTIONS_DECIMALS, 0, true)}`
								: "—"
							)
						}
					</td>
					<td>
						<div>
							<Currency>
								{position.isBuy ? "–" : "+"}{formatAmount(position.premium.abs(), OPTIONS_DECIMALS, 2, true)}
							</Currency>
						</div>
					</td>
					<td>
						{/* Collateral */}
						{position.isBuy ? "—"
							: isCollateralSubmitting
								? <Spinner />
								: <div className='edit-btn-container'>
										<Currency currencyName={position.collateral.isBase ? `W${position.token}` : "USDC"}>
											{position.collateral.isBase
												? formatAmountFree(position.collateral.amount, OPTIONS_DECIMALS, 3)
												: formatAmount(position.collateral.amount, OPTIONS_DECIMALS, 0, true)
											}
										</Currency>
										{!position.collateral.isFixed && collateralButton}
									</div>
						}
					</td>
					<td>
						<div style={{ width: 150, display: 'block' }}>
							{delta ? (
								<div
									className={cx("Exchange-list-info-label", {
										positive: hasPositionProfit,
										negative: !hasPositionProfit,
										muted: delta.eq(0),
									})}
								>
									{deltaStr}
								</div>
							) : deltaStr}
						</div>
					</td>
					<td>
						{format(position.expiry, DATE_FORMAT_TEMPLATE)}
					</td>
					<td>
						{TPButton}
					</td>
					<td>
						{SLButton}
					</td>
					<td className="td-btn pos-relative" style={{ width: 110, textAlign: 'center' }}>
						{closeButton}
					</td>
				</tr>
			:
			<div className={cx("App-card", isPending && 'positions-item_pending')}>
				<div className="App-card-content">
					{!isComplex && (
						<div className="App-card-row">
							<div className="label">
								Strategy
							</div>
							<div>
								<span style={{ color: builderStrategies[strategy].color }}>
									{strategy}
								</span>
								{isPending && <Spinner hasMargin={true} />}
							</div>
						</div>
					)}
					{isComplex && isPending && (
						<div className="App-card-row">
							<div className="label"></div>
							<div>
								<Spinner />
							</div>
						</div>
					)}
					<div className="App-card-row">
						<div className="label">
							Amount
						</div>
						<div>
							<Currency currencyName={position.token}>
								{formatAmountFree(position.size, OPTIONS_DECIMALS, 3)}
							</Currency>
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Strike
						</div>
						<div>
							<Strike position={position} />
						</div>
					</div>
					{!position.isBuy && (
						<div className="App-card-row">
							<div className="label">
								Liq. Price
							</div>
							<div>
								{position.collateral.liquidationPrice
									? `$${formatAmount(position.collateral.liquidationPrice, OPTIONS_DECIMALS, 0, true)}`
									: "—"}
							</div>
						</div>
					)}
					
					<div className="App-card-row">
						<div className="label">
							<Tooltip
                className="has-hint-tooltip nowrap"
                handle="Premium"
                position="left-bottom"
                enabled={true}
                renderContent={() => {
                  return (
                    <div className="Tooltip__text">
                      <h6>Negative Premium</h6>
                      <div style={{margin: "3px 0 5px"}}>Indicates the amount of USDC you <br />have paid as an option buyer.</div>
                      <h6>Positive Premium</h6>
                      <div style={{margin: "3px 0"}}>Denotes the amount of USDC you <br />have received as an option seller.</div>
                    </div>
                  );
                }}
              />
						</div>
						<Currency>
							{position.isBuy ? "–" : "+"}{formatAmount(position.premium.abs(), OPTIONS_DECIMALS, 2, true)}
						</Currency>
					</div>
					{!position.isBuy && (
						isCollateralSubmitting
						? <Spinner />
						: <div className="App-card-row">
								<div className="label">
									<Tooltip
										className="has-hint-tooltip nowrap"
										handle="Collateral"
										position="left-bottom"
										enabled={true}
										renderContent={() => {
											return (
												<div className="Tooltip__text">
													This refers to the amount <br />you’re setting aside <br />as collateral for selling options
												</div>
											);
										}}
									/>
								</div>
								<div className='edit-btn-container'>
									<Currency currencyName={position.collateral.isBase ? `W${position.token}` : "USDC"}>
										{position.collateral.isBase
											? formatAmountFree(position.collateral.amount, OPTIONS_DECIMALS, 3)
											: formatAmount(position.collateral.amount, OPTIONS_DECIMALS, 0, true)
										}
									</Currency>
									{!position.collateral.isFixed && collateralButton}
								</div>
							</div>
					)}
					<div className="App-card-row">
						<div className="label">
							PNL (ROE %)
						</div>
						<div>
							{delta ? (
								<div
									className={cx("Exchange-list-info-label", {
										positive: hasPositionProfit,
										negative: !hasPositionProfit,
										muted: delta.eq(0),
									})}
								>
									{deltaStr}
								</div>
							) : deltaStr}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Expiration Date
						</div>
						<div>
							{format(position.expiry, 'MM/dd/yy, hh:mm a')}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Take Profit
						</div>
						<div>
							{TPButton}
						</div>
					</div>
					<div className="App-card-row">
						<div className="label">
							Stop Loss
						</div>
						<div>
							{SLButton}
						</div>
					</div>
				</div>
				<div className="App-card-divider"></div>
				<div className="App-card-options">
					<div className="App-button-option pos-relative">
						{closeButton}
					</div>
				</div>
			</div>}
		</>
	);
};

export default BuilderPositionsItem;