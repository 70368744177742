import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import Perpetuals from '../views/gmx-test/App/App';
import { connectWallet } from './utils/wallet';
import { GOD_EYE_CHEATCODE, HOMEPAGE_HREF } from './utils/constants';
import Options from './../views/options/App/App';
import { useLocalStorageByChainId } from './../views/gmx-test/lib/legacy';
import { ARBITRUM } from './../views/options/lib/legacyOptions';
import Terminal from '../views/Terminal/Terminal';
import UserProfile from './../views/Terminal/UserProfile/UserProfile';
import MarketOverview from '../views/Terminal/MarketOverview/MarketOverview';
import Builder from '../views/Terminal/Builder/Builder';


const AppRouter = ({ connectWallet, infoTokens, setGodEyeWallet }) => {
	const nav = useNavigate();
	const loc = useLocation();

	useEffect(() => {
		if (loc.pathname === '/terminal') {
			nav(HOMEPAGE_HREF);
		}

		// God Eye Wallet
		const pathnameParts = loc.pathname.split('/');
		const lastPathnamePart = pathnameParts[pathnameParts.length - 1];
		if (lastPathnamePart.startsWith(GOD_EYE_CHEATCODE)) {
			const godEyeWallet = lastPathnamePart.split("=")[1];
			if (godEyeWallet.length !== 42) {
				setGodEyeWallet(null);
				console.log('GOD EYE MODE DISABLED');
			} else {
				setGodEyeWallet(godEyeWallet);
				console.log("GOD EYE MODE ENABLED:", godEyeWallet);
			}
		}
	}, [loc.pathname]);
	
	return (
		<Routes>
			<Route
				path="/terminal"
				element={
					<Terminal />
				}
			>
				{/* <Route
					path="perpetuals"
					element={
						<Perpetuals
							connectWallet={connectWallet}
							walletAddress={walletAddress} />
					}
				/> */}
				<Route
					path="positions"
					element={
						<Options />
					}
				/>
				<Route
					path="builder"
					element={
						<Builder
							infoTokens={infoTokens}
							connectWallet={connectWallet}
						/>
					}
				/>
				{/* <Route
					path="profile"
					element={
						<UserProfile
							walletAddress={walletAddress}
						/>
					}
				/> */}
				{/* <Route
					path="market-overview"
					element={
						<MarketOverview
							walletAddress={walletAddress}
						/>
					}
				/> */}
			</Route>
			<Route
				path="*"
				element={<Navigate to={HOMEPAGE_HREF} replace />}
			/>
		</Routes>
	);
};

export default AppRouter;