import { useState, memo } from "react";

import {
  helperToast,
  CALL,
  PUT,
} from "../../lib/legacyOptions";
import PositionsItem from './PositionsItemOptions';
import ClosePositionModal from './ClosePositionModal';
import SLTPModalOptions from './../../../../components/UI/modal/SLTPModalOptions';
import { getMarkPrice } from "../../../../components/utils/utils";
import { useWeb3React } from "@web3-react/core";

const PositionsList = memo((props) => {
  let {
    positions,
  } = props;
  const {
    infoTokens,
    setMarket,
    positionsLoading,
    positionsError,
    pendingPositions,
    setPendingPositions,
    library,
    SLTPCommission,
  } = props;

  /* const buyingPositions = pendingPositions.filter(pos => typeof pos === 'object');
  if (positions) {
    positions = [...buyingPositions, ...positions];
  } */
  
  const hasPositions = Boolean(positions && positions.length);

  const [closeModalVisible, setCloseModalVisible] = useState(false);
  const [SLTPVisible, setSLTPVisible] = useState(false);
  const [isTP, setIsTP] = useState(false);
  const [position, setPosition] = useState(null);

  const markPrices = {
    ETH: getMarkPrice(infoTokens, "ETH"),
    BTC: getMarkPrice(infoTokens, "BTC"),
  }
  const openSLTP = (position, isTP, setIsSLTPSpinning) => {
    setPosition({
      ...position,
      setIsSLTPSpinning,
    });
    setIsTP(isTP);
    setSLTPVisible(true);
  }
  const closePosition = (position) => {
    setPosition(position);
    setCloseModalVisible(true);
  }
  
  const onPositionClick = (position) => {
    helperToast.success(`${position.isCall ? "Call" : "Put"} ${position.token} market selected`);
    setMarket(position.isCall ? CALL : PUT, position.token);
  };

  const { active } = useWeb3React();
  const getStatusLabel = () => {
    if (!active) {
      return `No wallet connected`
    }
    if (!hasPositions || positionsLoading) {
      if (positionsLoading) {
        return `Loading...`;
      }
      if (positionsError) {
        return `Loading failed, retrying...`;
      }
      return `No open positions`;
    }
  }
  
  const renderPositionItem = (position, isLarge) => {
    return (
      <PositionsItem
        key={position.strategy}
        position={position}
        onPositionClick={onPositionClick}
        markPrices={markPrices}
        closePosition={closePosition}
        isLarge={isLarge}
        pendingPositions={pendingPositions}
        openSLTP={openSLTP}
      />
    )
  }

  return (
    <div className="PositionsList table-wrapper">
      <div className="Exchange-list small">
        <div>
          {(!hasPositions || positionsLoading) && (
            <div className="App-card Exchange-list-empty-note">
              {getStatusLabel()}
            </div>
          )}
          {hasPositions && positions.map((position) => {
            return renderPositionItem(position, false);
          })}
        </div>
      </div>
      <table className="Exchange-list large App-box">
        <tbody>
          {hasPositions && (
            <tr className="Exchange-list-header">
              <th>
                Position
              </th>
              <th>
                Amount
              </th>
              <th>
                Strike
              </th>
              <th>
                Liq. Price
              </th>
              <th>
                Premium Paid
              </th>
              <th>
                PNL (ROE %)
              </th>
              <th>
                Expiration Date
              </th>
              <th>
                Take Profit
              </th>
              <th>
                Stop Loss
              </th>
              <th></th>
            </tr>
          )}
          {(!hasPositions || positionsLoading) && (
            <tr>
              <td colSpan="15" className="Exchange-list-empty-note">
                {getStatusLabel()}
              </td>
            </tr>
          )}
          {hasPositions && positions.map((position) => {
            return renderPositionItem(position, true);
          })}
        </tbody>
      </table>

      <ClosePositionModal
        visible={closeModalVisible}
        setVisible={setCloseModalVisible}
        position={position}
        setPosition={setPosition}
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positions={positions}
      />
      <SLTPModalOptions
        visible={SLTPVisible}
        setVisible={setSLTPVisible}
        position={position}
        setPosition={setPosition}
        isTP={isTP}
        SLTPCommission={SLTPCommission}
        markPrices={markPrices}
        library={library}
      />
    </div>
  );
})

export default PositionsList;