import React, { useEffect, useState } from 'react';
import { CHAI_CONTRACT_BTC, CHAI_CONTRACT_ETH, Chai_ABI, HEGIC_SENTIMENT_LABELS, HEGIC_STRATEGIES, HEGOPS, HEGOPS_ABI, HegicStrategy_ABI, OperationalTreasury, OperationalTreasury_ABI, builderOpenContract, getTPApproveContract, getTPContract } from '../../../../components/utils/contracts';
import { BigNumber, ethers } from 'ethers';
import { builderStrategies } from '../builderConstants';
import { ARBITRUM, expandDecimals } from '../../../gmx-test/lib/legacy';
import { DDL_WALLET, DDL_WALLET_ENCODED, OPTIONS_DECIMALS, OPTION_TYPES, tokenDecimals } from '../../../../components/utils/constants';
import { CALL, PUT } from '../../../options/lib/legacyOptions';
import { errAlert } from '../../../../components/utils/notifications';
import { alchemyProvider, getLyra } from '../../../../components/utils/providers';
import useSWR from 'swr';
import './BuilderPositions.scss';
import MarkPricePanel from '../../../../components/MarkPricePanel';
import BuilderListSection from './BuilderListSection';
import useGodEye from '../../../../hooks/useGodEye';
import { getSoftMaxCollateral, numToBn } from '../../../../components/utils/utils';

const BuilderPositions = (props) => {
	const {
    infoTokens,
    fetchPositionsTrigger,
    triggerPositionsFetch,
	} = props;

	const { active, library, account } = useGodEye();

	// Fetching positions
	const [positions, setPositions] = useState([]);
  const [pendingPositions, setPendingPositions] = useState([]);
	useEffect(() => {
    setPositions([]);
    setPendingPositions([]);
  }, [account]);

	const fetchComplexPositions = async () => {
		const complexPositions = [];
		const {
			CreateBuild: createBuildFilter,
			OpenPositionByHegic: openByHegicFilter,
			OpenPositionByLyra: openByLyraFilter,
		} = builderOpenContract.filters;

		const buildEvents = (await builderOpenContract.queryFilter(createBuildFilter(null, account))).reverse();
    const eventsPack = [];
    let eventsPackLimit = 6;
    const lastPackLength = buildEvents.length % eventsPackLimit;
    for (let i = 0; i < buildEvents.length; i++) {
      const buildEvent = buildEvents[i];
      const complexStrategy = Object.keys(builderStrategies).find(strat => builderStrategies[strat].key === buildEvent.args.productType.toNumber());
			const buildID = buildEvent.args.buildID;
			
			const complexPosition = {
				complexStrategy,
				buildID: buildID.toNumber(),
				elementsDraft: [],
				elements: [],
			};
			
			const lyraEvents = builderOpenContract.queryFilter(openByLyraFilter(buildID))
        .then(events => {
          events.forEach(lyraEvent => {
            complexPosition.elementsDraft.push({
              market: "Lyra",
              id: lyraEvent.args.tokenID.toNumber(),
            })
          })
        });
			const hegicEvents = builderOpenContract.queryFilter(openByHegicFilter(buildID))
        .then(events => {
          events.forEach(hegicEvent => {
            complexPosition.elementsDraft.push({
              market: "Hegic",
              id: hegicEvent.args.tokenID.toNumber(),
            })
          })
        });
      const allEvents = Promise.all([lyraEvents, hegicEvents]).then(() => {
        complexPositions.push(complexPosition);
      })
      
      eventsPack.push(allEvents);
      if (i + 1 > (buildEvents.length - lastPackLength)) {
        eventsPackLimit = lastPackLength;
      }
      if (eventsPack.length >= eventsPackLimit) {
        await Promise.all(eventsPack);
        eventsPack.splice(0, eventsPack.length);
      }
    }
		return complexPositions;
	}
  const fetchHegicPositions = async (complexPositions, positions) => {
    let strategiesAddresses = [];
    const provider = alchemyProvider;
    const signer = library.getSigner();
    // chaiMarkPrice 1e18 / 1e8 -- ETH / BTC
    const chaiContractETH = new ethers.Contract(CHAI_CONTRACT_ETH, Chai_ABI, signer);
    const chaiContractBTC = new ethers.Contract(CHAI_CONTRACT_BTC, Chai_ABI, signer);
    const chaiMarkPrices = {
      ETH: await chaiContractETH.latestAnswer(),
      BTC: await chaiContractBTC.latestAnswer()
    };

    Object.values(HEGIC_STRATEGIES).forEach(obj => {
      strategiesAddresses = [...strategiesAddresses, ...Object.values(obj)];
    })

    const contract = new ethers.Contract(HEGOPS, HEGOPS_ABI, provider);
    const transferFilter = {
      address: contract.address,
      topics: [
        ethers.utils.id("Transfer(address,address,uint256)"),
        null,
        ethers.utils.hexZeroPad(account, 32),
      ]
    };
    const transferEvents = await contract.queryFilter(transferFilter);
    if (!transferEvents.length) {
      return;
    }
    const tokenIds = transferEvents.map(e => {
      return e.args.tokenId;
    })
    
    const buyFilterTopics = [
      ethers.utils.id("Acquired(uint256,(uint128,uint128),uint256,uint256,uint256,bytes[])"),
      tokenIds.map(id => {
        return ethers.utils.hexZeroPad(id, 32);
      }),
    ]

    const eventsPack = [];
    let eventsPackLimit = 13;
    const lastPackLength = strategiesAddresses.length % eventsPackLimit;
    for (let i = 0; i < strategiesAddresses.length; i++) {
      const address = strategiesAddresses[i];
      const contract = new ethers.Contract(address, HegicStrategy_ABI, provider);

      const buyFilter = {
        address,
        topics: buyFilterTopics,
      }
      const events = contract.queryFilter(buyFilter)
        .then(eArr => {
          eArr.forEach(e => {
            formatPosition(e);
          });
        })
      eventsPack.push(events);

      if (i + 1 > (strategiesAddresses.length - lastPackLength)) {
        eventsPackLimit = lastPackLength;
      }
      if (eventsPack.length >= eventsPackLimit) {
        await Promise.all(eventsPack);
        eventsPack.splice(0, eventsPack.length);
      }
    }
    return positions;
    async function formatPosition(e) {
      const data = e.decode(e.data, e.topics);
      const OpTreasury = new ethers.Contract(OperationalTreasury, OperationalTreasury_ABI, provider);
      const additionalData = (await OpTreasury.lockedLiquidity(data.id));
      const isActive = additionalData.state;
      if (!isActive) {
        return;
      }

      const periodDays = data.period.toString() / 60**2 / 24;
      let strategies;
      if (periodDays < 14) {
        strategies = HEGIC_STRATEGIES.short;
      } else if (periodDays < 30) {
        strategies = HEGIC_STRATEGIES.medShort;
      } else if (periodDays < 60) {
        strategies = HEGIC_STRATEGIES.medLong;
      } else if (periodDays <= 90) {
        strategies = HEGIC_STRATEGIES.long;
      } else {
        return;
      }

      const strategy = Object.keys(strategies).find(key => strategies[key] === e.address);
      const [, sentiment, strikeScale, token] = strategy.split('_');
      const isCall = sentiment.includes('CALL');
      const isSpread = sentiment.includes('SPREAD');
      if (isSpread) {
        return;
      }
      // size 1e18 / 1e8 -- ETH / BTC
      const size = expandDecimals(data.data.amount, OPTIONS_DECIMALS - tokenDecimals[token]);
      // chaiMarkPrice 1e18 / 1e8 -- ETH / BTC
      const chaiMarkPrice = expandDecimals(chaiMarkPrices[token], OPTIONS_DECIMALS - tokenDecimals[token]);
      // strike 1e8
      const buyingPrice = expandDecimals(data.data.strike, OPTIONS_DECIMALS - 8);
      const comparisonMethod = isCall ? "gt" : "lt";
      const isITM = buyingPrice[comparisonMethod](chaiMarkPrice);
      let strikePrice = isITM ? buyingPrice : buyingPrice.div(100).mul(strikeScale);
      if (isSpread) {
        strikePrice = buyingPrice;
      }
      // premium 1e6
      const premium = expandDecimals(data.positivepnl, OPTIONS_DECIMALS - 6);
      const stratContract = new ethers.Contract(additionalData.strategy, HegicStrategy_ABI, provider);
      // profit 1e6
      const profit = expandDecimals(await stratContract.payOffAmount(data.id), OPTIONS_DECIMALS - 6);
      const pnl = {
        unrealizedPnl: profit.sub(premium),
        unrealizedPnlPercentage: expandDecimals(expandDecimals(profit.sub(premium), 4).div(premium), OPTIONS_DECIMALS - 4)
      };
      // SL/TP
      const DDL_TakeProfit = getTPContract("Hegic", token, signer);
      const tpInfo = await DDL_TakeProfit.tokenIdToTokenInfo(data.id);
      let tp, sl;
      if (!tpInfo.takeProfitPrice.eq(0)) {
        // price 1e8
        const price = expandDecimals(tpInfo.takeProfitPrice, OPTIONS_DECIMALS - 8);
        if (tpInfo.takeType === 0) {
          if (isCall) {
            tp = price;
          } else {
            sl = price;
          }
        } else {
          if (isCall) {
            sl = price;
          } else {
            tp = price;
          }
        }
      }

      const expiry = additionalData.expiration * 1000;
      const approveContract = getTPApproveContract("Hegic", token, signer);

      // Two children with the same key fix
      const uniqStrategy = strategy + "_" + premium.toString();
      const fetchedPosition = {
        strategy: uniqStrategy,
        market: "Hegic",
        type: OPTION_TYPES.Hegic,
        id: data.id,
        isCall,
        isBuy: true,
        sentiment: HEGIC_SENTIMENT_LABELS[sentiment],
        token,
        size,
        strikePrice,
        premium,
        expiry,
        pnl,
        isClosingDisabled: profit.eq(0),
        tp,
        sl,
        close: () => {
          return OpTreasury.connect(signer).payOff(data.id, account);
        },
        checkIsTpApproved: () => {
          return approveContract.isApprovedOrOwner(DDL_TakeProfit.address, data.id);
        },
        approveTp: () => {
          return approveContract.approve(DDL_TakeProfit.address, data.id);
        }
      }
      const positionObj = {
				complexStrategy: isCall ? CALL : PUT,
				elements: [fetchedPosition]
			}

      // Find if position is located in a complex strategy object
      // where elIndex is index of position in elements field ( {..., elements: [-elIndex-] } )
      // and index is the index of complex strategy object ([{...}, {...}, -index-])
			let elIndex;
      const index = positions.findIndex(pos => {
				elIndex = pos.elements?.findIndex(el => el.strategy === uniqStrategy);
				return elIndex !== -1;
			});
      if (index === -1) {
        // If position is not located in a complex strategy object,
        // find draft of the complexPosition in complexPositions object
				const complexPosition = complexPositions.find(pos => {
					return !!pos.elementsDraft.find(el => el.id === data.id.toNumber());
				});
				if (complexPosition) {
          // If draft of the complexPosition is found,
          // find if the complexPosition object is added to positions
					const complexPositionIndex = positions.findIndex(pos => pos.buildID === complexPosition.buildID);
					if (complexPositionIndex === -1) {
            // If complexPosition obj is not added to positions, push it to positions
						positions.push({...complexPosition, elements: [fetchedPosition]})
					} else {
            // If complexPosition obj is added to positions, push fetchedPosition to its elements
						positions[complexPositionIndex].elements.push(fetchedPosition);
					}
				} else {
          // If draft of the complexPosition is absent,
          // it would mean that fetchedPosition is not complex (strategy === Call || Put).
          // Then push it to positions
					positions.push(positionObj);
				}
      } else {
        // If fetchedPosition is located in a complex strategy object,
        // replace it with the updated version
        positions[index].elements[elIndex] = fetchedPosition;
      }
    }
  }
  const fetchLyraPositions = async (complexPositions, positions) => {
    const LYRA = getLyra();
    const posArr = await LYRA.positions(account);
    const SLIPPAGE = 0.015;

    const formatPosition = async (pos) => {
      const { id, strikeId, strikePrice, isCall, isLong, pricePerOption, size, expiryTimestamp, collateral } = pos;
      if (size.eq(0) || expiryTimestamp * 1000 < Date.now()) {
        return;
      }
      const isBuy = isLong;

      let token = pos.marketName.split('-')[0];
      if (token.startsWith('W')) {
        token = token.substring(1, token.length);
      }

      // SL/TP
      let tp, sl;
      const DDL_TakeProfit = getTPContract("Lyra", token, library.getSigner());
      const tpInfo = await DDL_TakeProfit.tokenIdToTokenInfo(id);
      if (!tpInfo.stopOrderPrice.eq(0)) {
        // price 1e8
        const price = expandDecimals(tpInfo.stopOrderPrice, OPTIONS_DECIMALS - 8);
        if (tpInfo.stopOrderType === 0) {
          if (isCall) {
            tp = price;
          } else {
            sl = price;
          }
        } else {
          if (isCall) {
            sl = price;
          } else {
            tp = price;
          }
        }
      }

      const expiry = expiryTimestamp * 1000;
      
      const trades = pos.trades();
      const premium = trades.reduce((premium, trade) => {
        const method = trade.isBuy ? "add" : "sub";
        return premium[method](trade.premium);
      }, BigNumber.from(0));
      
      const strategy = `Lyra_${isCall ? "CALL" : "PUT"}_${strikeId.toString()}_${token}_${premium.toString()}`;
      const pnl = pos.pnl();

      // IncorrectOwner error fix
      pos.owner = account;

      const signer = library.getSigner();
      const approveContract = getTPApproveContract("Lyra", token, library.getSigner());

      if (collateral && !collateral.max) {
        collateral.max = getSoftMaxCollateral(pos, collateral, true);
      }
      if (collateral && collateral.min.eq(collateral.max)) {
        collateral.isFixed = true;
      }

      const fetchedPosition = {
        strategy,
        market: "Lyra",
        lyraPosition: pos,
        type: OPTION_TYPES.Lyra,
        isCall,
        id,
        sentiment: isCall ? CALL : PUT,
        isBuy,
        token,
        size,
        strikePrice,
        pricePerOption,
        premium,
        collateral,
        expiry,
        pnl,
        tp,
        sl,
        getTrade: async (isBuy = false) => {
          return await pos.trade(isBuy, size, SLIPPAGE);
        },
        close: async (size) => {
          const trade = await pos.close(size, SLIPPAGE);
          // add referrer
          trade.tx.data = trade.tx.data.substring(0, trade.tx.data.length - DDL_WALLET_ENCODED.length);
          trade.tx.data+= DDL_WALLET_ENCODED;
          // visually
          trade.params[0].referrer = DDL_WALLET;

          if (trade.isDisabled) {
            errAlert(new Error(trade.disabledReason));
          }
          
          return signer.sendTransaction(trade.tx);
        },
        checkIsTpApproved: () => {
          return approveContract.ownerOf(id)
            .then(res => {
              return res.toLowerCase() === DDL_TakeProfit.address.toLowerCase();
            });
        },
        approveTp: () => {
          return approveContract.approve(DDL_TakeProfit.address, id);
        },
        updateCollateral: async (collateralAdd) => {
          const newCollateral = collateral.amount.add(numToBn(collateralAdd));
          const trade = await pos.trade(
            isBuy,
            BigNumber.from(0),
            SLIPPAGE,
            {
              position: pos,
              setToCollateral: newCollateral,
            }
          )
          if (trade.isDisabled) {
            errAlert(new Error(trade.disabledReason));
          }
          return signer.sendTransaction(trade.tx);
        }
      };
			const positionObj = {
				complexStrategy: isCall ? CALL : PUT,
				elements: [fetchedPosition]
			}

      // Find if position is located in a complex strategy object
      // where elIndex is index of position in elements field ( {..., elements: [-elIndex-] } )
      // and index is the index of complex strategy object ([{...}, {...}, -index-])
			let elIndex;
      const index = positions.findIndex(pos => {
				elIndex = pos.elements?.findIndex(el => el.strategy === strategy)
				return elIndex !== -1;
			});
      if (index === -1) {
        // If position is not located in a complex strategy object,
        // find draft of the complexPosition in complexPositions object
				const complexPosition = complexPositions.find(pos => {
					return !!pos.elementsDraft.find(el => el.id === id);
				});
				if (complexPosition) {
          // If draft of the complexPosition is found,
          // find if the complexPosition object is added to positions
					const complexPositionIndex = positions.findIndex(pos => pos.buildID === complexPosition.buildID);
					if (complexPositionIndex === -1) {
            // If complexPosition obj is not added to positions, push it to positions
						positions.push({...complexPosition, elements: [fetchedPosition]})
					} else {
            // If complexPosition obj is added to positions, push fetchedPosition to its elements
						positions[complexPositionIndex].elements.push(fetchedPosition);
					}
				} else {
          // If draft of the complexPosition is absent,
          // it would mean that fetchedPosition is not complex (strategy === Call || Put).
          // Then push it to positions
					positions.push(positionObj);
				}
      } else {
        // If fetchedPosition is located in a complex strategy object,
        // replace it with the updated version
        positions[index].elements[elIndex] = fetchedPosition;
      }
    }
    const promises = [];
    posArr.forEach(pos => {
      promises.push(formatPosition(pos));
    });

    await Promise.all(promises);
    return positions;
  }
  const { data: positionsData, error: positionsDataError } = useSWR(
    active && [active, account, "getBuilderPositions", pendingPositions, fetchPositionsTrigger],
    {
      fetcher: async () => {
        if (!active) {
          return;
        }
        const positionsDraft = [];
        const complexPositions = await fetchComplexPositions();
        await Promise.all([
          fetchHegicPositions(complexPositions, positionsDraft),
          fetchLyraPositions(complexPositions, positionsDraft),
        ]);

        // Remove closing positions
        pendingPositions.forEach(strat => {
          if (typeof strat !== 'string') {
            return;
          }
          // Find pending complexPosition
          let pendingElIndex;
          const pendingComplexIndex = positionsDraft.findIndex(pos => {
            pendingElIndex = pos.elements.findIndex(el => el.strategy === strat);
            return pendingElIndex !== -1;
          });
          if (pendingComplexIndex !== -1) {
            // If complex position has more than 1 element, cut out the pending element
            if (positionsDraft[pendingComplexIndex].elements.length > 1) {
              positionsDraft[pendingComplexIndex].elements.splice(pendingElIndex, 1);
            } else {
              // Else cut out the complex position itself
              positionsDraft.splice(pendingComplexIndex, 1);
            }
          }
        });
        // End remove closing positions

        positionsDraft.forEach(pos => {
          // sum of stats in complex positions
          pos.amount = pos.elements.reduce((sum, el) => {
            return sum.add(el.size);
          }, BigNumber.from(0));
          pos.premium = pos.elements.reduce((sum, el) => {
            return sum.sub(el.premium);
          }, BigNumber.from(0));
          pos.pnl = pos.elements.reduce((sum, el) => {
            return {
              unrealizedPnl: sum.unrealizedPnl.add(el.pnl.unrealizedPnl),
            }
          }, { unrealizedPnl: BigNumber.from(0)});
          pos.pnl.unrealizedPnlPercentage = expandDecimals(expandDecimals(pos.pnl.unrealizedPnl, 4).div(pos.premium), OPTIONS_DECIMALS - 4);
          pos.collaterals = pos.elements.reduce((sum, el) => {
            if (el.collateral) {
              const index = el.collateral.isBase ? 0 : 1;
              sum[index] = sum[index].add(el.collateral.amount);
            }
            return sum;
          }, [BigNumber.from(0), BigNumber.from(0)]);
        })
        
        return positionsDraft;
      }, refreshInterval: 10000,
    }
  );
  if (positionsDataError) {
    console.log("Fetching Builder Positions error!", positionsDataError);
  }
  useEffect(() => {
    if (positionsData) {
      setPositions([...positionsData]);
    }
  }, [positionsData]);
  const positionsLoading = active && !positionsData && !positionsDataError;
  // End Fetching positions

  // Fetching Commission data, no changes expected
  const { data: SLTPCommission, error: SLTPCommissionError } = useSWR(
    active && library && [active, library, "getSLTPCommission_Options", account],
    {
      fetcher: async () => {
        const DDL_TakeProfit = getTPContract("Hegic", "", library.getSigner());
        const commission = await DDL_TakeProfit.commissionSize();
        return commission;
      },
      refreshInterval: 300 * 1000,
    }
  )
  if (SLTPCommissionError) {
    console.log("DDL_TakeProfit commission error!", SLTPCommissionError);
  }
  // End Commission data

	return (
		<div className="BuilderPositions">
      <div className="BuilderPositions__header">
        <h1>My positions</h1>
        <MarkPricePanel
          infoTokens={infoTokens}
        />
      </div>
			<BuilderListSection
        library={library}
        infoTokens={infoTokens}
        positions={positions}
        positionsLoading={positionsLoading}
        positionsError={positionsDataError}
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        SLTPCommission={SLTPCommission}
        triggerPositionsFetch={triggerPositionsFetch}
      />
		</div>
	);
};

export default BuilderPositions;