import React, { useState } from 'react';
import cx from 'classnames';
import { convertInputNum } from '../../utils/sepThousands';
import "./NumberInput.scss";

const NumberInput_v3 = (props) => {
	const {
		title,
		displayValueTitle,
		displayValue,
		value,
		setValue,
		hasError,
		maxValue,
		onChange,
		icon,
		iconAlt,
		disabled,
		additionalSymbol,
		placeholder
	} = props;

	const [hasFocus, setHasFocus] = useState(false);

	const cls = cx(props.className, "NumberInput", hasFocus && 'hlight', hasError && 'error', disabled && 'disabled');
	
	return (
		<div className={"Exchange-swap-section" + (cls && ` ${cls}`)}>
			<div className="Exchange-swap-section-top">
				<div className="Exchange-swap-usd">
					{title}
				</div>
				{displayValue && (
					<div
						className="align-right clickable"
						onClick={() => {
							setValue(displayValue);
						}}
					>
						<span>
							<span className='NumberInput__displayValueTitle'>{displayValueTitle}:</span> {displayValue}
						</span>
					</div>
				)}
				{icon && (
					<div className='align-right'>
						<img src={icon} alt={iconAlt ?? "icon"} />
					</div>
				)}
			</div>
			<div className="Exchange-swap-section-bottom">
				<div>
					<input
						placeholder={placeholder ?? '0.0'}
						className="Exchange-swap-input"
						disabled={disabled}
						value={value}
						onChange={(e) => {
							setValue(convertInputNum(e.target.value));

							if (onChange) {
								onChange(e.target.value);
							}
						}}
						onFocus={(e) => {
							setHasFocus(true);
						}}
						onBlur={(e) => {
							setHasFocus(false);
						}}
					/>
				</div>
				<div className='align-right NumberInput__additionalContainer'>
					{maxValue &&
						<button
							className="input-max-btn"
							disabled={disabled}
							onClick={() => {
								setValue(maxValue)
							}}
						>
							Max
						</button>
					}
					{additionalSymbol &&
						<div className='NumberInput__additionalSymbol'>
							{additionalSymbol}
						</div>
					}
				</div>
				
			</div>
		</div>
	);
};

export default NumberInput_v3;