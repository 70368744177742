import React, { useEffect } from 'react';
import cx from 'classnames';

const Modal = (props) => {
	const {
		children, 
		className, 
		visible, 
		setVisible, 
		resetModal, 
		isObligatory
	} = props;
	
	const classes = cx('modal', className, visible && 'active');

	const bodyNode = document.getElementsByTagName('body')[0];
	const reset = () => {
		if (resetModal) {
			resetModal();
		}
		bodyNode.classList.remove('_scroll-lock');
	}
	useEffect(() => {
		if (visible) {
			bodyNode.classList.add('_scroll-lock');
		} else {
			reset();
		}
	}, [visible]);

	const closeModal = () => {
		if (isObligatory) {
			return;
		}
		setVisible(false);
	}
	
	return (
		<div
			className={classes}
			onMouseDown={closeModal}
			onTransitionEnd={(e) => {
				if (visible) {
					const isScrollable = e.target.scrollHeight > e.target.clientHeight;
					if (isScrollable) {
						e.target.style.alignItems = "flex-start";
					} else {
						e.target.style.alignItems = "";
					}
				}
			}}
		>
			<div
				className="modal__content-wrapper"
				onTransitionEnd={(e) => {
					e.stopPropagation();
				}}
			>
				<div className="modal__content" onMouseDown={e => e.stopPropagation()}>
					{children}
				</div>
			</div>
			{!isObligatory && 
				<button
					className="close-btn" 
					onClick={closeModal} 
					onTransitionEnd={(e) => e.stopPropagation()}
				/>}
		</div>
	);
};

export default Modal;