import React, { useState, useEffect } from 'react';
import Tab from '../Tab/Tab';
import TradingView from './TradingView';
import cx from "classnames";
import './ChartSelector.scss';
import BuilderPositionChart from '../../../Terminal/Builder/BuilderChart/BuilderPositionChart';

const ChartSelector = (props) => {
	const {
		CHART_PERIODS,
		period,
		setPeriod,
		candleStatsHtml,
		chartRef,
		chartToken,
		positionChartData,
		positionChartCollaterals
	} = props;

	const CHARTS = ["position", "simple", "trading"];
	const CHARTS_LABELS = {
		position: "Position Chart",
		simple: "Simple chart",
		trading: "Trading View",
	};
	const [chart, setChart] = useState("position");

	const onChartOptionChange = (chart) => {
		setChart(chart);
	}

	return (
		<div className={cx("ExchangeChart-bottom App-box App-box-border", `ExchangeChart_${chart}-active`)}>
			<Tab
				options={CHARTS}
				optionLabels={CHARTS_LABELS}
				option={chart}
				onChange={onChartOptionChange}
				className={"Exchange-chart-tabs"}
			/>

			<BuilderPositionChart
				className="ExchangeChart__position"
				style={{display: `${chart === "position" ? "block" : "none"}`}}
				chartToken={chartToken}
				chartData={positionChartData}
				collaterals={positionChartCollaterals}
			/>
			<div
				className='ExchangeChart__simple'
			>
				<div className="ExchangeChart-bottom-header">
					<div className="ExchangeChart-bottom-controls">
						<Tab options={Object.keys(CHART_PERIODS)} option={period} setOption={setPeriod} />
					</div>
					{candleStatsHtml}
				</div>
				<div className="ExchangeChart-bottom-content" ref={chartRef}></div>
			</div>
			<TradingView
				className={"ExchangeChart__trading"}
				style={{display: `${chart === "trading" ? "block" : "none"}`}}
				chartToken={chartToken}
			/>
		</div>
	);
};

export default ChartSelector;